@import '../colors';


.bullet-navigator {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .bullet-navigator-bullet {
        margin: 15px;
        width: 15px;
        height: 15px;
    
        path {
            fill: #D8D8D8;
        }
    }

    .bullet-navigator-bullet-active {
        path {
            fill: $unaro-yellow;
        }
    }
}