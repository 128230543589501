.payment-methods-page {
    display: flex;
    flex-direction: column;
    padding: 30px 20px;

    button {
        border-radius: 25px;
        color: white;
        background-color: #EA5454;
        border:none;
        padding: 8px 20px;
        outline: 0;
        font-size: 14px;
        cursor: pointer;
        width: 180px;
    }

    .header {
        display: grid;
        grid-template-columns: 0.5fr 1fr 1fr 0.5fr 0.3fr 0.5fr;
        margin-top: 30px;
        border-bottom: 1px solid #29355A;
        padding-bottom: 10px;

        label {
            font-weight: 700;
            color: #4D5059;
            margin-left: 10px;
            font-size: 12px;
        }
    }

    .payment-methods-loader {
        flex: 1;
        display: flex;
        align-content: center;
        justify-content: center;
        margin-top: 50px;
    }

    .payment-methods {
        overflow-y: scroll;
        height: calc(100vh - 450px);

        .payment-method {
            display: grid;
            grid-template-columns: 0.5fr 1fr 1fr 0.5fr 0.3fr 0.5fr;
            align-items: center;
            border-bottom: 1px solid #EAEAEC;
            padding: 15px 0;
    
            .pm-type {
                flex: 1;
                margin-left: 10px;
    
                img {
                    height: 20px;
                }
            }
    
            label {
                font-weight: 400;
                color: #4D5059;
                margin-left: 10px;
                font-size: 12px;
            }

            .default-pm {
                display: flex;
                flex-direction: row;
                align-items: center;
                
                .default-label {
                    background-color: #FFCC33;
                    color: white;
                    border-radius: 25px;
                    font-size: 8px;
                    padding: 3px 10px;
                }

                .change-default {
                    background-color: rgba(216, 216, 216, 0.6);
                    cursor: pointer;
                }
            }
    
            .pm-actions {
                flex: 1;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-left: 10px;
    
    
                img {
                    height: 15px;
                    margin-right: 15px;
                    cursor: pointer;
                }
            }
        }
    }
}