.send-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    margin: 20px 30px;
    padding: 40px;
    height: 50%;
    user-select: none;

    .send-message-title{
        font-weight: bold;
        font-size: 26.5px;
        line-height: 31px;
        text-align: center;
        color: #3C4858;
        margin-top: 40px;
    }

    .send-message-subtitle {
        font-weight: 300;
        font-size: 25px;
        line-height: 23px;
        color: #3C4858;
        margin-top: 10px;
    }

    .actions {
        display: flex;
        flex-direction: row;
        align-items: center;

        button {
            min-width: 150px;
            border-radius: 25px;
            color: white;
            align-self: center;
            background-color: #EA5459;
            border:none;
            padding: 10px;
            outline: 0;
            margin: 10px;
            margin-top: 40px;
            font-size: 16px;
            font-weight: 300;
            cursor: pointer;
        }

        :nth-child(2) {
            background-color: #FFCC33;
        }

        :nth-child(3) {
            background-color: #FF9933;
        }
    }
}