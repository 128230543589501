.not-available {
  display: flex;
  flex-direction: column;
  flex: 1;

  .back-btn {
    margin-top: 20px;
    margin-bottom: 0;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #ffffff;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    margin: 50px;

    .coming-soon {
      position: absolute;
      top: 168px;
      left: 130px;
      transition: left 1s;
    }

    .coming-soon-locked {
      left: 260px;
    }

    .icon {
      height: 12%;
    }

    .title {
      font-size: 26.5px;
      line-height: 31px;
      color: #3c4858;
      font-weight: bold;
      margin-top: 60px;
    }

    .text {
      font-weight: 300;
      font-size: 19.5px;
      line-height: 23px;
      color: #3c4858;
      font-weight: bold;
      margin-top: 50px;
    }
  }
}
