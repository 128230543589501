@import '../colors.scss';

.multi-selector-dropdown-container {
    display: flex;
    flex-direction: column;
    outline: 0;
    user-select: none;
    position: relative;
    
    .dropdown {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        border-radius: 25px;
        background: #F7F7F7;
        border-radius: 21px;
        padding: 10px 20px;
        outline: 0;
        cursor: pointer;
        border: 1px solid #CACACA;
        z-index: 2;

        .logo {
            transition-duration: 0.5s;
            transition-property: transform;
        }

        label {
            color: #3C4858;
            font-size: 15px;
            cursor: pointer;
        }
    }

    .dropdown-open {

        .logo {
            transform: rotate(180deg);
         }
    }

    .options {
        position: absolute;
        display: flex;
        flex-direction: column;
        background-color: white;
        min-width: 330px;
        padding: 10px 0px 10px 0px;
        border-radius: 10px;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.14);
        overflow-y: scroll;
        height: 90px;
        padding-top: 30px;
        top: 10px;

        .option {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            cursor: pointer;

            label {
                padding-top: 10px;
                padding-bottom: 10px;
                padding-left: 20px;
                padding-right: 10px;
                font-size: 15px;
                color: #3C4858;
                cursor: pointer;
            }

            .selector {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 16px;
                min-height: 16px;
                width: 15px;
                height: 15px;
                border: 1px solid #CACACA;
                border-radius: 50%;
                cursor: pointer;
                margin-right: 18px;

                .selected {
                    display: flex;
                    min-width: 10px;
                    min-height: 10px;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    cursor: pointer;
                    background-color: #FFCC33;
                }
            }
        }
       

        .option:hover {
            background-color: #F4F4F4;

            label {
                color: #3C4858;
            }
        }
    }
}