.dashboard-page {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.dashboard-title {
  margin: 20px;
  font-size: 36px;
  color: #a0a8b1;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.dashboard-page .dashboard-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 10px 20px 0px 20px;
}

.dashboard-page .dashboard-top .dashboard-title {
  font-size: 36px;
  color: #A0A8B1;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.dashboard-page .dashboard-top .add-btn {
  min-width: 120px;
  border-radius: 25px;
  color: white;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  background-color: #E85558;
  border: none;
  padding: 10px;
  outline: 0;
}

@media only screen and (max-width: 1000px) {
  .dashboard-page .dashboard-top .dashboard-title {
    width: 500px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 800px) {
  .dashboard-page .dashboard-top .dashboard-title {
    width: 300px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 500px) {
  .dashboard-page .dashboard-top .dashboard-title {
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

@media only screen and (max-width: 300px) {
  .dashboard-page .dashboard-top .dashboard-title {
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
