@import "../colors";

.delete-popup {
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.25);
  top: 0;
  left: 0;

  .delete-popup-landing-name {
    color: $unaro-red;
  }

  .delete-popup-information {
    padding: 12px;
  }

  .delete-popup-close-icon {
    font-size: 18px;
    position: absolute;
    right: 20px;
    background-color: white;
    border: 0;
    cursor: pointer;
  }

  .delete-popup-content {
    display: flex;
    flex-direction: column;
    background-color: white;
    position: absolute;
    align-items: center;
    top: 40%;
    left: 40%;
    width: 30%;
    padding: 20px;
    text-align: center;
    border-radius: 5px;

    .delete-popup-actions {
      display: flex;
      flex-direction: row;
      align-self: flex-end;
      margin-top: 20px;
      width: 100%;
      justify-content: space-evenly;

      .delete-popup-button {
        background-color: $unaro-red;
        color: white;
      }

      .cancel-button {
        background-color: $unaro-red;
        &:hover {
          background-color: #bf4e4e;
        }
      }

      .continue-button {
        background-color: $unaro-yellow;
        &:hover {
          background-color: #dbb02e;
        }
      }

      button {
        outline: 0;
        border: none;
        padding: 3px;
        font-weight: bold;
        border-radius: 25px;
        padding: 11px;
        outline: 0;
        margin-left: 10px;
        cursor: pointer;

        span {
          margin-left: 10px;
        }
      }

      button:hover {
        color: white;
      }
    }
  }
}
