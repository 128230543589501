.sms-reports {
    display: flex;
    flex-direction: column;
    padding: 30px;
    flex: 1;
    
    .sr-title {
        font-weight: 300;
        font-size: 23px;
        color: #3C4858;
    }

    .sr-top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .srt-title {
            font-weight: 300;
            font-size: 12px;
            color: #3C4858;
        }

        .srt-right {
            display: flex;
            flex-direction: row;
            align-items: center;

            input {
                outline: 0;
                background: #FFFFFF;
                border-radius: 17.625px;
                border: none;
                width: 250px;
                padding: 10px 20px;
            }
        }
    }

    .sr-content {
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        box-shadow: 0px 1.10427px 3.31282px rgba(0, 0, 0, 0.14);
        border-radius: 6.62564px;
        padding: 30px 0;
        margin-top: 10px;

        .sr-selector {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 18px;
            height: 18px;
            border-radius: 25px;
            border: 1px solid #C4C4C4;
            align-self: center;
            cursor: pointer;
        }

        .sr-selector-selected {
            width: 10px;
            height: 10px;
            border-radius: 25px;
            background-color: #FFCC33;
            cursor: pointer;
        }

        .src-header {
            display: grid;
            grid-template-columns: 0.3fr 0.5fr 1fr 1fr 2fr 1fr;
            border-bottom: 1px solid #EAEAEA;
            padding: 0 30px;
            padding-bottom: 20px;
            padding-left: 40px;
            margin-right: 45px;

            label {
                font-weight: bold;
                font-size: 16px;
                color: #000000;
            }

            .src-header-center {
                text-align: center;
            }
        }

        ::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 12px;
            background: #EFEFEF;
            border-radius: 18px;
        }
        
        ::-webkit-scrollbar-thumb {
            border-radius: 18px;
            background-color:#D9D9D9;
            -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
        }

        .no-messages-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            flex: 1;

            .no-campaigns {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;

                :first-child {
                    font-weight: 400;
                    font-size: 25px;
                }
    
                :nth-child(2) {
                    font-weight: 300;
                    font-size: 18px;
                    margin-top: 10px;
                }

                button {
                    background: #E85558;
                    border-radius: 20px;
                    border: none;
                    outline: 0;
                    font-weight: 500;
                    font-size: 12;
                    color: #FFFFFF;
                    padding: 10px;
                    width: 180px;
                    margin-top: 30px;
                    cursor: pointer;
                }
            }
        }

        .sr-messages {
            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            margin-top: 10px;
            height: calc(100vh - 320px);
            margin-right: 30px;

            .sr-message {
                display: grid;
                grid-template-columns: 0.3fr 0.5fr 1fr 1fr 2fr 1fr;
                padding: 10px 30px;
                padding-left: 40px;
                align-items: center;
                
                label {
                    font-weight: 300;
                    font-size: 12px;
                    color: #000000;
                    
                }

                .srm-sent {
                    margin-left: 30px;
                    color: #EA5454;
                    font-weight: 700;
                    font-size: 12px;
                }

                .sr-name {
                    font-weight: bold;
                    font-size: 14px;
                    color: #000000;
                }

                .sr-tags {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    flex-wrap: wrap;
                    margin-right: 40px;

                    .sr-tag {
                        padding: 3px 7px;
                        margin: 2px;
                        border-radius: 21px;
                        border: 1px solid gray;
                        font-size: 10px;
                        display: flex;
                        color: #FFFFFF;
                    }
                }

                .srm-actions {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-end;

                    button {
                        border: none;
                        outline: none;
                        padding: 8px 0;
                        width: 70px;
                        background: #FFCC33;
                        border-radius: 20.866px;
                        font-weight: 500;
                        font-size: 12px;
                        color: white;
                        cursor: pointer;
                    }

                    :nth-child(2) {
                        margin-left: 20px;
                        background: #EA5454;
                    }
                }
            }   
            
            .sr-message:hover {
                background-color: #F4F4F4;
            }
        }
    }
}