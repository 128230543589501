.grow-facebook-screen {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #475677;
  flex: 1;

  &__top {
    display: flex;
    flex-direction: column;
    padding: 24px;
    margin-top: 15px;
    color: white;
    height: 100%;
    text-align: center;
  }

  &__facebook-logo {
    margin: auto;
    font-family: Helvetica Neue, serif;
    font-weight: bold;
    font-size: 40px;
  }

  &__circle {
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    background-color: white;
    border-radius: 50%;
    margin: auto;
  }

  &__like-button {
    height: 40px;
    width: 100px;
    background-color: #475677;
    border-radius: 8px;
  }

  &__skip-button {
    height: 50px;
    width: 80px;
    background-color: white;
    border: 0;
    border-radius: 8px;
    align-self: center;
  }
}
