@import '../colors';

.integration-item {
    display: flex;
    flex-direction: row;
    background-color: white;
    margin-bottom: 10px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    justify-content: space-between;
    align-items: center;
    padding: 10px;

    .integration-item-details {
        display: flex;
        flex-direction: row;
        align-items: center;

        .integration-icon {
            width: 50px;

            img {
                max-width: 70px;
                height: 50px;
                object-fit: contain;
            }
        }

        .integration-name {
            // width: 400px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            margin-left: 20px;
            margin-right: 40px;
            color: #3C4858;
            font-size: 18px;
        }

        .integration-created {
            color: #878787;
            font-size: 15px;
        }
    }

    .integration-item-actions {
        display: flex;
        flex-direction: row;

        button {
            border-radius: 25px;
            color: white;
            align-self: center;
            background-color: $accent-color;
            border: none;
            padding: 3px 10px 3px 10px;
            outline: 0;
            margin-right: 10px;
            cursor: pointer;
        }

        .test-btn {
            background-color: #FFCC33;
        }

        .update-btn {
            background-color: #FF9933;
        }
    }
}