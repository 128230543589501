@import '../colors';

.tags-input {
    display: flex;
	align-items: center;
    flex-wrap: wrap;
    padding: 10px 20px 10px 20px;
    border: 0.75px solid #DBDBDB;
    box-sizing: border-box;
    border-radius: 21.75px;
    background-color: #F5F5F5;
    margin-top: 5px;

    input {
        background-color: #F5F5F5;   
        border: none;
        outline: none;
        font-size: 15px;
        flex: 1;
    }

    #tags {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        padding-inline-start: 0px;
        padding-inline-end: 0px;
        margin-block-start: 0px;
        margin-block-end: 0px;
    }

    .tag-input-tag {
        width: auto;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        padding: 0 8px;
        font-size: 14px;
        list-style: none;
        border-radius: 6px;
        margin-right: 5px;
        margin-bottom: 5px;
        background: $accent-color;
        
        .close-icon {
            width: 8px;
            height: 8px;
            padding: 3px;
            background-color: white;
            border-radius: 50px;
            margin-left: 4px;

            path {
                fill: $accent-color;
            }
        }
        
        .tag-close-icon {
            display: block;
            width: 16px;
            height: 16px;
            line-height: 16px;
            text-align: center;
            font-size: 14px;
            margin-left: 8px;
            color: #0052cc;
            border-radius: 50%;
            background: #fff;
            cursor: pointer;
        }
    }
    #tag-input-secondary--true {
        background-color: transparent;
        position: relative;
    }
    .tag-input-container {
        position: relative;
        width: 100%;
    }
    .tag-add-icon {
        position: absolute; 
        right: 0;
        cursor: pointer;
    }
}