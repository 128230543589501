@import '../colors';

.add-network-popup {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;

    .add-network-popup-content {
        display: flex;
        flex-direction: column;
        background-color: white;
        position: absolute;
        top: 25%;
        padding: 20px;
        box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.4);
        border-radius: 6px;

        .close-icon {
            width: 15px;
            height: 15px;
            align-self: flex-end;
    
            path {
                fill: #aaaaaa;
            }
        }

        .close-icon:hover {
            path {
                fill: $accent-color;
            }
        }

        .add-network-popup-title {
            font-size: 20px;
            color: #3C4858;
            margin-bottom: 20px;
            align-self: center;
        }

        .add-network-popup-label-input {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 10px;

            .input-error {
                border: 0.75px solid $accent-color;
            }

            label {
                color: #3C4858;
                font-weight: 500;
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                width: 80px;
            }

            input {
                border: 0.75px solid #DBDBDB;
                box-sizing: border-box;
                border-radius: 21.75px;
                background-color: #F5F5F5;
                padding: 10px 20px 10px 20px;
                margin-left: 10px;
                outline: 0;
                font-size: 15px;
                flex: 1;
            }

           .secured-logo {
               margin-left: 10px;
           }
        }

        .actions {
            display: flex;
            flex-direction: row;
            margin-top: 40px;

            button {
                min-width: 120px;
                border-radius: 25px;
                color: white;
                align-self: center;
                background-color: $accent-color;
                border:none;
                padding: 10px;
                outline: 0;
                margin-left: 30px;
                margin-right: 30px;
                font-size: 12px;
            }

            .back {
                background-color: #DBDBDB;
                border:none;
            }
        }
    }
}