.MuiMenuItem-root{
    padding: unset!important;
}
.MuiMenuItem-root>div>div{
    padding: 7px;
    padding-left: 20px;
    padding-right: 20px;
}
.blockLeft{
    width: 20%;
    display: inline-block;
    float: left;
    margin-right: 2%;
}
.blockRight{
    width: 78%;
    display: inline-block;
    float: left;
    height: 100%;
    background-color: white;
    border-radius: 8px;
    margin-top: 16px;
}
.customers{
    padding-left: 27px;
    padding-right: 27px;
}
.mainTitle{
    display: block;
    background-color: #EA5454;
    font-family: Roboto;
    color: white;
    padding: 23px;
    font-size: 22px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.blockLeft ul{
    background-color: white;
    padding-left: unset;
    padding-bottom: 10px;
    border-radius: 8px;
}

.title{
    font-family: Roboto;
    font-weight: 600;
    color: #000000;
}

.contacts{
    font-family: Roboto;
    margin-top: -9px;
    color: grey;
    font-size: 15px;
    font-weight: 200;
}

.blockLeft li{
    list-style-type: none;
    padding-left: 20px;
}
.audiences .mainTitle{
    background-color: #FF9933;
}
.blockRight .mainTitle{
    background-color: #FFCC33;
}



/*_______________________________________________pop-up Add Filter_________________________________________*/
/* Popup box BEGIN */
.hover_bkgr_fricc{
    background:rgba(0,0,0,.4);
    cursor:pointer;
    display:none;
    height:100%;
    position:fixed;
    text-align:center;
    top:0;
    width:100%;
    z-index:10000;
}
.hover_bkgr_fricc .helper{
    display:inline-block;
    height:100%;
    vertical-align:middle;
}
.hover_bkgr_fricc > div {
    background-color: #fff;
    box-shadow: 10px 10px 60px #555;
    display: inline-block;
    height: auto;
    max-width: 551px;
    min-height: 100px;
    vertical-align: middle;
    width: 60%;
    position: relative;
    border-radius: 8px;
    padding: 15px 5%;
}
.popupCloseButton {
    background-color: #fff;
    border: 3px solid #999;
    border-radius: 50px;
    cursor: pointer;
    display: inline-block;
    font-family: arial;
    font-weight: bold;
    position: absolute;
    top: -20px;
    right: -20px;
    font-size: 25px;
    line-height: 30px;
    width: 30px;
    height: 30px;
    text-align: center;
}
.popupCloseButton:hover {
    background-color: #ccc;
}
.trigger_popup_fricc {
    cursor: pointer;
    font-size: 20px;
    margin: 20px;
    display: inline-block;
    font-weight: bold;
}
/* Popup box BEGIN */
#simple-modal-title{
    text-align: center;
    font-weight: 200;
    margin-top: 30px;
    font-family: Roboto;
    font-size: 30px;
    margin-bottom: 30px!important;
}
.border{
    border: 1px solid #C4C4C4;
    height: 25px;
    border-radius: 16px;
}
.saveSegmentLi a{
    color: #EA5454;
}
.buttons{
    display: table;
    margin: 0 auto;
}
.yesDelete{
    display: inline-block;
    margin-top: 28px;
    margin-bottom: 28px;
    background-color: #EA5454;
    border: none;
    padding: 11px;
    color: white;
    border-radius: 23px;
    padding-left: 23px;
    padding-right: 22px;
    font-family: Roboto;
    margin-left: 12px;
    font-weight: 100;
    font-size: 16px;
    cursor: pointer;
}
.notDelete{
    display: inline-block;
    margin-top: 28px;
    margin-bottom: 28px;
    background-color: #FFCC33;
    border: none;
    padding: 11px;
    color: white;
    border-radius: 23px;
    padding-left: 23px;
    padding-right: 22px;
    font-family: Roboto;
    margin-left: 12px;
    font-weight: 100;
    font-size: 16px;
    cursor: pointer;
}
.saveSegmentLiB{
    text-align: left!important;
    color: #000000!important;
    margin-bottom: 25px;
    font-size: 24px;
}
.saveSegmentLiB label{
    cursor: pointer;
}
.borderSaveSegment{
    border: 1px solid #C4C4C4;
    height: 28px;
    border-radius: 15px;
    margin-top: 2px;
    display: inline-block;
    width: 100%;
}


/*_________________________________________________________________Import List______________________________________________________________*/
.line{
    border: 1px solid #C4C4C4;
}
.importList{
    display: inline-block;
    border: 1px solid;
    padding: 5px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 18px;
    margin-bottom: 17px;
    margin-top: 18px;
    color: #5B5B5B;
    border-color: #B5B5B5;
}
.importTitle{
    text-align: center;
    color: black;
    font-size: 30px;
    margin-top: 30px;
}
.titleDrag{
    font-weight: bold;
    color: black;
    font-size: 21px;
    margin-top: 49px;
}
.dragDrop{
    padding-bottom: 15px;
    padding: 10px;
    border: 1px solid;
    border-style: dashed;
    margin-top: 32px;
    border-color: #EA5454;
    border-radius: 8px;
    text-align: center;
}
.dragDrop p{
    font-size: 13px;
    line-height: 16px;
}
.dragDrop button{
    background-color: #FFCC33;
    color: white;
    margin: 0 auto;
    display: table;
    border: none;
    padding: 9px;
    padding-left: 15px;
    padding-right: 15px;
    border-radius: 15px;
    font-size: 15px;
}





.audenceLeft{
    width: 42.5%;
    display: inline-block;
    float: left;
}
.audenceRight{
    width: 42.5%;
    display: inline-block;
    float: left;
}
.audenceLine{
    width: 0.5%;
    margin-left: 5%;
    margin-right: 6%;
    display: inline-block;
    float: left;
    background-color: #C4C4C4;
    height: 197px;
}
.audenceRightTitle{
    font-size: 13px;
    text-align: left!important;
    line-height: 14px!important;
}
.audenceRightDescription{
    font-size: 9px;
    text-align: left!important;
    line-height: 10px!important;
}
.audence{
    display: block;
    margin-top: 24px;
    height: 223px;
}
.audenceLeft img{
    margin: unset;
    margin-top: 10px;
}
.nextAudence{
    background-color: #FFCC33;
    color: white;
    margin: 0 auto;
    display: table;
    border: none;
    padding: 9px;
    padding-left: 21px;
    border-radius: 15px;
    padding-right: 21px;
}
.successfulImport{
    text-align: center;
}
.successfulImport h2{
    margin-bottom: 17px!important;
    color: black;
}
.successfulImport p{
    color: #000000;
    margin-bottom: 60px;
}
.successfulImport p>a{
    color: #EA5454;
}
.successfulImport img{
    margin-top: 50px;
}
.tryAgain{
    background-color: #EA5454;
    color: white;
    border: none;
    padding: 11px;
    border-radius: 21px;
    padding-left: 21px;
    padding-right: 21px;
    margin-bottom: 27px;
    font-family: Roboto;
    font-weight: 100;
    font-size: 15px;
}
.imgFailed{
    width: 55px!important;
}
.audienceName{
    display: table;
    border-radius: 17px;
    border: 1px solid #C4C4C4;
    height: 34px;
    padding-left: 18px;
    width: 95%;
    max-width: 340px;
    margin: 0 auto;
    outline: none;
}
.select-contact{
    padding-bottom: 21px;
    border: 1px solid #B5B5B5;
    border-style: none none solid none;
    text-align: left;
    cursor: pointer;
}
.select-contact select{
    width: 200px;
    height: 35px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 22px;
    border: 1px solid #B5B5B5;
    color: #5B5B5B;
    cursor: pointer;
}
.select-contact select:active, .select-contact select:focus, .select-contact select:hover{
    outline: none;
}
.createContact{
    padding-bottom: 13px;
    padding-top: 8px;
}
.createContact input{
    width: 41%;
    display: inline-block;
    margin-left: 2%;
    margin-right: 2%;
    border-radius: 19px;
    height: 30px;
    border: 1px solid #C4C4C4;
    padding-left: 15px;
    margin-bottom: 27px;
}
.createContact input:focus, .createContact input:active{
    outline: none;
}
.emailWidth{
    width: 91%!important;
}

.spbl1{
    width: 30%;
    display: inline-block;
    float: left;
    font-size: 13px;
    color: #5B5B5B;
    font-weight: 500;
}
.spbl2{
    width: 70%;
    display: inline-block;
    float: left;
}
.asd{
    width: 17px;
    height: 17px;
    border-radius: 8px;
    display: inline-block;
    margin-left: 10px;
    margin-right: 4px;
    float: left;
    margin-top: 1px;
}
.c1, .c1.Mui-checked{
    color: #FFCC33!important;
}
.c2, .c2.Mui-checked{
    color: #EA5459!important;
}
.c3, .c3.Mui-checked{
    color: #FF9933!important;
}
.c4, .c4.Mui-checked{
    color: #14A697!important;
}
.c5, .c5.Mui-checked{
    color: #5AC040!important;
}
.c6, .c6.Mui-checked{
    color: #D353C7!important;
}
.c7, .c7.Mui-checked{
    color: #A83FCD!important;
}
.c8, .c8.Mui-checked{
    color: #2A2A2A!important;
}
.saveB{
    background-color: #FFCC33;
    border: none;
    color: white;
    margin: 0 auto;
    display: table;
    padding: 12px;
    padding-left: 24px;
    padding-right: 24px;
    border-radius: 23px;
    font-size: 17px;
    margin-top: 50px;
    margin-bottom: 19px;
    cursor: pointer;
}
.exportCSV{
    background-color: #EA5454;
    color: white;
    float: left;
    padding: 11px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 19px;
    margin-right: 15px;
}
.cancelCSV{
    background-color: #FFCC33;
    color: white;
    float: left;
    padding: 11px;
    padding-left: 20px;
    padding-right: 20px;
    border-radius: 19px;
}
.exportButton{
    display: table;
    margin: 0 auto;
    margin-top: 35px;
    margin-bottom: 20px;
    font-size: 18px;
    cursor: pointer;
}
.blockSelectCustomerList{
    margin: 0 auto;
    display: table;
    padding: 9px;
    padding-left: 5px;
    padding-right: 5px;
    border-radius: 22px;
    border: 1px solid #B5B5B5;
    color: #5B5B5B;
}
.blockSelectCustomerList:hover, .blockSelectCustomerList:focus, .blockSelectCustomerList:active{
    outline: none;
}
.exportLoyalText{
    color: #EA5454;
    margin-top: 35px;
}
.importList2 p{
    color: black!important;
    margin-bottom: 21px!important;
}
.importList2 .audenceRightTitle{
    margin-bottom: 25px;
}
.importList2 .dragDrop{
    padding-bottom: 31px;
}
.importList2 .audenceRightTitle{
    margin-bottom: 15px!important;
}
.blockEdit{
    background-color: #5B5B5B;
    width: 21.5%;
    display: inline-block;
    float: left;
    color: white;
    padding: 14px;
}











.imgEditBorder{
    float: left;
    margin-top: 1px!important;
}
.blockEdit span{
    display: inline-block;
    float: left;
    margin-left: 35px;
}
.fb{
    border-top-left-radius: 10px;
}
.lb{
    border-top-right-radius: 10px;
}

.MuiDataGrid-footer{
    display: none!important;
}
.congrRecogn{
    margin-top: 85px;
    color: #EA5454;
    text-align: center;
    font-weight: 700;
    font-size: 22px;
}
.columnImport{
    text-align: center;
}
.columnImport a{
    color: #EA5454;
}


.MuiList-root{
    background-color: white;
}
.MuiMenu-paper{
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 12px!important;
}
.MuiList-root img{
    float: left;
    width: 17px;
    margin-right: 9px;
}


/*__________________________________________________________________________________________________________*/

.makeStyles-paper-3 .audienceBlock{
    padding-top: unset!important;
    padding-bottom: unset!important;
}
.asd{
    background-color: aqua;
}
.editList .makeStyles-paper-1{
    width: 700px;
}
.makeStyles-paper-1{
    border: none!important;
    border-radius: 12px!important;
}

.makeStyles-paper-1 p{
    font-weight: 400;
    line-height: 7px;
    font-family: Roboto;
    text-align: center;
    margin-bottom: 40px;
    color: #EA5454;
}
.makeStyles-paper-1 img{
    margin: 0 auto;
    display: table;
    margin-top: 20px;
    margin-bottom: -30px;
}
























.burgerButton{
    cursor: pointer;
}
.audienceBlock{
    width: 500px;
    padding-top: 41px;
    padding-bottom: 41px;
}
.selectColorAudience{
    text-align: center;
    margin-top: 25px;
}
.SelectColor{
    color: #5B5B5B;
    font-weight: bold;
    margin-right: 11px;
}


/*_____________________________________________________________Add Contact____________________________________________________*/

.addContactTitle{
    line-height: 0px!important;
    font-weight: 400!important;
    font-size: 35px!important;
}

.addContactBlock .successfulImport img{
    margin-top: unset;
}

/*____________________________________________________________________Export_____________________________________________________________*/

.exportContact{
    text-align: center;
    padding-top: 0px;
    padding-bottom: unset;
}

/*_________________________________________________________________Delete Customers_______________________________________________________*/
.makeStyles-formControl-11{
    border: unset!important;
}
.MuiSelect-select.MuiSelect-select{
    padding-left: 5px;
    border: 1px solid;
    border-radius: 19px;
}
/*.MuiList-root div{*/
/*    width: 100%;*/
/*}*/
.country{
    width: 44%;
    display: inline-block;
    margin-left: 2%;
    margin-right: 3%;
    border-radius: 19px;
    height: 34px;
    border: 1px solid #C4C4C4;
    padding-left: 15px;
    margin-bottom: 27px;
    color: #5B5B5B;
    cursor: pointer;
    outline: none;
}
.closeImg{
    float: right;
    cursor: pointer;
    margin-top: 7px;
}
.contactSuccessfulImg{
    margin-top: -26px;
}
.filterMenu{
    padding: 15px!important;
    padding-left: 20px!important;
    padding-right: 20px!important;
}
.line{
    margin-top: 7px;
}
.filterMenu input{
    border-radius: 11px;
    border-color: #C4C4C4;
    outline: none;
    padding: 3px;
    padding-left: 6px;
    width: 94%;
}
.done{
    text-align: center;
    margin-top: 7px;
    cursor: pointer;
}
.inputSaveSegment{
    width: 96%;
    padding-left: 4%;
    border-radius: 25px;
    height: 38px;
    border: 1px solid #C4C4C4;
    outline: none;
}
.areYouSure{
    color: #5B5B5B;
}
.areYouSure span{
    color: #EA5454;
}
.MuiDataGrid-colCell{
    background-color: #5B5B5B;
    color: white;
}
.MuiDataGrid-root .MuiDataGrid-cellLeft{
    border: 1px solid #C4C4C4;
}
.columnTitles{
    display: table;
    width: 100%;
}
.columnRows{
    display: table;
    width: 100%;
}
.MuiDataGrid-columnsContainer{
    display: none!important;
}



/*__________________________________________________Customer Account_______________________________________________________*/


.importList{
    display: inline-block;
    border: 1px solid #C4C4C4;
    color: #5B5B5B;
}





























