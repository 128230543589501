.edit-venue-popup {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
    user-select: none;
    outline: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .edit-venue-popup-content {
        display: flex;
        flex-direction: column;
        background-color: white;
        box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.25);
        overflow: hidden;
        border-radius: 16px;
        outline: 0;
        width: 380px;
        padding: 30px;

        .close-icon {
            width: 20px;
            height: 20px;
            cursor: pointer;
            align-self: flex-end;

            path {
                fill: #C4C4C4;
            }
        }

        .close-icon:hover {
            path {
                fill: #EA5459;
            }
        }

        .edit-venue-popup-title {
            font-size: 19px;
            padding-bottom: 15px;
            padding-right: 5px;
            padding-left: 5px;
            border-bottom: 1px solid #E3E3E3;
            font-weight: 400;
        }

        .subtitle {
            font-size: 14px;
            font-weight: 400;
            margin-top: 20px;
        }

        input {
            height: 40px;
            background: #F5F5F5;
            border: 0.75px solid #DBDBDB;
            box-sizing: border-box;
            border-radius: 21.75px;
            outline: 0;
            padding: 10px 20px;
            margin-top: 10px;
            box-shadow: none;
            width: 100%;
        }

        .input-error {
            border: 0.75px solid #EA5454;
        }

        .error-message {
            margin-top: 20px;
            color: #E85558;
        }

        button {
            background: #FFCC33;
            border-radius: 57px;
            border: none;
            outline: 0;
            height: 40px;
            color: white;
            font-weight: 500;
            font-size: 15px;
            margin-top: 40px;
            margin-bottom: 10px;
            width: 220px;
            align-self: center;
            cursor: pointer;
        }
    }
}