@import '../colors';

.add-integrations-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    margin: 10px;
    padding: 20px;

    img {
        height: 70px;
    }

    label {
        color: #3C4858;
        margin: 10px;
    }

    button {
        min-width: 100px;
        border-radius: 25px;
        color: white;
        align-self: center;
        background-color: $accent-color;
        border: none;
        padding: 10px 20px 10px 20px;
        outline: 0;
        margin-top: 20px;
        cursor: pointer;
    }

    button:disabled {
        background-color: #E9E9E9;
        color: black;
        cursor: auto;
    }
}