@import "../colors";

.landing-page-item {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  background-color: white;
  // margin-right: 15px;
  // margin-left: 15px;
  margin-bottom: 30px;
  flex-basis: 30%;
  max-height: 250px;
  width:30%;
  margin-right:20px;

  .calendar-container {
    position: relative;
  }

  .calendar {
    width: 300px;
  }

  hr {
    border: 0;
    clear: both;
    display: block;
    width: 100%;
    background-color: $grey-2;
    height: 1px;
  }

  &__content {
    padding: 20px;
    flex: 2;
  }

  &__title-container {
    display: flex;
    flex: 2;
    font-weight: bold;
    flex-direction: column;
  }

  &__image-container {
    flex: 1;
    height: 100px;
    position: relative;
  }

  &__calendar {
    &:hover {
      cursor: pointer;
    }
  }

  &__image {
    height: 100px;
    width: 100px;
    position: absolute;
    top: -40px;
    right: 0px;
    background: green;
    box-shadow: 5px 11px 19px 1px rgba(0, 0, 0, 0.54);
  }

  &__left-content {
    display: flex;
  }

  &__actions {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    margin-bottom: 20px;
    flex: 1;
  }

  &__action-btn {
    outline: 0;
    border: none;
    font-weight: bold;
    border-radius: 25px;
    font-size: 14px;
    margin-right: 20px;
    padding: 11px;
    height: 35.26px;
    width: 100px;
    padding-left: 20px;
    outline: 0;
    color: white;
    background-color: $accent-color;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
  }

  &__action-btn-edit {
    background-color: #fecb45;
  }

  &__action-btn-download {
    background-color: $unaro-orange;
    width: 200px;
  }

  &__action-btn:hover {
    color: #3c4858;
    background-color: #eeeeee;
  }
}
