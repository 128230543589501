@import './colors';

.signups {
    display: flex;
    flex-direction: column;
    flex: 2;
    background-color: white;
    border-radius: 10px;
    margin-left: 20px;
    margin-bottom: 50px;
    padding: 20px;
    user-select: none;

    .signups-top {
        display: flex;
        flex-direction: row;

        .signups-logo {
            position: relative;
            top: -30px;
            display: flex;
            background-color: #FF8000;
            height: 60px;
            width: 60px;
            border-radius: 0 0 0 30px;
            justify-content: center;
            align-items: center; 

            img {
                height: 25px;
                width: 25px;
                align-self: center;
            }
        }

        label {
            margin-left: 20px;
        }
    }

    hr {
        border: 0;
        clear:both;
        display:block;
        width: 100%;               
        background-color: #eeeeee;
        height: 1px;
    }
    .sign-up-stats-container {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-evenly;

        .signups-stats {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-left: 30px;

            .name {
                display: flex;
                flex-direction: row;
                flex:1.5;

                img {
                    width: 20px;
                    height: 20px;
                }

                label {
                    margin-left: 10px;
                }
            }

            .stats {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                flex:1;
            }
        }
    }
}