@import './colors';

.main-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-image: url('../images/background.svg');
    background-color: #eeeeee;
    width:100%;
   


    .top-bar {
        width: 97%;
    }

    .main {
       
        flex: 1;
        margin-left: 210px;
        transition: margin-left 1s;
        margin-top: 80px;
        display: flex;
        flex-direction: column;
        .content {
            display: flex;
            flex: 50
        }
    }
}

.small-main {
    .main {
        flex: 1;
        margin-left: 80px;
    }
}

@font-face {
    font-family: 'Futura PT Bold';
    src: local('Futura PT Bold'), url(../fonts/FuturaPTBold.otf) format('opentype');
}

@font-face {
    font-family: 'Futura PT Book';
    src: local('Futura PT Book'), url(../fonts/FuturaPTBook.otf) format('opentype');
}

@font-face {
    font-family: 'Futura PT Cond Medium';
    src: local('Futura PT Cond Medium'), url(../fonts/FuturaPTCondMedium.otf) format('opentype');
}

@font-face {
    font-family: 'Roboto Light';
    src: local('Roboto Light'), url(../fonts/Roboto-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto Bold';
    src: local('Roboto Bold'), url(../fonts/Roboto-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    src: local('Roboto Regular'), url(../fonts/Roboto-Regular.ttf) format('truetype');
}

@media only screen and (max-width: 1000px) {
    .main-page {
        .main {
            flex: 1;
            margin-left: 80px;
        }
    }
}