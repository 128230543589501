@import '../colors';

.account-page {
    display: flex;
    flex-direction: column;
    flex: 1;

    .back-btn {
        margin-top: 20px;
        margin-left: 30px;    
    }

    .account-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 0px 50px 0px 50px;

        label {
            color: #3C4858;
            font-size: 20px;
        }

        button {
            min-width: 100px;
            border-radius: 25px;
            color: white;
            align-self: center;
            background-color: $accent-color;
            border:none;
            padding: 10px;
            outline: 0;
            cursor: pointer;
        }

        .billing-btn {
            margin-right: 20px;
            background-color: #FFCC33;
        }
    }
}