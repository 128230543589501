@import './colors';

.settings-page {
    display: flex;
    flex-direction: column;
    flex: 1;
    user-select: none;
    
    .back-btn {
        margin-top: 20px;
    }

    .settings-title {
        color: #3C4858;
        font-weight: 300;
        font-size: 20px;
        margin-left: 20px;
        margin-bottom: 20px;
    }

    .settings {
        display: flex;
        flex-direction: column;
        margin: 20px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
        border-radius: 5px;

        .settings-top {
            display: flex;
            flex-direction: row;
            background-color: white;
            border-radius: 5px 5px 0 0;
            padding: 20px;

            label {
                font-weight: 300;
                font-size: 15px;
                color: #3C4858;
                margin-left: 10px;
            }
        }

        .settings-content {
            display: flex;
            flex-direction: row;
            background-color: #E2E2E2;
            border-radius:  0 0 5px 5px;
            padding: 15px;

            .connection-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                flex: 1;
                margin: 15px;
                padding: 10px;
                background-color: white;
                box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
                border-radius: 6px;
                cursor: pointer;

                .connection-icon {
                    width: 25px;
                    height: 25px;
                    cursor: pointer;
                }

                label {
                    color: #3C4858;
                    font-weight: 300;
                    font-size: 15px;
                    margin-left: 10px;
                    cursor: pointer;
                }
            }
        }
    }
}