@import "./colors.scss";

.quick-send {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__error-message {
    font-size: 20px;
    color: $accent-color;
  }

  &__header-container {
    display: flex;
    justify-content: space-between;
    margin: 24px 24px 0px 24px;
  }

  &__container {
    display: flex;
    padding: 24px;
    min-height: 80vh;
  }

  &__phone-container {
    flex: 50 1;
    background-color: #fff;
    border-left: 0.5px solid $grey-2;
    display: flex;
    justify-content: center;
  }

  &__phone-image {
    background-image: url("../images/phone-hand-quick-send.svg");
    background-position: center bottom;
    background-size: contain;
    background-repeat: no-repeat;
    height: 700px;
    margin-top: auto;
    width: 700px;
    justify-content: center;
    position: relative;
  }

  &__phone-text {
    position: absolute;
    top: 130px;
    left: 250px;
    height: 150px;
    width: 150px;
    word-break: break-word;
    background-image: url("../images/TextBubble.svg");
    background-position: center;
    background-repeat: no-repeat;
    padding: 4px;
    padding-right: 12px;
    color: $grey-1;
    font-size: 10px;
    p {
      width: 95%;
      padding: 8px;
      height: 67%;
      display: flex;
    }
  }

  &__title {
    font-size: 36px;
    @media (min-width: 320px) and (max-width: 480px) {
      font-size: 28px;
    }
  }

  &__tag-add-icon {
    position: absolute;
    right: 75px;
  }

  &__main-content {
    background-color: #fff;
    padding: 24px;
    padding-top: 24px;
    flex: 50 1;
    justify-content: space-between;
    flex-direction: column;
    align-content: space-between;
    display: flex;
    z-index: 2;
  }

  &__input-container {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    margin-bottom: auto;
    align-items: center;
    @media (min-width: 320px) and (max-width: 480px) {
      flex-direction: column;
      margin: auto;
      margin-bottom: 15px;
    }
  }

  &__input-and-info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--add-track-url {
      @extend .quick-send__input-and-info-container;
      position: relative;
      width: 320px;
    }
  }

  &__input-label {
    font-size: 20px;
    font-weight: bold;
    @media (min-width: 320px) and (max-width: 480px) {
      font-size: 16px;
      width: 85%;
    }
  }

  &__text-area-label {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  &__text-area {
    resize: none;
    height: 95px;
    border-radius: 10px;
    padding: 30px;
    border: 1px solid $grey-2;
    &:focus {
      outline: 0;
    }
    @media (min-width: 320px) and (max-width: 480px) {
      margin-bottom: 15px;
    }
  }

  &__text-counter {
    font-size: 12px;
    color: $grey-1;
  }

  &__info-tooltip {
    height: 25px;
    width: 25px;
    background-image: url("../images/InfoTooltip.svg");
    background-position: center;
    background-repeat: no-repeat;
    margin: auto;
  }

  &__dropdown {
    margin-right: 55px;
    outline: 0;
    border: 0.5px solid $grey-2;
    border-radius: 25px;
    @media (min-width: 320px) and (max-width: 480px) {
      width: 230px;
    }
  }

  &__text-area-container {
    display: flex;
    flex-direction: column;
    margin-top: 28px;
  }

  &__input {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 11px;
    border-radius: 25px;
    margin-right: 55px;
    width: 265px;
    padding: 12px 20px;
    background: transparent;
    border-color: $grey-2;
    outline: 0;
    background-color: white;
    border-style: solid;
    border-width: 1px;

    &--with-info {
      @extend .quick-send__input;
      margin-right: 0;
    }
    @media (min-width: 320px) and (max-width: 480px) {
      width: 230px;
    }
  }

  &__add-button {
    border: 0;
    width: 150px;
    height: 30px;
    background: $unaro-red;
    color: white;
    border-radius: 30px;
    margin-left: 20px;
    &:focus {
      outline: 0;
    }
    &:hover {
      cursor: pointer;
    }
    &--disabled-true {
      background: $grey-2;
      &:hover {
        cursor: default;
      }
    }
  }

  &__tag-input {
    display: flex;
    width: 325px;
    align-items: center;
    background: white;
  }

  &__tag-input-with-search {
    display: flex;
    width: 270px;
    align-items: center;
    background: white;
  }

  &__send-button {
    border: 0;
    width: 50px;
    height: 39px;
    background: $unaro-red;
    color: white;
    border-radius: 30px;
    &:focus {
      outline: 0;
    }
    &:hover {
      cursor: pointer; 
    }
    &--disabled-true {
      background: $grey-2;
      &:hover {
        cursor: default;
      }
    }
  }
}
