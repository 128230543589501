.email-preview {
    display: flex;
    flex-direction: row;
    height: 100%;
    box-sizing: border-box;

    .ep-infos {
        flex: 2;
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
        border-radius: 6px;
        height: 100%;
        padding: 20px;
        box-sizing: border-box;

        .dropdown {
            right: 40px;
            background: white;
            border: 1px solid #C4C4C4;
            min-width: 150px;
            padding: 10px;

            label {
                font-weight: 400;
                font-family: Roboto;
                color: #000000;
            }

            .logo {
                path {
                    fill: #C4C4C4;
                }
            }
        }

        .epi-name {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 20px;
            height: 25px;

            .info-btn {
                width: 12px;
                height: 12px;
                margin-right: 10px;
            }

            label {
                font-family: Roboto;
                font-weight: 700;
                font-size: 20px;
                min-width: 180px;
            }
        }

        .edit-to {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 32px;
            user-select: none;

            label {
                margin: 0 10px;
            }
        }

        input {
            border: 1px solid #C4C4C4;
            border-radius: 21.75px;
            padding: 7px 15px;
            outline: none;
        }

        .ep-info {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .epi-left {
                display: flex;
                flex-direction: column;
                width: 85%;
            }

            .epi-info {
                font-family: Roboto;
                font-weight: 400;
                font-size: 16px;
                flex: 1;
                line-height: 32px;

                span {
                    font-weight: 700;
                    color: #EA5454;
                }
            }

            .invalid-domain-text {
                font-family: Roboto;
                font-weight: 500;
                font-size: 11px;
                color: #EA5454;
                margin-top: 5px;
            }

            .edit-from {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 32px;
                user-select: none;

                label {
                    margin: 0 30px;
                }

                input {
                    width: 180px;
                }

                .verify-fomain-btn {
                    margin-left: 10px;
                    height: 32px;
                    width: 130px;
                    border: none;
                    outline: none;
                    background: #EA5454;
                    color: white;
                    border-radius: 39px;
                    cursor: pointer;
                    font-size: 11px;
                    font-weight: 300;
                    font-family: Roboto;
                }
            }

            .subject-input {
                flex: 1;
            }

            .edit-icon {
                cursor: pointer;
            }

            .edit-active {
                path {
                    fill: #EA5454;
                }
            }
        }

        hr {
            border: 0;
            clear:both;
            display:block;
            width: 100%;               
            background-color: #C4C4C4;
            height: 1px;
            margin-top: 0px;
        }

        .schedule {
            margin-top: 20px;
        }

        .epi-schedule {
            display: flex;
            flex-direction: row;
            align-items: center;

            label {
                margin-right: 50px;
                line-height: 40px;
            }

            .epi-scheduler {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: 30px;
                user-select: none;
                height: 40px;
                
                label {
                    margin: 0 10px;
                }

                .schedule-date {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    border: 1px solid #C4C4C4;
                    border-radius: 21.75px;
                    padding: 0px 15px;
                    width: 120px;
                    cursor: pointer;

                    label {
                        cursor: pointer;
                    }

                    .logo {
                        cursor: pointer;
                    
                        path {
                            fill: #C4C4C4;
                        }
                    }
                }

                .schedule-time {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    border: 1px solid #C4C4C4;
                    border-radius: 21.75px;
                    padding: 7px 15px;
                    position: relative;

                    .dropdown {
                        background: white;
                        border: none;
                        min-width: 60px;
                        padding: 5px;
            
                        label {
                            font-weight: 400;
                            font-family: Roboto;
                            color: #000000;
                            line-height: normal;
                        }
            
                        .logo {
                            path {
                                fill: #C4C4C4;
                            }
                        }
                    }

                    .options {
                        left: -5px;
                        top: 30px;
                        height: 120px;
                        min-width: 70px;
                        padding: 0px;
                        overflow-y: scroll;

                        label {
                            margin: 0px;
                            line-height: 20px;
                        }
                    }
                }
            }
        }

        .epi-actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            align-self: center;
            margin-top: 50px;
            margin-bottom: 20px;
            
            .epi-action * {
                cursor: pointer;
            }

            .epi-action {
                display: flex;
                flex-direction: row;
                align-items: center;
                background: #EA5454;
                border-radius: 45px;
                padding: 0 20px;
                height: 40px;

                label {
                    font-family: Roboto;
                    font-weight: 300;
                    font-size: 16px;
                    color: #FFFFFF;
                    margin-right: 10px;
                }
            }

            .send-test {
                margin-right: 20px;
                background: #FFCC33;
            }
        }
    }

    .ep-preview {
        flex: 1;
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
        border-radius: 6px;
        height: 100%;
        margin-left: 20px;
        padding: 20px;
        box-sizing: border-box;

        .epp-top {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            label {
                font-family: Roboto;
                font-weight: 400;
                font-size: 16px;
            }

            .eppt-right  * {
                cursor: pointer;
            }

            .eppt-right {
                display: flex;
                flex-direction: row;
                align-items: center;

                label {
                    font-family: Roboto;
                    font-weight: 400;
                    font-size: 16px;
                    color: #EA5454;
                    margin-right: 10px;
                }
            }
        }

        .epp-content {
            display: flex;
            flex: 1;
            margin-top: 20px;
            user-select: none;

            .dynamic-content-div {
                width: 100%;
                border: none;
            }
        }

    }


    .calendar {
        position: absolute;
        bottom: 100px;
    }
}