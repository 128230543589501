@import '../colors';

.percent-slider {
    display: flex;
    flex-direction: row;

    .percent-slider-title {
        color: #3C4858;
        font-weight: 500;
        width: 200px;

    }

    .percent-slider-value {
        margin-left: 20px;
    }

    .rc-slider {
        // margin-left: 20px;
        height: 10px;
        width: 100px;
        margin-left: 20px;
    }

    .rc-slider-rail, .rc-slider-track {
        height: 10px;
    }

    .rc-slider-track {
        background-color: transparent;

    }

    .rc-slider-handle {
        bottom: -10px;
        background-color: $unaro-red;
        border: 4px solid $grey-2;
        height: 20px;
        width: 20px;
    }
}