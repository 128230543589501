.plans-popup {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
    user-select: none;
    outline: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .plans-popup-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.25);
        overflow: hidden;
        border-radius: 16px;
        outline: 0;
        width: fit-content;
        margin-left: 90px;
        padding: 10px;

        .close-icon {
            width: 15px;
            height: 15px;
            margin: 10px;
            cursor: pointer;
            align-self: flex-end;
    
            path {
                fill: #aaaaaa;
            }

            :hover {
                path {
                    fill: #EA5454;
                }
            }
        }
    }
}