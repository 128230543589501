@import './colors';

.organisation {
    display: flex;
    flex-direction: column;
    user-select: none;
    flex: 1;
    
    span {
        color: red
    }

    .add-organisation {
        display: flex;
        flex-direction: column;
        margin: 50px 5vw 50px 5vw;
        flex: 1;

        background-color: white;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
        border-radius: 5px;

        .add-organisation-top {
            display: flex;
            flex-direction: row;
            align-items: center;

            .add-organisation-logo {
                position: relative;
                top: -20px;
                display: flex;
                background-color: $accent-color;
                height: 60px;
                width: 60px;
                border-radius: 0 0 0 30px;
                justify-content: center;
                align-items: center; 
                margin-left: 10px;
                margin-bottom: -10px;

                .add-organisation-logo-svg {
                    width: 30px;
                    height: 30px;

                    path {
                        fill: white;
                    }
                }
            }

            label {
                margin-left: 20px;
            }
        }

        button {
            min-width: 120px;
            border-radius: 25px;
            color: white;
            align-self: center;
            background-color: $accent-color;
            border:none;
            padding: 10px;
            outline: 0;
            margin-right: 20px;
            font-size: 14px;
            margin-bottom: 30px;
        }

        .add-organisation-content {
            display: flex;
            flex-direction: row;
            flex: 1;
            margin: 10px 0px 40px 0px;
            padding-left: 30px;
            padding-right: 30px;
            border-top: 1px solid rgba(0, 0, 0, 0.14);
            
            .checkbox-input {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 10px;

                label {
                    color: #3C4858;
                    font-size: 15px;
                    margin-right: 20px;
                }
            }

            .add-organisation-details {
                display: flex;
                flex-direction: column;
                flex: 1;
                padding-left: 20px;
                padding-right: 20px;

                .add-organisation-subtitle {
                    align-self: center;
                    font-size: 15;
                    margin-top: 20px;
                    margin-bottom: 10px;
                }

                .text-switch {
                    margin-top: 10px;

                    .text-switch-label {
                        color: #3C4858;
                        font-size: 15px;
                        width: 120px;
                    }

                    .text-switch-actions  {
                        .text-switch-value {
                            width: 80px;
                        }
                    }
                }
            }

            .add-organisation-address {
                border-left: 1px solid rgba(0, 0, 0, 0.14);
                margin-left: 30px;
                padding-left: 30px;
            }

            .add-organisation-input {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 10px;

                .dropdown-container {
                    flex: 1;
                    margin-left: 10px;

                    .dropdown {
                        flex: 1;
                        padding: 10px 20px 10px 20px;
                        border: 0.75px solid #DBDBDB;
                        box-sizing: border-box;
                        border-radius: 21.75px;
                        background-color: #F5F5F5;

                        label {
                            width: inherit;
                        }
                    }

                    .options {
                        height: 200px;
                        overflow-y: scroll;

                        label {
                            width: inherit;
                        }

                        label:hover {
                            background-color: $accent-color;
                            color: white;
                        }
                    }

                    .dropdown-open {

                        .logo {
                            margin-top: 2px;
                         }
                    }
                }

                .google-places-autocomplete  {
                    flex: 1;
                    position: static;
                    width: auto;
                    margin-right: -10px;
                }

                label {
                    color: #3C4858;
                    font-size: 15px;
                    width: 122px;
                }

                input {
                    flex: 1;
                    border: 0.75px solid #DBDBDB;
                    box-sizing: border-box;
                    border-radius: 21.75px;
                    background-color: #F5F5F5;
                    padding: 10px 20px 10px 20px;
                    margin-left: 10px;
                    outline: 0;
                    font-size: 15px;
                }
                
                .input-error {
                    border: 0.75px solid $accent-color;
                }
            }

            .add-venue {
                display: flex;
                flex-direction: column;

                .add-organisation-subtitle {
                    align-self: center;
                    font-size: 15;
                    margin-top: 20px;
                    margin-bottom: 10px;
                }
            }
        }   
    }
}

@media only screen and (max-width: 1000px) {
    .organisation {
        .add-organisation {
            display: flex;
            flex-direction: column;
    
            .add-organisation-content {
                flex-direction: column;
                
                .add-organisation-details {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                }
    
                .add-organisation-address {
                    border-left: none;
                    margin-left: 20px;
                    padding-left: 0px;
                }
            }   
        }
    }
}