@import "../colors.scss";

.drag-item {
  border: 1px solid $grey-2;
  padding: 0.5rem 1rem;
  background-color: $grey-3;
  max-width: 100%;
  display: flex;
  align-items: center;
  cursor: move;
  &__drag-handle {
    margin: 5px;
  }
}
