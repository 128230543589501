.verify-domain-popup {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
    user-select: none;
    outline: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .verify-domain-popup-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        box-shadow: 0px 1px 22px rgba(0, 0, 0, 0.2);
        border-radius: 12px;
        overflow: hidden;
        outline: 0;
        width: 500px;
        padding: 20px;

        .close-btn {
            width: 14px;
            height: 14px;
            cursor: pointer;
            align-self: flex-end;

            path {
                fill:#C4C4C4;
            }
        }

        label {
            font-family: Roboto;
            font-weight: 300;
            font-size: 12px;
            text-align: center;
            max-width: 450px;
        }

        .title {
            font-family: Roboto;
            font-weight: bold;
            font-size: 20px;
            font-weight: 700;
            margin-bottom: 30px;
        }

        input {
            width: 80%;
            margin: 30px 0;
            border: 1px solid #C4C4C4;
            border-radius: 21.75px;
            padding: 7px 14px;
            font-size: 12px;
            outline: none;
        }

        .save-btn {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            background: #EA5454;
            border-radius: 45px;   
            width: 240px;
            padding: 10px 20px;
            margin-bottom: 20px;
            cursor: pointer;

            label {
                font-family: Roboto;
                font-style: normal;
                font-weight: 300;
                font-size: 19px;
                color: #FFFFFF;
                cursor: pointer;
            }
        }
    }
}