@import "../../colors.scss";

.instagram-redirect {
  height: 100%;
  background-color: $grey-3;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;

  &__logo {
    margin-top: 20px;
  }

  &__instagram-preview {
    height: 300px;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
  }

  &__button {
    height: 50px;
    width: 80%;
    background-color: #a41c4a;
    border-radius: 30px;
    border: 0;
    color: white;
    font-size: 16.37px;
    font-weight: bold;
    margin-top: 70px;
    margin-bottom: 35px;
  }
}
