.emails-collected-widget {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    display: flex;
    height: 100%;

    .emails-collected-widget-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;
        height: 100%;

        .remove-widget-icon {
            position: absolute;
            left: -10px;
            top: -10px;
            cursor: pointer;

            display: none;
            animation: display-none-transition 1s both; 
        }

        .show-remove {
            display: block;
            animation: display-none-transition 1s both;
        }

        .emails-collected-widget-icon {
            height: 20px;
            align-self: flex-end;
        }
    
        .emails-collected-widget-title {
            font-weight: 500;
            font-size: 12px;
            color: #373737;
            padding: 10px 0;
            border-bottom: 1px solid #EEEEEE;
        }
    
        .emails-collected-widget-content {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            flex: 1;
    
            .emails-collected-widget-value {
                font-weight: 300;
                font-size: 30px;
                color: #3C4858;
            }
        
            .emails-collected-widget-bottom {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-top: 5px;
        
                label {
                    margin-left: 10px;
                    font-weight: 300;
                    font-size: 14px;
                    color: #999999;
                }
            }
        }
    }
}

.emails-collected-widget-edit * {
    cursor: grab;
}

.emails-collected-widget-edit:active * {
    cursor: grabbing;
}

@keyframes display-none-transition {
    0% {
      opacity: 0;
    }
}