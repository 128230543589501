.message-info-popup {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
    user-select: none;
    outline: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .message-info-popup-content {
        display: flex;
        flex-direction: column;
        background-color: white;
        box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.25);
        overflow: hidden;
        border-radius: 16px;
        outline: 0;
        width: 600px;
        padding: 20px 0;
        max-height: 750px;
        position: relative;

        .close-icon {
            width: 15px;
            height: 15px;
            position: absolute;
            right: 20px;
            cursor: pointer;
    
            path {
                fill: #aaaaaa;
            }

            :hover {
                path {
                    fill: #EA5454;
                }
            }
        }

        .title {
            margin: 40px 0;
            align-self: center;
            font-weight: 400px;
            font-size: 24px;
        }

        .message-info {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 10px 40px;

            :first-child {
                font-weight: 400;
                font-size: 16px;
                width: 200px;
            }

            :nth-child(2) {
                font-weight: 300;
                font-size: 14px;
            }
        }

        .grey {
            background-color: #F2F2F2;
        }
    }
}