.signup-page {
    min-height: 100vh;
    background: url('../images/signup-background.png') no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

    .verify-page {
        display: flex;
        align-items: center;
        justify-content: center;
        height: fit-content;
    
        .verify-page-content {
            display: flex;
            flex-direction: column;
            background: #FFFFFF;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
            border-radius: 6px;
            padding: 30px;
            width: 430px;

            .signup-back-btn {
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;
                user-select: none;

                img {
                    height: 14px;
                    cursor: pointer;
                }

                label {
                    margin-left: 8px;
                    font-size: 14px;
                    font-weight: 300;
                    font-family: Roboto;
                    color: #3C4858;
                    cursor: pointer;
                }
            }

            .verify-form {
                display: flex;
                flex-direction: column;
                width: 100%;
            }
    
            .verify-title {
                font-size: 38px;
                font-family: Roboto;
                align-self: center;
                margin-bottom: 20px;
            }
    
            .verify-subtitle {
                font-weight: 400;
                font-size: 16px;
                color: #5B5B5B;
                font-family: Roboto;
                align-self: center;
                margin-bottom: 30px;
                text-align: center;
    
                span {
                    color: #EA5454;
                }
            }
    
            .verify-label {
                font-family: Roboto;
                font-weight: 700;
                color: #5B5B5B;
                font-size: 16px;
                margin-bottom: 10px;
            }
    
            .industries-dd {
                position: relative;
    
                .dropdown {
                    border: 1px solid #C4C4C4;
                    border-radius: 21.75px;
                    outline: none;
                    padding: 7px 15px;
                    margin-bottom: 25px;
                    background-color: white;
                    width: 100%;
                }
    
                .options {
                    height: 200px;
                    width: 100%;
                    overflow-y: scroll;
                    position: absolute;
                    top: 40px;
                }
            }
    
            .verify-input {
                border: 1px solid #C4C4C4;
                border-radius: 21.75px;
                outline: none;
                padding: 7px 15px;
                margin-bottom: 25px;
            }
    
            .input-error {
                border: 0.75px solid #EA5454;
            }
    
            .error-label {
                color: #EA5454;
                font-family: Roboto;
                font-weight: 300;
                font-size: 15px;
                align-self: flex-start;;
            }
    
            .verify-search {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 25px;
                outline: none;
    
    
                .google-places-autocomplete {
                    outline: none;
                    border: none;
                    width: 320px;
                    height: 29px;
                    border-top: 1px solid #C4C4C4;
                    border-left: 1px solid #C4C4C4;
                    border-bottom: 1px solid #C4C4C4;
                    border-top-left-radius: 21.75px;
                    border-bottom-left-radius: 21.75px;
                    background-color: white;
    
                    input {
                        padding: 7px 15px;
                        border-top-left-radius: 21.75px;
                        border-bottom-left-radius: 21.75px;
                        width: 90%;
                    }
                }
    
                .vs-btn {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    background-color: #5B5B5B;
                    border: 1px solid #5B5B5B;
                    border-top-right-radius: 21.75px;
                    border-bottom-right-radius: 21.75px;
                    padding: 5px 15px;
                    flex: 1;
                    height: 19px;
                    cursor: pointer;
    
                    label {
                        font-family: Roboto;
                        font-weight: 400;
                        font-size: 10px;
                        color: #FFFFFF;
                        cursor: pointer;
                        margin-right: 10px;
                    }
    
                    img {
                        cursor: pointer;
                    }
                }
            }
    
            .va-bottom {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                
                input {
                    width: 100px;
                }
            }
    
            .vp-inputs {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin-top: 20px;
    
                .vp-input {
                    display: flex;
                    flex-direction: column;
    
    
                    input {
                        width: 170px;
                    }
                }
            }
    
            .vc-btn {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin-top: 10px;
                background: #EA5454;
                border-radius: 45px;
                width: 95px;
                align-self: center;
                color: white;
                font-weight: 300;
                font-size: 16px;
                font-family: Roboto;
                cursor: pointer;
                padding: 10px 20px;
                
                label {
                    cursor: pointer;
                }
    
                img {
                    cursor: pointer;
                }
            }
        }
    }

    .verify-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #FFFFFF;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
        border-radius: 6px;
        padding: 50px;
        width: 430px;
        align-self: flex-start;

        .verify-title {
            font-size: 38px;
            font-weight: 400;
            font-family: Roboto;
            margin: 30px 0;
        }

        .verify-subtitle {
           color: #5B5B5B;
           font-size: 16px;
           font-weight: 400;
           font-family: Roboto;
           text-align: center;

           span {
                color: #EA5454;
                font-weight: 700;
                cursor: pointer;
            }
        }
    }

    .signup-content {
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
        border-radius: 6px;
        padding: 40px;
        width: 430px;

        .signup-title {
            font-size: 38px;
            font-family: Roboto;
            align-self: center;
            margin-top: 30px;
            margin-bottom: 20px;
        }

        .signup-subtitle {
            font-weight: 400;
            font-size: 16px;
            color: #5B5B5B;
            font-family: Roboto;
            align-self: center;
            margin-bottom: 30px;

            span {
                font-weight: 700;
                color: #EA5454;
                cursor: pointer;
            }
        }

        .signup-names {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            :first-child {
                margin-right: 50px;
            }

            .signup-name {
                display: flex;
                flex-direction: column;
                flex: 1;
            }
        }

        .signup-label {
            font-family: Roboto;
            font-weight: 700;
            color: #5B5B5B;
            font-size: 16px;
            margin-top: 40px;
            margin-bottom: 20px;
        }

        input {
            border: 1px solid #C4C4C4;
            border-radius: 21.75px;
            outline: none;
            padding: 7px 15px;
        }

        .input-error {
            border: 0.75px solid #EA5454;
        }

        .error-label {
            color: #EA5454;
            font-family: Roboto;
            font-weight: 300;
            font-size: 15px;
            margin-top: -20px;
            margin-bottom: 20px;
        }

        .terms-services {
            font-family: Roboto;
            font-weight: 300;
            font-size: 13px;
            color: #5B5B5B;
            margin: 40px 0;

            a {
                color: #EA5454;
            }
        }

        button {
            background: #EA5454;
            border-radius: 45px;
            border: none;
            outline: none;
            padding: 10px;
            width: 90px;
            align-self: center;
            color: white;
            font-weight: 300;
            font-size: 16px;
            font-family: Roboto;
            cursor: pointer;
        }
    }
}