@import '../../colors.scss';

.tags-dropdown-container {
    display: flex;
    flex-direction: column;
    outline: 0;
    user-select: none;
    position: relative;
    flex: 1;
    margin: 10px 30px;
    
    .dropdown {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        outline: 0;
        height: 24px;
        border: 0.75px solid #C4C4C4;
        border-radius: 21.75px;
        background-color: white;
        padding: 5px 20px;
        cursor: pointer;

        .logo {
            width: 12px;
            height: 12px;
            transition-duration: 0.5s;
            transition-property: transform;
        }

        label {
            color: #3C4858;
            font-size: 15px;
             cursor: pointer;
       }

        .contact-tags {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-right: 10px;

            .contact-tag {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: fit-content;
                padding: 3px 12px;
                margin: 2px;
                border-radius: 25px;
                border: 1px solid gray;

                label {
                    font-size: 12px;
                    display: flex;
                }

                .remove-icon {
                    width: 10px;
                    height: 10px;
                    margin-left: 10px;
                }

                .remove-icon-black {
                    path {
                        fill: black;
                    }
                }
            }
        }
    }

    .dropdown-open {

        .logo {
            transform: rotate(180deg);
            margin-top: 5px;
         }
    }

    .options {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 40px;
        background-color: white;
        min-width: 290px;
        padding: 10px 0px 10px 0px;
        border-radius: 10px;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.14);
        z-index: 5;
        overflow-y: scroll;
        height: 200px;
        cursor: pointer;

        label {
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 20px;
            padding-right: 10px;
            font-size: 15px;
            color: #3C4858;
            cursor: pointer;
        }

        label:hover {
            background-color: $accent-color;
            color: white;
        }
    }
}