@import '../../colors.scss';

.contact-dropdown-container {
    display: flex;
    flex-direction: column;
    outline: 0;
    user-select: none;
    position: relative;
    
    .dropdown {
        display: flex;
        align-items: center;
        flex-direction: row;
        justify-content: space-between;
        min-width: 250px;
        border-radius: 25px;
        background: #F7F7F7;
        border-radius: 8px;
        padding: 20px 30px 20px 10px;
        outline: 0;
        cursor: pointer;

        .logo {
            transition-duration: 0.5s;
            transition-property: transform;
        }

        label {
            color: #3C4858;
            font-size: 15px;
            cursor: pointer;
        }
    }

    .dropdown-open {

        .logo {
            transform: rotate(180deg);
            margin-top: 5px;
         }
    }

    .options {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 40px;
        background-color: white;
        min-width: 290px;
        padding: 10px 0px 10px 0px;
        border-radius: 10px;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.14);
        z-index: 5;
        overflow-y: scroll;
        height: 200px;

        label {
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 20px;
            padding-right: 10px;
            font-size: 15px;
            color: #3C4858;
            cursor: pointer;
        }

        label:hover {
            background-color: $accent-color;
            color: white;
        }
    }
}