.visit-customer {
    display: flex;
    flex-direction: row;
    border: 1px solid #C4C4C4;
    border-radius: 7px;
    padding: 10px;
    border-radius: 10px;

    img {
        background-color: #E85558;
        width: 60px;
        height: 60px;
        border-radius: 5px;
    }

    .visit-customer-right {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        flex: 1;

        .visit-customer-name {
            font-size: 18px;
            text-align: right;            
            color: #666666;
        }

        .visit-customer-email {
            font-size: 14px;
            text-align: right;            
            color: #666666;
        }

        hr {
            border: 0;
            clear:both;
            display:block;
            width: 100%;               
            background-color: #979797;
            height: 1px;
            margin-top: 10px;
          }
    }
}