@import "../colors.scss";

.edit-landing-redirect {
  padding: 24px;
  &__title {
    font-weight: bold;
    font-size: 16px;
    margin-top: 50px;
    margin-bottom: 30px;
    margin-left: 30px;
  }

  &__bottom-picker {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    position: relative;
  }

  &__button-colour-picker {
    margin-right: 15px;
  }

  &__colour-pickers {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin-top: 20px;
  }

  &__switches {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: 50px;
  }

  &__switch {
    display: flex;
    align-content: center;
    align-items: center;
    flex-basis: 45%;
    margin: 10px;
  }

  &__bottom-content {
    width: 94%;
    height: 300px;
    justify-content: space-evenly;
    display: flex;
    flex-direction: column;
  }

  &__content-divider {
    border: 0;
    clear: both;
    display: block;
    width: 90%;
    background-color: $grey-2;
    height: 1px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &__text-area {
    resize: none;
    width: 90%;
    height: 300px;
    border-radius: 8px;
    border: 1px solid $grey-2;
    padding: 30px;
  }

  &__switch-label {
    margin-left: 20px;
  }

  &__label {
    font-weight: bold;
    width: 300px;
  }

  &__input-container {
    width: 90%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__input-with-picker {
    display: flex;
    align-items: center;
  }

  &__input {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 11px;
    border-radius: 25px;
    width: 225px;
    padding: 12px 20px;
    background: transparent;
    border-color: $grey-2;
    outline: 0;
    background-color: white;
    border-style: solid;
    border-width: 1px;

    &--no-picker {
      @extend .edit-landing-redirect__input;
      margin-right: 50px;
    }

    &--full-width {
      @extend .edit-landing-redirect__input;
      width: 90%;
    }
  }

  &__custom-image-input-container {
    width: 522px;
    display: flex;
    justify-content: space-between;
  }

  &__custom-image-button-url-container {
    display: flex;
    margin-top: 30px;
  }

  &__embed-code-input {
    resize: none;
    width: 90%;
    height: 100px;
    border-radius: 8px;
    border: 1px solid $grey-2;
    padding: 30px;
  }

  &__instagram-input-container {
    width: 522px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin-top: 20px;
    align-items: center;
  }
}
