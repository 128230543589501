@import "./colors";

.dashboard-page {
  display: flex;
  flex-direction: column;
  flex: 1;

  .dashboard-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 15px 20px 0px 40px;
    margin-right: 30px;

    .dashboard-title {
      font-size: 36px;
      color: #a0a8b1;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    }

    .add-btn {
      min-width: 120px;
      border-radius: 25px;
      color: white;
      align-self: center;
      background-color: $accent-color;
      border: none;
      padding: 10px;
      outline: 0;
      cursor: pointer;
    }
  }
}

.add-btn {
  min-width: 120px;
  border-radius: 25px;
  color: white;
  align-self: center;
  background-color: $accent-color;
  border: none;
  padding: 10px;
  outline: 0;
  cursor: pointer;
}

.dashboard-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 15px 20px 0px 40px;
  margin-right: 30px;
}

@media only screen and (max-width: 1000px) {
  .dashboard-page {
    .dashboard-top {
      .dashboard-title {
        width: 500px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .dashboard-page {
    .dashboard-top {
      .dashboard-title {
        width: 300px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .dashboard-page {
    .dashboard-top {
      .dashboard-title {
        width: 200px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

@media only screen and (max-width: 300px) {
  .dashboard-page {
    .dashboard-top {
      .dashboard-title {
        width: 150px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }
}

.intro-dashboard {
  // display: flex;
  // flex-direction: column;
  // flex: 1;

  &__dashboard-top {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px 20px 0px 40px;
    height: 85px;
  }
  &__dashboard-title {
    font-size: 36px;
    color: #a0a8b1;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }
  &__dashboard-subtitle {
    margin-top: 25px;
    margin-bottom: 25px;
  }

  &__main-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
  }

  &__intro-card {
    background-color: white;
    margin: 20px 30px 0px 30px;
    flex-basis: 45%;
    border-radius: 15px;
    position: relative;
    transition: all 0.3s ease-in-out;
    -webkit-box-shadow: 2px 2px 12px 3px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 2px 12px 3px rgba(0, 0, 0, 0.2);
    &:hover {
      background-color: $grey-3;
      transform: scale(1.02);
      cursor: pointer;
    }
    &:focus {
      outline: none;
    }
  }

  &__card-image {
    width: 100%;
    background-color: $grey-3;
    background-position: center;
    background-repeat: no-repeat;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    height: 230px;
    background-size: cover;

    &--venue-background {
      background-image: url("../images/venueTutorial.svg");
    }
    &--sms-background {
      background-image: url("../images/sendSMSTutorial.svg");
    }
    &--landing-background {
      background-image: url("../images/landingTutorial.svg");
    }
    &--support-background {
      background-image: url("../images/supportTutorial.svg");
    }
  }

  &__card-content {
    display: flex;
    padding: 24px;
  }

  &__left-content {
    flex-basis: 66%;
    margin-left: 10px;
    padding-right: 15px;
    border-right: 1px solid $grey-2;

    &--support {
      border-right: 0;
      font-size: 20px;
    }
  }

  &__card-subtitle {
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    margin-top: 10px;
  }

  &__right-content {
    flex-basis: 33%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: auto;
    & p {
      margin: 0;
      font-size: 20px;
      line-height: 23px;
      color: $grey-1;
    }
  }

  &__video-backdrop {
    background: rgba(0, 0, 0, 0.4);
    width: 200vh;
    height: 91vh;
    z-index: 2;
    display: flex;
    position: absolute;
    justify-content: center;
    align-content: center;
  }

  &__video-player {
    margin: auto;
    object-fit: cover;
    background-color: black;
  }

  &__card-check-mark {
    height: 70px;
    width: 70px;
    background-color: green;
    border-radius: 50%;
    position: absolute;
    top: 10px;
    background-image: url("../images/confirmTick.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    left: 10px;
  }
}
