@import '../colors';

.select-device-popup {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;

    .select-device-popup-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        position: absolute;
        top: 25%;
        padding: 20px;
        box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.4);
        border-radius: 6px;

        .close-icon {
            width: 15px;
            height: 15px;
            align-self: flex-end;
    
            path {
                fill: #aaaaaa;
            }
        }

        .close-icon:hover {
            path {
                fill: $accent-color;
            }
        }

        label {
            font-size: 20px;
            color: #3C4858;
        }

        .selectors {
            display: flex;
            flex-direction: row;
            padding-left: 2vw;
            padding-right: 2vw;

            .types {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 3vw 3vw 0 3vw;
                
                img {
                    width: auto;
                    height: 135px;
                    background-size: contain;
                    background-repeat: no-repeat;                        
                }

                .selector {
                    width: 25px;
                    height: 25px;
                    margin-top: 40px;
                    cursor: pointer;
                }
            }
        }

        .actions {
            display: flex;
            flex-direction: row;
            margin-top: 40px;

            button {
                min-width: 120px;
                border-radius: 25px;
                color: white;
                align-self: center;
                background-color: $accent-color;
                border:none;
                padding: 10px;
                outline: 0;
                margin-left: 30px;
                margin-right: 30px;
                font-size: 12px;
                cursor: pointer;
            }

            .back {
                background-color: #DBDBDB;
                border:none;
            }
        }
    }
}