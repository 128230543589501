@import '../colors';

.popup {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;

    .popup-content {
        display: flex;
        flex-direction: column;
        background-color: white;
        position: absolute;
        top: 40%;
        left: 40%;
        width: 30%;
        padding: 20px;
        border-radius: 5px;

        input {
            border: 0.75px solid #DBDBDB;
            box-sizing: border-box;
            border-radius: 21.75px;
            background-color: #F5F5F5;
            padding: 10px 20px 10px 20px;
            width: 30vw;
            outline: 0;
            font-size: 15px;
         }

         .popup-actions {
             display: flex;
             flex-direction: row;
             align-self: flex-end;
             margin-top: 20px;

             button {
                min-width: 120px;
                border-radius: 25px;
                color: white;
                align-self: center;
                background-color: $accent-color;
                border:none;
                padding: 10px;
                outline: 0;
                margin-left: 20px;
                font-size: 12px;
                cursor: pointer;
             }

             .cancel {
                 background-color: #DBDBDB;
                 border:none;
                }
         }
    }
}