.manage-email-campaigns {
    display: flex;
    flex-direction: column;
    padding: 10px;
    flex: 1;

    .mec-top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 40px 10px;

        .mec-title {
            font-family: Roboto;
            font-weight: 300;
            font-size: 30px;
        }

        .navigation {
            .page-navigator {
                button {
                    color: #3C4858;
                }

                .number-navigation {
                    background-color: #F2F2F2;
                    border: 1px solid #C4C4C4;

                    .number-navigator {
                        label {
                            color: black;
                            font-weight: 300;
                            font-size: 14px;
                        }
                    }

                    .number-navigator-selected {
                        label {
                            color: white;
                        }
                    }
                }
            }
        }

        .mec-right {
            display: flex;
            flex-direction: row;
            align-items: center;

            .mec-search {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                background-color: white;
                min-width: 200px;

                border: 1px solid #C4C4C4;
                border-radius: 30px;
                padding: 7px 15px;
                margin-right: 30px;

                min-width: 180px;
                
                input {
                    outline: none;
                    border: none;
                }

                .mec-icon {
                    line {
                        stroke: #C4C4C4;
                    }

                    circle {
                        stroke: #C4C4C4;
                    }
                }
            }

            .dropdown {
                background-color: white;
                border-color: #C4C4C4;
                min-width: 180px;
                padding: 7px 15px;
                z-index: 3;
            }

            .options {
                min-width: 210px;
                top: 10px;
                padding-top: 25px;

                label {
                    font-size: 12px;
                }
            }
        }

    }

    .mec-content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;


        .mec-campaign {
            display: flex;
            flex-direction: row;
            align-items: center;
            background: #FFFFFF;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
            border-radius: 6px;
            margin: 10px;
            padding: 15px;

            .mecc-content {
                flex: 2;
                display: flex;
                flex-direction: column;
                margin-right: 15px;

                .mecc-name {
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 20px;
                    padding: 15px 0;
                    margin-bottom: 15px;
                    border-bottom: 1px solid #C4C4C4;
                }

                .mecc-info {
                    font-family: Roboto;
                    font-size: 12px;
                    margin: 10px 0;

                    span {
                        font-weight: 700;
                    }
                }

                .mecc-actions {
                    display: flex;
                    flex-direction: row;
                    margin: 10px 0;

                    .mecc-action * {
                        cursor: pointer;
                    }

                    .mecc-action  {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        background: #FFCC33;
                        border-radius: 45px;
                        padding: 2px 15px;
                        margin-right: 15px;

                        label {
                            font-weight: 300;
                            font-size: 12px;
                            color: #FFFFFF;
                        }

                        .icon {
                            width: 10px;
                            max-width: 15px;
                            margin-left: 10px;

                            path {
                                fill: white;
                            }
                        }
                    }

                    .delete {
                        background: #EA5454;
                    }
                }
            }

            .mecc-preview {
                flex: 1.2;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 6px;
                height: 100%;

                .dynamic-content-div {
                    width: 100%;
                    border: none;
                }
            }
        }
    }
}