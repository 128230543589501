.add-integrations {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

@media only screen and (max-width: 1300px) {
    .add-integrations  {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media only screen and (max-width: 1000px) {
    .add-integrations  {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 700px) {
    .add-integrations  {
        grid-template-columns: 1fr;
    }
}