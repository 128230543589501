@import "../colors";

.edit-login-animate-true {
  animation: fade-in 1.2s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.edit-login-welcome {
  // display: flex;
  // flex-direction: column;
  // padding: 24px;

  &__title {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    margin-left: 20px;
    font-weight: bold;
    margin-top: 19px;

    &--options {
      @extend .edit-login-welcome__title;
      margin-bottom: 20px;
    }
  }

  &__top-inputs {
    display: flex;
    margin-top: 25px;
  }

  &__dropdown {
    border: 1px solid $grey-2;
    border-radius: 32px;
  }

  &__input {
    font-family: Roboto, serif;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 11px;
    border-radius: 25px;
    margin-right: 55px;
    width: 265px;
    padding: 12px 20px;
    border-color: $grey-2;
    outline: 0;
    background: white;
    border-style: solid;
    border-width: 1px;
  }

  &__content-managers {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 10px;
  }

  &__content-input {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
  }

  &__text-area {
    @extend .edit-login-welcome__input;
    height: 164px;
    background-color: #F2F2F2;
    margin: 10px;
    margin-top: 32px;
    resize: none;
    width: 84%;
  }

  &__divider {
    margin-top: 30px;
    border: 0;
    clear: both;
    display: block;
    width: 100%;
    background-color: #eeeeee;
    height: 1px;
  }

  &__text-counter {
    font-size: 12px;
    margin-bottom: 0px;
    margin-left: 10px;
    color: $grey-2;
  }

  &__color-options {
    display: flex;
    flex-wrap: wrap;
    margin-right: 5px;
    margin-left: 5px;
    height: 100px;
  }

  &__colour-picker {
    flex-basis: 30%;
  }
}
