@import '../colors.scss';

.venues {
    display: flex;
    flex-direction: column;
    flex: 1;

    .back-btn {
        margin-top: 20px;
    }
}