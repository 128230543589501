@import '../../colors';


.add-customer-popup {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
    user-select: none;
    outline: 0;

    .add-customer-popup-content {
        display: flex;
        flex-direction: column;
        background-color: white;
        position: absolute;
        top: 12%;
        left: 30%;
        width: 25%;
        min-width: 400px;
        padding: 20px;
        overflow: hidden;
        border-radius: 20px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        outline: 0;
        background-image: url('../../../images/unaro-yellow-bg.svg');

        .add-customer-popup-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 40px 30px;
            height: 100%;
            border-radius: 25px;
            padding: 20px;
            justify-content: space-between;
            
            .add-customer-popup-top {
                display: flex;
                flex-direction: column;
                align-items: center;

                .logo {
                    width: 80%;
                    height: 70px;
                    margin: 20px 0;
                    object-fit: contain;

                }

                .title-dropdown-container {
                    background-color: inherit;

                    .dropdown {
                        background-color: inherit;
                    }
                }
        
                .add-customer-popup-connect-with-email {
                    font-family: Futura PT Bold;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 18px;
                    text-align: center;
                    color: #EA5454;                
                }

                input {
                    border: none;
                    outline: none;
                    border-bottom: 0.6px solid #898989;
                    width: 100%;
                    font-family: Futura PT Book;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 18px;
                    line-height: 18px;
                    margin: 15px 0;
                    padding: 5px;
                    background-color: inherit;
                }

                .birthday-input {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    border-bottom: 0.6px solid #898989;
                    user-select: none;
                    width: 100%;
                    margin: 15px 0;
                    padding: 5px;
                    color: #666666;
                    background-color: inherit;

                    label {
                        width: 120px;
                    }

                    input {
                        width: fit-content;
                        padding: 0px;
                        margin: 0;
                        border: none;
                        color: #666666;
                        background-color: inherit;
                    }
                }

                .input-error {
                    border-bottom: 0.6px solid #E85558;
                }

                .title-preview {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;

                    .icon {
                        margin-left: 5px;
                        width: 15px;
                    }
                }

                .error-label {
                    color: #E85558;
                }
            }

            .add-customer-popup-bottom {
                display: flex;
                flex-direction: column;
                align-items: center;
                user-select: none;

                .add-customer-popup-register-btn {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
                    padding: 20px;
                    border-radius: 30px;
                    width: 200px;
                    align-self: center;
                    margin: 5px;
        
                    label {
                        font-family: Futura PT Book;
                        font-style: normal;
                        font-weight: normal;
                        font-size: 25px;
                        color: #FFFFFF;
                        margin-right: 10px;
                    }
                }

                .add-more {
                    label {
                        font-size: 20px;
                    }
                }

                .add-customer-popup-privacy {
                    font-family: Futura PT Book;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    color: #666666;
                    margin-top: 5px;
                    align-self: center;
                }
            }
          
        }
        
    }
}