.info-container {
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 2;
  cursor: pointer;

  .info {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-left: 30px;
    border: 2px solid #979797;
    border-radius: 25px;
    outline: 0;
    cursor: pointer;
    z-index: 3;

    label {
      color: #979797;
      cursor: pointer;
    }
  }

  .template-info {
    white-space: break-spaces;
    position: absolute;
    font-family: unset;
    width: 220px;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    padding: 10px 70px 10px 50px;
    left: 20px;
    top: -10px;
    color: black;
    background-color: white;

    @media only screen and (max-width: 1180px) {
      padding-right: 10px;
    }

    @media only screen and (max-width: 1130px) {
      width: 180px;
    }

    @media only screen and (max-width: 1080px) {
      width: 120px;
    }

    @media only screen and (max-width: 1030px) {
      width: 80px;
    }

    @media only screen and (max-width: 850px) {
      visibility: hidden;
    }
  }
}
