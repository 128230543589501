@import '../colors';

.import-devices {
    display: flex;
    flex-direction: column;

    .import-devices-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: white;
        align-items: center;
        border-radius: 5px 5px 0px 0px;
        margin-top: 30px;

        .import-devices-logo {
            position: relative;
            top: -20px;
            display: flex;
            background-color: $accent-color;
            height: 60px;
            width: 60px;
            border-radius: 0 0 0 30px;
            justify-content: center;
            align-items: center; 
            margin-left: 20px;

            .import-devices-logo-svg {
                width: 30px;
                height: 30px;

                path {
                    fill: white;
                }
        
                rect {
                    stroke: white;
                    fill: $accent-color
                }
        
                line {
                    stroke: white;
                }
                
            }
        }

        .actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 10px;

            button {
                min-width: 140px;
                border-radius: 25px;
                color: white;
                align-self: center;
                background-color: $accent-color;
                border:none;
                padding: 10px;
                outline: 0;
                margin: 10px 20px 20px 0px;
                cursor: pointer;
            }
        }
    }

    .import-devices-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        background-color: #E2E2E2;
        padding: 20px;
    }

    .not-found {
        display: flex;
        flex-direction: column;
        align-items: center;
        align-self: center;
        margin-top: 20px;

        .not-found-icon {
            width: 30px;
            height: 30px;

            path {
                fill: $accent-color;
            }
        }

        label {
            margin-top: 10px;
        }
    }
}

@media only screen and (max-width: 1400px) {
    .import-devices{
        .import-devices-grid {
           grid-template-columns: 1fr 1fr 1fr;
        }
    }
}

@media only screen and (max-width: 1100px) {
    .import-devices {
        .import-devices-grid {
           grid-template-columns: 1fr 1fr;
        }
    }
}

@media only screen and (max-width: 800px) {
    .import-devices {
        .import-devices-grid {
           grid-template-columns: 1fr;
        }
    }
}