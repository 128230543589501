@import '../colors';

.venue-network-item {
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin-left: 0px;
    background: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    user-select: none;
    margin-top: 10px;
    
    .venue-network-item-name {
        color: #3C4858;
        font-size: 15px;
        font-weight: 300;
    }

    .venue-network-item-switch {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 10px;
        cursor: pointer;

        label {
            color: #3C4858;
            font-size: 12px;
            font-weight: 300;
            margin-right: 20px;
        }
    }
}