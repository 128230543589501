.customer-logins-widget {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    display: flex;
    height: 100%;

    .customer-logins-widget-container {
        display: flex;
        flex-direction: column;
        padding: 10px;
        height: 100%;

        .remove-widget-icon {
            position: absolute;
            left: -10px;
            top: -10px;
            cursor: pointer;

            display: none;
            animation: display-none-transition 1s both; 
        }

        .show-remove {
            display: block;
            animation: display-none-transition 1s both;
        }

        .customer-logins-widget-icon {
            height: 20px;
            align-self: flex-end;
        }

        .customer-logins-widget-title {
            font-weight: 500;
            font-size: 12px;
            color: #373737;
            padding: 10px 0;
            border-bottom: 1px solid #EEEEEE;
        }

        .customer-logins-content {
            display: flex;
            flex-direction: row;
            height: 100%;

            .cl-chart {
                flex: 1;
            }

            .cl-stats {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: space-evenly;

                .cl-stat {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    img {
                        height: 35px;
                        width: 35px;
                        object-fit: fill;
                    }

                    label {
                        font-weight: 300;
                        font-size: 17px;
                        color: #717171;
                        margin-left: 10px;

                        span {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}

.customer-logins-widget-edit * {
    cursor: grab;
}

.customer-logins-widget-edit:active * {
    cursor: grabbing;
}

@keyframes display-none-transition {
    0% {
      opacity: 0;
    }
}