@import '../colors';

.add-integration-popup-item {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 10px;
    border: 0.75px solid #DBDBDB;


    .add-integration-popup-item-venue {
        display: flex;
        flex-direction: row;
        background-color: $accent-color;
        align-items: center;
        padding: 10px;
        min-width: 20vw;
        border-radius: 10px;
        margin-bottom: 10px;


        .add-integration-popup-item-venue-logo {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: white;
            border-radius: 25px;
            padding: 10px;

            img {
                width: 20px;
                height: 20px;
            }
        }


        label {
            margin-left: 20px;
            color: white;
        }
    }

    .add-integration-popup-item-input {
        border: 0.75px solid #DBDBDB;
        box-sizing: border-box;
        border-radius: 21.75px;
        background-color: #F5F5F5;
        padding: 10px 20px 10px 20px;
        min-width: 250px;
        outline: 0;
        font-size: 15px;
        margin-top: 10px;
    }

    .add-integration-popup-item-dropdown {
        margin-top: 5px;

        .dropdown-container {
            .dropdown {
                min-width: 250px;
                padding: 10px 20px 10px 20px;
                border: 0.75px solid #DBDBDB;
                box-sizing: border-box;
                border-radius: 21.75px;
                background-color: #F5F5F5;

                label {
                    width: inherit;
                }
            }

            .options {
                top: 45px;

                label {
                    width: inherit;
                }
            }

            .dropdown-open {

                .logo {
                    margin-top: 2px;
                }
            }
        }
    }

    .input-label {
        color: #3C4858;
        font-size: 15px;
        width: 150px;
        margin-top: 10px;
    }

    .input-error {
        border: 1px solid $accent-color;
        border-radius: 21.75px;
    }

    .error-label {
        margin-top: 10px;
        color: $accent-color;
    }

    button {
        min-width: 100px;
        border-radius: 25px;
        color: white;
        align-self: center;
        background-color: $accent-color;
        border: none;
        padding: 10px 20px 10px 20px;
        outline: 0;
        margin: 10px 10px 0px 10px;
        cursor: pointer;
    }
}