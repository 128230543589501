@import '../colors';

.integrations {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 20px;

    .back-btn {
        margin-top: 0px;
        margin-left: 0px;    
    }

    .integrations-title {
        color: #3C4858;
        font-size: 20px;
        margin-bottom: 40px;
    }
}