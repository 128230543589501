.create-email-campaign {
    display: flex;
    flex-direction: column;
    padding: 20px;
    flex: 1;

    .cec-top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin: 10px 0;

        .cec-name {
            font-size: 28px;
            font-weight: 300;
            width: 300px;
        }
        
        .cec-steps {
            display: flex;
            flex-direction: row;
            align-items: center;
            height: 50px;

            .cec-step {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                align-self: center;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                margin: 20px;
                background-color: #C4C4C4;

                label {
                    font-size: 16px;
                    font-family: Roboto;
                    font-weight: 900;
                    color: white;
                }
            }

        }

        .cec-actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            margin-right: 20px;
            width: 300px;

            button {
                height: 40px;
                width: 80px;
                background: #EA5454;
                border-radius: 45px;
                outline: none;
                border: none;
                color: white;
                font-size: 16px;
                font-weight: 300;
                cursor: pointer;
            }

            .ceca-back {
                margin-right: 15px;
                background:#FF9933;
            }

            .ceca-fl {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                height: 40px;
                width: 120px;
                border-radius: 45px;
                background:#FFCC33;
                padding: 0 20px;
                cursor: pointer;

                label {
                    color: white;
                    font-size: 16px;
                    font-weight: 300;
                    cursor: pointer;
                }

                img {
                    cursor: pointer;
                }
            }
        }

    }

    .cec-content {
        display: flex;
        flex-direction: column;
        margin-top: 40px;
        box-sizing: border-box;
        height: 100%;
    }
}