@import '../colors.scss';

.show-venues {
    display: flex;
    flex-direction: column;
    user-select: none;

    .show-venues-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-left: 30px;
        margin-right: 30px;

        label {
            font-size: 22px;
            font-weight: 300;
            color: #3C4858;
        }

        button {
            min-width: 100px;
            border-radius: 25px;
            color: white;
            align-self: center;
            background-color: $accent-color;
            border: none;
            padding: 10px;
            outline: 0;
        }
    }

    .show-venues-content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        margin-left: 20px;
        margin-right: 20px;
        margin-top: 40px;
    }   
}

@media only screen and (max-width: 1400px) {
    .show-venues{
        .show-venues-content {
           grid-template-columns: 1fr 1fr;
        }
    }
}

@media only screen and (max-width: 1000px) {
    .show-venues{
        .show-venues-content {
           grid-template-columns: 1fr;
        }
    }
}