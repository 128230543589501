.email-password-screen {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    border: 1px solid grey;
    padding: 20px;

    .email-password-top {
        display: flex;
        flex-direction: column;
        align-items: center;

        label {
            font-family: Futura PT Book;
        }

        .phone-preview-text {
            margin-bottom: 10px;
        }

        .email-password-screen-small-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 5px;
            margin-bottom: 5px;
            
            .phone-preview-logo {
                max-height: 150px;
                max-width: 150px;
                object-fit: contain;
            }

            .logo-placeholder {
                display: flex;
                height: 120px;
                width: 120px;
                border-radius: 50%;
                background-color: white;
            }
    
            .phone-preview-social {
                display: flex;
                flex-direction: row;
    
    
                .social-preview {
                    margin-top: 10px;
                }
            }
        }

        input {
            font-size: 18px;
            outline: 0;
            border: none;
            padding: 5px;
            margin-top: 10px;
        }
    }  

    .powered-by {
        display: flex;
        flex-direction: column;
        align-items: center;

        .powered-by-unaro-icon {
            display: flex;
            flex-direction: column;
            height: 20px;
        }
    }
}