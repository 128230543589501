.payment-done {
    display: flex;
    flex-direction: column;
    align-items: center;

    .pd-title {
        font-family: Roboto Light;
        font-weight: 300;
        font-size: 30px;
        margin-top: 20px;
    }

    .pd-subtitle-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .pd-subtitle-text {
            display: flex;
        }

        .pd-subtitle {
            font-family: Roboto;
            font-weight: 700;
            font-size: 14px;
            text-align: center;
            margin-top: 20px;
    
            span {
                color: #EA5454;
            }
        }
    }

    .pds-error {
        color: #EA5454;
    }

    button {
        margin-top: 30px;     
        width: 90px;
        height: 40px;
        background: #EA5454;
        box-shadow: 0px 0px 14.5467px rgba(234, 84, 84, 0.6);
        border-radius: 45px;
        color: white;
        outline: 0;
        border: none;
        cursor: pointer;
        font-size: 16px;
        font-weight: 300;
        font-family: Roboto;
    }

    .pd-actions {
        display: flex;
        font-display: row;
        align-items: center;

        .retry-btn {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 110px;
            background: #FFCC33;
            box-shadow: 0px 0px 14.5467px rgba(255, 204, 51, 0.6);
            border-radius: 45px;          
            cursor: pointer;
            margin-top: 30px;     
            height: 40px;
            margin-left: 20px;

            label {
                color: white;
                margin-right: 10px;
                cursor: pointer;
            }

            img {
                cursor: pointer;
            }
        }
    }
    

}