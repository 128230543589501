@import '../colors';

.file-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;

    .file-selector-title {
        color: #3C4858;
        font-weight: 500;
        width: 100px;
    }

    input {
        display: none;
    }
    
    .file-selector-file-name {
        margin-left: 20px;  
        width: 160px;
        height: 15px;
        padding: 10px;
        border: 0.75px solid #DBDBDB;
        border-radius: 25px;
        background-color: #F5F5F5;
        color: #3C4858;
        outline: 0;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .file-selector-btn {
        margin-left: 20px;
        color: #11BEFC;
        text-decoration-line: underline;
        cursor: pointer;
    }
}