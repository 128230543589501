@import "../colors";

.stepper {
  display: flex;
  flex-basis: 50%;
  justify-content: center;
  &__step {
    margin-right: 30px;
    background-color: $grey-2;
    border-radius: 50%;
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      border-radius: 50%;
      margin: auto;
      color: white;
      font-size: 16px;
    }
  }
  &__inner--active-true {
    height: 25px;
    width: 25px;
    border: 5px solid $unaro-red;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

    &--1 {
      @extend .stepper__inner--active-true;
      background-color: $unaro-yellow;
    }
    &--2 {
      @extend .stepper__inner--active-true;
      background-color: #ff6633;
    }
    &--3 {
      @extend .stepper__inner--active-true;
      background-color: $unaro-orange;
    }
    &--4 {
      @extend .stepper__inner--active-true;
      background-color: #ea6f54;
    }
    &--5 {
      @extend .stepper__inner--active-true;
      background-color: $unaro-red;
    }
  }
}
