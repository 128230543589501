@import '../colors';

.customer-details-page {
    display: flex;
    flex-direction: column;
    flex: 1;

    .customer-details-page-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 20px;


        .alert-btn {
            display: flex;
            flex-direction: row;
            border: 2.27px solid #EA5454;
            margin-right: 50px;
            border-radius: 25px;
            padding: 5px 20px;
            margin-top: 10px;
            align-items: center;

            .alert-icon {
                height: 25px;
                width: 25px;

                path {
                    fill: #EA5454;
                }
            }

            label {
                color: #EA5454;
                margin-left: 10px;
                font-size: 20px;
            }
        }
    }

    .customer-details-back {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 30px;

        .back-icon {
            width: 15px;
            height: 15px;
        }

        label {
            margin-left: 5px;
        }
    }

    .customer-details-container {
        display: flex;
        flex-direction: row;
        margin: 50px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
        background-color: white;

        .customer-details {
            display: flex;
            flex-direction: column;
            flex: 1;

            .customer-details-contact {
                display: flex;
                flex-direction: row;
                border-bottom: 1px solid rgba(0, 0, 0, 0.14);
                padding-bottom: 30px;

                img {
                    background-color: #bbbbbb;
                    position: relative;
                    top: -15px;
                    width: 120px;
                    height: 120px;
                    margin-left: 15px;
                }

                .customer-details-name-email {
                    display: flex;
                    flex-direction: column;
                    margin-left: 30px;
                    margin-right: 10px;
                    margin-top: 40px;

                    .customer-details-name {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        label {
                            font-size: 21px;
                            color: #3C4858;
                        }

                        .customer-details-registration-type {
                            margin-left: 10px;
                            width: 20px;
                            height: 20px;
                        }
                    }

                    .customer-details-email {
                        margin-top: 10px;
                        color: #3C4858;
                        // max-width: 200px;
                        // white-space: nowrap;
                        // text-overflow: ellipsis;
                        // overflow: hidden;
                    }
                }
            }

            .customer-details-contact-details {
                display: flex;
                flex-direction: row;
                padding: 20px 40px 50px 40px;

                .titled-label {
                    margin-top: 20px;
                }

                .customer-details-contact-details-left {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                }

                .customer-details-contact-details-right {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                }
            }

            .visits-container {
                display: flex;
                flex-direction: column;
                user-select: none;
                margin-top: -20px;

                .title {
                    color: #999999;
                    font-size: 10px;
                    padding: 0 40px;
                }

                .visits {
                    display: flex;
                    flex-direction: column;
                    margin: 10px 0;
                    height: 150px;
                    overflow-x: scroll;

                    .visit-selected{
                        background-color: #F1F1F1;
                    }

                    .visit {
                        display: flex;
                        flex-direction: row;
                        padding: 15px 40px;

                        :nth-child(2) {
                            margin-left: 70px;
                        }

                        label {
                            font-weight: 300;
                            line-height: 15px;
                            color: #3C4858;
                        }
                    }

                    .visit:hover {
                        background-color: #f1f0f0;
                    }
                }
            }
        }


        .customer-usage {
            display: flex;
            flex-direction: column;
            flex: 2;
            border-left: 1px solid rgba(0, 0, 0, 0.14);

            .customer-usage-statistics {
                display: flex;
                flex-direction: row;
                padding: 30px;
                
                .charts {
                    display: flex;
                    flex-direction: row;

                    .total-data-usage-chart {
                        width: 15vw;
                        height: 15vh;
                        display: flex;
                        flex-direction: column;
                        
                        .total-data-usage-label {
                            color: #999999;
                            font-size: 10px;
                            margin-bottom: 20px;
                        }

                        .total-data-usage-chart-description {
                            display: flex;
                            flex-direction: row;
                            margin-right: 40px;
                        }
                    }

                    .data-usage-persession-chart {
                        width: 15vw;
                        height: 15vh;
                        margin-right: 40px;
                        display: flex;
                        flex-direction: column;

                        label {
                            color: #999999;
                            font-size: 10px;
                            margin-bottom: 20px;
                        }
                    }
                }
                .data-usage-totals {
                    flex: 1;
                    display: flex;
                    flex-direction: row;

                    .titled-label {
                        margin-top: 20px;
                    }

                    .data-usage-totals-left {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                    } 

                    .data-usage-totals-right {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                    }
                }
            }

            .customer-usages {
                margin-bottom: 20px;

                .customer-usage-grid {
                    display: grid;
                    flex: 1;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                    padding: 10px 10px 10px 60px;
                }
            }

            .in-contact-container {
                flex: 1;
                display: flex;
                flex-direction: column;
                padding: 20px;
                justify-content: space-between;

                .title {
                    font-weight: 300;
                    font-size: 15px;
                    margin: 20px 10px 10px 40px; 
                    color: #5F5F5F;
                }   

                .in-contact {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr;
                    column-gap: 20px;
                    row-gap: 20px;
                    margin: 20px;

                    @media only screen and (max-width: 1500px) {
                        grid-template-columns: 1fr 1fr 1fr;
                    }

                    @media only screen and (max-width: 1320px) {
                        grid-template-columns: 1fr 1fr;
                    }
                }

                .navigation {
                    margin: 10px;
                    align-self: center;
                }
            }
        }
    }
}

.titled-label {
    display: flex;
    flex-direction: column;

    .title {
        color: #999999;
        font-size: 10px;
    }

    .icon-text {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 5px;

        .text {
            color: #3C4858;
            font-size: 15px;
        }
    
        .icon {
            width: 15px;
            height: 15px;
            margin-right: 5px;
    
            path {
                fill: #bbbbbb;
            }

            rect {
                fill: #bbbbbb;
            }
        }
    }
}

@media only screen and (max-width: 1450px) {

    .customer-details-page {
        .customer-details-container {
            .customer-usage {
                .customer-usage-statistics {
                    flex-direction: column;

                    .charts {
                        justify-content: space-between;
                    }
                    .data-usage-totals  {
                        margin-top: 40px;

                        .titled-label {
                            margin-right: 20px;
                        }
                        
                        .data-usage-totals-left {
                            flex-direction: row;

                        }

                        .data-usage-totals-right {
                            flex-direction: row;

                        }
                    }

                    .charts {
                        .data-usage-persession-chart  {
                            margin-right: 20px;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1100px) {

    .customer-details-page {
        .customer-details-container {
           flex-direction: column;

            .customer-usage {
               border-top: 1px solid rgba(0, 0, 0, 0.14);

                .customer-usage-statistics {

                    .charts {
                        .total-data-usage-chart {
                            width: 20vw;
                            height: 20vh;
                        }

                        .data-usage-persession-chart {
                            width: 20vw;
                            height: 20vh;
                        }
                    }
                }
            }
        }
    }
}