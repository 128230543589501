@import "../../colors.scss";

.text-redirect {
  padding: 24px;
  background-color: white;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__default-logo {
    max-height: 150px;
    max-width: 200px;
    object-fit: contain;
    margin-top: 20px;
  }

  &__tick {
    height: 80px;
    margin-top: 50px;
  }

  &__subtitle {
    color: $unaro-yellow;
    font-weight: bold;
    font-size: 18.69px;
    margin-top: 50px;
    margin-bottom: 10px;
  }

  &__description {
    text-align: center;
    font-size: 13px;
  }

  &__button {
    height: 50px;
    width: 80%;
    background-color: #a41c4a;
    border-radius: 30px;
    border: 0;
    color: white;
    font-size: 16.37px;
    font-weight: bold;
    margin-top: 70px;
  }
}
