.report-page {
    display: flex;
    flex-direction: column;
    padding: 30px;
    flex: 1;

    .rp-back {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 30px;
        cursor: pointer;
        user-select: none;
        width: fit-content;

        .back-icon {
            height: 15px;
            width: 15px;
        }

        label {
            font-weight: 300;
            font-size: 16px;
            color: #3C4858;
            margin-left: 5px;
            cursor: pointer;
        }
    }

    .rp-content {
        display: flex;
        flex-direction: row;
        flex: 1;

        .rp-title {
            font-weight: 400;
            font-size: 24px;
            margin: 0 10px;
        }

        .rp-left {
            display: flex;
            flex-direction: column;
            margin-right: 40px;
            flex: 1;

            .rp-message {
                display: flex;
                flex-direction: column;
                background: #FFFFFF;
                box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
                border-radius: 6px;
                flex: 2;
                padding: 20px;
    
                .rp-message-top {
                    padding-bottom: 20px;
                    border-bottom: 1px solid #CBCBCB;
                }
    
                label {
                    font-weight: 300;
                    font-size: 18px;
                }
    
                .rp-title {
                    font-weight: 400;
                    font-size: 24px;
                }
    
                .rp-from {
                    font-weight: 500;
                    font-size: 18px;
                    color: #EA5454;
                    margin-top: 30px;
                    margin-bottom: 20px;
                }
            }

            .rp-stats {
                display: flex;
                flex-direction: row;
                align-items: center;
                background: #FFFFFF;
                box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
                border-radius: 6px;
                padding: 20px;
                flex: 1;
                margin-top: 20px;

                .rp-chart {
                    flex: 1.2;
                }

                .pie-chart-placeholder {
                    background-color: #F7F7F7;
                    min-width: 120px;
                    min-height: 120px;
                    border-radius: 50%;
                    margin: 0 20px;
                }

                .rp-percents {
                    flex: 1;
                    margin-left: 10px;
                    display: flex;
                    flex-direction: column;

                    .rp-percent {
                        font-weight: 500;
                        font-size: 47px;
                        color: #FFCC33;
                    }

                    .rp-label {
                        font-weight: 500;
                        font-size: 20px;
                        color: #373737;
                    }

                    .not-delivered {
                        color: #EA5454;
                        margin-top: 10px;
                    }
                }
            }
        }
    
        .rp-summary {
            display: flex;
            flex-direction: column;
            background: #FFFFFF;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
            border-radius: 6px;
            flex: 2.5;
            padding: 20px;

            .rp-summary-top {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 15px;
                border-bottom: 1px solid #CBCBCB;

                .rp-summary-actions {
                    display: flex;
                    flex-direction: row;
                    margin-right: 10px;

                    .rp-btn {
                        outline: 0;
                        border: 0;
                        cursor: pointer;
                        margin-left: 20px;
                        color: white;
                        background-color: #EA5459;
                        border-radius: 21.75px;
                        padding: 7px 15px;
                        min-width: 100px;
                    }

                    .rp-ummary-btn {
                        background-color: #FFCC33;
                    }
                }
            }

            .rp-messages {
                flex: 1;
                margin-top: 20px;

                .rp-messages-header {
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                    padding: 10px;
                    margin-right: 22px;
                    margin-left: 10px;
                    border-bottom: 1px solid #CBCBCB;

                    label {
                        font-weight: 500;
                        font-size: 16px;
                    }
                }

                ::-webkit-scrollbar {
                    -webkit-appearance: none;
                    width: 12px;
                    background: #EFEFEF;
                    border-radius: 18px;
                }
                
                ::-webkit-scrollbar-thumb {
                    border-radius: 18px;
                    background-color:#D9D9D9;
                    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
                }

                .rpm-content {
                    display: flex;
                    flex-direction: column;
                    overflow-y: scroll;
                    height: calc(100vh - 340px);

                    .rpm-message {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                        padding: 10px;
                        margin: 0 10px;
                        border-bottom: 1px solid #CBCBCB;

                        label {
                            font-weight: 300;
                            font-size: 16px;
                        }

                        .rpm-date {
                            display: flex;
                            flex-direction: column;

                            label {
                                font-weight: 500;
                            }
                        }
                    }
                }
            }
        }
    }

}