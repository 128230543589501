.customers-page {
    display: flex;
    flex-direction: row;
    flex: 1;

    .cp-top {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 20px;
        background: #EA5454;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;

        label {
            font-family: Roboto;
            font-weight: 400;
            font-size: 22px;
            color: #FFFFFF;
        }
    }

    .customers-left {
        display: flex;
        flex-direction: column;
        flex: 1;
        margin-right: 20px;

        .cl-customers {
            display: flex;
            flex-direction: column;
            background: #FFFFFF;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
            border-radius: 6px;
            margin-bottom: 20px;

            .cp-content {
                display: flex;
                flex-direction: column;
                margin: 20px 0;

                .cl-selector * {
                    cursor: pointer;
                }

                .cl-selector {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .cls-icon {
                        margin-left: 20px;
                        margin-right: 10px;
                    }

                    .cls-info {
                        display: flex;
                        flex-direction: column;
                        margin: 10px;

                        :first-child {
                            margin-bottom: 5px;
                            font-size: 17px;
                            font-weight: 400;
                            font-family: Roboto;
                        }

                        :nth-child(2) {
                            font-size: 12px;
                            font-weight: 200;
                            font-family: Roboto;
                        }
                    }
                }

                .cl-selected {
                    background: #F2F2F2;
                    
                    .cls-icon {
                        path {
                            fill:#EA5454;
                        }

                        rect {
                            stroke: #EA5454;
                        }
                    }
                }
            }
        }

        .cl-audiences {
            display: flex;
            flex-direction: column;
            background: #FFFFFF;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
            border-radius: 6px;

            .cp-top  {
                background: #FF9933;
            }

            ::-webkit-scrollbar {
                -webkit-appearance: none;
                width: 10px;
                background: #EFEFEF;
                border-radius: 18px;
            }
            
            ::-webkit-scrollbar-thumb {
                border-radius: 18px;
                background-color:#D9D9D9;
                -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
            }

            .cla-content {
                display: flex;
                flex-direction: column;
                overflow-y: scroll;
                height: calc(100vh - 745px);
                margin: 15px;

                .cl-audience {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .audience-color {
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        margin-left: 10px;
                        margin-right: 15px;
                    }

                    .audience-info {
                        display: flex;
                        flex-direction: column;
                        margin: 10px;

                        :first-child {
                            margin-bottom: 5px;
                            font-size: 17px;
                            font-weight: 400;
                            font-family: Roboto;
                        }

                        :nth-child(2) {
                            font-size: 12px;
                            font-weight: 200;
                            font-family: Roboto;
                        }
                    }
                }
            }
        }
    }

    .customers-right {
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
        border-radius: 6px;
        flex: 2.5;

        .cp-top  {
            justify-content: space-between;
            background: #FFCC33;

            .navigation {
                .page-navigator {
                    button {
                        color: white;
                    }

                    .number-navigation {
                        background-color: #F2F2F2;
                       
                        .number-navigator {
                            label {
                                color: black;
                                font-weight: 300;
                                font-size: 14px;
                            }
                        }

                        .number-navigator-selected {
                            label {
                                color: white;
                            }
                        }
                    }
                }
            }

            .cp-right {
                display: flex;
                flex-direction: row;
                align-items: center;

                .cp-search {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: space-between;
    
                    border-radius: 30px;
                    padding: 7px 15px;
                    min-width: 200px;
                    background-color: white;
                    
                    input {
                        outline: none;
                        border: none;
                        font-size: 12px;
                    }
    
                    .search-icon {
                        line {
                            stroke: #C4C4C4;
                        }
    
                        circle {
                            stroke: #C4C4C4;
                        }
                    }
                }

                img {
                    margin-left: 30px;
                    margin-right: 10px;
                }
            }
        }

        .cp-actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 40px;

            .cp-action * {
                cursor: pointer;
            }

            .cp-action {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: 20px;

                label {
                    margin-left: 10px;
                    font-family: Roboto;
                    font-weight: 400;
                    font-size: 12px;
                    color: #EA5454;
                }
            }
        }

        .cp-selector {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            border: 1px solid #C4C4C4;
            border-radius: 50%;
            width: 16px;
            height: 16px;
            cursor: pointer;

            .cps-selected {
                width: 8px;
                height: 8px;
                background-color: #EA5454;
                border-radius: 50%;
            }
        }

        .cp-header {
            display: grid;
            grid-template-columns: 0.3fr 1fr 1fr 1fr 1fr;
            padding: 20px 0px;
            margin: 0 40px;
            border-bottom: 1px solid #EAEAEA;
            user-select: none;

            label {
                font-family: Roboto;
                font-weight: 700;
                font-size: 14px;
            }
        }

        ::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 10px;
            background: #EFEFEF;
            border-radius: 18px;
        }
        
        ::-webkit-scrollbar-thumb {
            border-radius: 18px;
            background-color:#D9D9D9;
            -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
        }

        .cp-contacts {
            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            margin: 20px 40px;
            height: calc(100vh - 500px);

            .cp-contact * {
                cursor: pointer;
            }

            .cp-contact {
                display: grid;
                grid-template-columns: 0.3fr 1fr 1fr 1fr 1fr;
                padding: 20px 0px;
                cursor: pointer;

                .cp-name {
                    font-family: Roboto;
                    font-weight: 700;
                    font-size: 14px;
                }

                .cp-info {
                    font-family: Roboto;
                    font-weight: 300;
                    font-size: 12px;
                }
            }
        }
       
    }
}