.new-campaign {
    display: flex;
    flex-direction: row;
    background-color: white;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    margin: 20px 30px;
    padding: 40px;
    user-select: none;

    .left {
        display: flex;
        flex-direction: column;
        flex: 1.5;

        .sender-id {

            button {
                margin-top: 0px;
                margin-left: 30px;
                background-color: #FFCC33;
            }
        }

        button {
            min-width: 120px;
            border-radius: 25px;
            color: white;
            align-self: center;
            background-color: #EA5459;
            border:none;
            padding: 10px;
            outline: 0;
            margin-right: 40px;
            margin-top: 20px;
            font-size: 14px;
            cursor: pointer;
        }

        .title {
            font-weight: 300;
            font-size: 25px;
            line-height: 23px;
            color: #3C4858;
            margin-bottom: 30px;
            margin-top: 10px;
        }
    
        .create-campaign-input {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 10px 0;
    
            .name {
                width: 200px;
                font-weight: 500;
                font-size: 15px;
                color: #3C4858;

                @media only screen and (max-width: 780px) {
                    width: 120px;
                }
            }
    
            .contact-dropdown-container {
                cursor: pointer;

                .dropdown {
                    width: 300px;
                    padding: 12px 20px 12px 20px;
                    border: 0.75px solid #DBDBDB;
                    box-sizing: border-box;
                    border-radius: 21.75px;
                    background-color: #F5F5F5;

                    @media only screen and (max-width: 780px) {
                        width: 250px;
                    }
    
                    label {
                        width: inherit;
                        cursor: pointer;
                    }
                }
    
                .options {
                    cursor: pointer;
    
                    label {
                        width: inherit;
                        cursor: pointer;
                    }
                }
    
                .dropdown-open {
    
                    .logo {
                        margin-top: 2px;
                     }
                }
            }

            .tags-dd {
                cursor: pointer;
              
                .dropdown {
                    width: 300px;
                    padding: 12px 20px 12px 20px;
                    border: 0.75px solid #DBDBDB;
                    box-sizing: border-box;
                    border-radius: 21.75px;
                    background-color: #F5F5F5;
                    cursor: pointer;
                }

                @media only screen and (max-width: 780px) {
                    width: 250px;
                }

                label {
                    width: inherit;
                    cursor: pointer;
                }

                .options {
                    height: 60px;
                }
            }

            input {
                border: 0.75px solid #DBDBDB;
                box-sizing: border-box;
                border-radius: 21.75px;
                background-color: #F5F5F5;
                padding: 10px 20px 10px 20px;
                width: 300px;
                outline: 0;
                font-size: 15px;

                @media only screen and (max-width: 780px) {
                    width: 250px;
                }
            }
    
            .info-container {
                display: flex;
                flex-direction: row;
                position: relative;
                cursor: pointer;

                .info {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    width: 20px;
                    height: 20px;
                    margin-left: 30px;
                    border: 2px solid #979797;
                    border-radius: 25px;
                    outline: 0;
                    cursor: pointer;
                    z-index: 3;
                    
                    label {
                        color: #979797;
                        cursor: pointer;
                    }
                }

                .template-info {
                    position: absolute;
                    font-family: unset;
                    width: 220px;
                    border: 1px solid #D9D9D9;
                    border-radius: 10px;
                    padding: 10px 70px 10px 50px;
                    left: 20px;
                    top: -10px;
                    color: black;
                    background-color: white;

                    @media only screen and (max-width: 1180px) {
                        padding-right: 10px;
                    }

                    @media only screen and (max-width: 1130px) {
                        width: 180px;
                    }

                    @media only screen and (max-width: 1080px) {
                        width: 120px;
                    }

                    @media only screen and (max-width: 1030px) {
                        width: 80px;
                    }

                    @media only screen and (max-width: 850px) {
                        visibility: hidden;
                    }
                }
            }
        }
    
        hr {
            width: 100%;
            border: 1px solid #EAEAEA;
            margin-top: 10px;
            margin-bottom: 10px;
        }
    
        .all-selector {
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
    
            .name {
                font-weight: 500;
                font-size: 15px;
                color: #3C4858;
            }
    
            .value {
                font-size: 15px;
                color: #3C4858;
                margin-left: 5px;
            }
    
            .switch {
                margin-left: 20px;
            }
        }
    
        .or {
            font-size: 15px;
            color: #3C4858;
            margin-top: 20px;
        }
    
        .actions {
            display: flex;
            flex-direction: row;
            align-items: center;
    
            :nth-child(2) {
                background-color: #FFCC33;
            }
        }
    }

    .right {
        display: flex;
        flex-direction: column;
        flex: 1;

        @media only screen and (max-width: 1150px) {
            display: none;
        }
    }

    .error-message {
        color: #E85558;
    }
}