@import './colors';

.demographics {
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: white;
    border-radius: 10px;
    margin-left: 20px;
    margin-bottom: 50px;
    padding: 20px;
    user-select: none;

    .demographics-top {
        display: flex;
        flex-direction: row;

        .demographics-logo {
            position: relative;
            top: -30px;
            display: flex;
            background-color: #FF8000;
            height: 60px;
            width: 60px;
            border-radius: 0 0 0 30px;
            justify-content: center;
            align-items: center; 

            img {
                height: 25px;
                width: 25px;
                align-self: center;
            }
        }

        label {
            margin-left: 20px;
        }
    }

    hr {
        border: 0;
        clear:both;
        display:block;
        width: 100%;               
        background-color: #eeeeee;
        height: 1px;
    }
    .demographics-stats-container {
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: space-evenly;

        .demographics-stats {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 20px;

            .demographics-icon {
                height: 80px;
            }

            label {
                font-size: 30px;
                font-weight: 500;
            }

            .male-percent {
                color: #FFCC33;
            }

            .female-percent {
                color: #EA5454;
            }
        }
    }
}