.email-templates {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    padding: 10px;

    .et-top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #C4C4C4;
        padding: 15px 10px;
        margin: 10px;
        
        .ett-left {
            display: flex;
            flex-direction: row;
            align-items: center;

            label {
                font-family: Roboto;
                font-weight: 300;
                font-size: 22px;
                border-right: 1px solid #C4C4C4;
                padding-right: 40px;
                cursor: pointer;
            }

            :nth-child(2) {
                margin-left: 40px;
            }
        }

        .ett-right {
            display: flex;
            flex-direction: row;
            align-items: center;

            .dropdown {
                right: 40px;
                background: white;
                border: 1px solid #C4C4C4;
                min-width: 180px;
                padding: 10px 20px;
                margin-right: 20px;

                label {
                    font-weight: 400;
                    font-family: Roboto;
                    color: #000000;
                }

                .logo {
                    path {
                        fill: #C4C4C4;
                    }
                }
            }

            .options {
                min-width: 180px;
            }

            .ett-search {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                border: 1px solid #C4C4C4;
                border-radius: 30px;
                padding: 7px 15px;
                min-width: 180px;
                
                input {
                    outline: none;
                    border: none;
                }

                .etts-icon {
                    line {
                        stroke: #C4C4C4;
                    }

                    circle {
                        stroke: #C4C4C4;
                    }
                }
            }
        }
    }

    ::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 10px;
        background: #EFEFEF;
        border-radius: 18px;
    }
    
    ::-webkit-scrollbar-thumb {
        border-radius: 18px;
        background-color:#D9D9D9;
        -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    }

    .et-content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        row-gap: 50px;
        column-gap: 50px;
        overflow-y: scroll;
        height: calc(100vh - 370px);
        box-sizing: border-box;
        padding: 10px;
        padding-bottom: 40px;
        padding-right: 30px;
        margin-top: 20px;
        margin-right: 10px;

        .et-template * {
            cursor: pointer;
        }

        .et-template {
            height: 180px;
            display: flex;
            flex-direction: row;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
            border-radius: 6px;
            padding: 20px;
            cursor: pointer;

            .ett-name {
                font-family: Roboto;
                font-weight: bold;
                font-size: 16px;
                margin-top: 20px;
            }

            .subject {
                font-family: Roboto;
                font-weight: 300;
                font-size: 10px;
                margin-top: 40px;
            }

            .template-info {
                flex: 2;
                display: flex;
                flex-direction: column;
            }

            .template-preview {
                flex: 1.2;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 6px;
                height: 100%;

                .dynamic-content-div {
                    width: 100%;
                    border: none;
                }
            }
        }

        .et-start-new {
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .ett-name {
                margin-top: 30px;
            }
        }
    }
}