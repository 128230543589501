@import '../colors';

.show-devices {
    display: flex;
    flex-direction: column;

    .devices-page-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: white;
        align-items: center;
        border-radius: 5px 5px 0px 0px;
        margin-top: 30px;

        .devices-page-top-left {
            display: flex;
            flex-direction: row;
            align-items: center;

            .devices-page-logo {
                position: relative;
                top: -20px;
                display: flex;
                background-color: $accent-color;
                height: 60px;
                width: 60px;
                border-radius: 0 0 0 30px;
                justify-content: center;
                align-items: center; 
                margin-left: 20px;

                .devices-page-logo-svg {
                    width: 30px;
                    height: 30px;

                    path {
                        fill: white;
                    }
            
                    rect {
                        stroke: white;
                        fill: $accent-color
                    }
            
                    line {
                        stroke: white;
                    }
                }
            }

            input {
                width: 220px;
                height: 30px;
                padding: 10px;
                border: 0.75px solid #DBDBDB;
                border-radius: 25px;
                background-color: #F5F5F5;
                color: #3C4858;
                outline: 0;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                background-image: url('../../images/search.svg');
                background-repeat: no-repeat;
                background-position: left center;
                background-position-x: 5px;
                margin-left: 20px;
                margin-top: -10px;
                padding-left: 30px;
            }
        }

        .devices-page-top-right {
            display: flex;
            flex-direction: row;
            align-items: center;

            .add-device-btn {
                min-width: 140px;
                border-radius: 25px;
                color: white;
                align-self: center;
                background-color: $accent-color;
                border:none;
                padding: 10px;
                outline: 0;
                margin: 10px 20px 20px 20px;
                cursor: pointer;
            }
            .add-device-btn:after {
                content: "Add a new device";
            }
        }
    }

    .devices-grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        background-color: #E2E2E2;
        padding: 20px;
    }
}

@media only screen and (max-width: 1400px) {
    .show-devices{
        .devices-grid {
           grid-template-columns: 1fr 1fr 1fr;
        }
    }
}

@media only screen and (max-width: 1100px) {
    .show-devices {
        .devices-grid {
           grid-template-columns: 1fr 1fr;
        }
    }
}

@media only screen and (max-width: 800px) {
    .show-devices {
        .devices-grid {
           grid-template-columns: 1fr;
        }
    }
}