.buttoned-popup {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
    user-select: none;
    outline: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .buttoned-popup-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.25);
        overflow: hidden;
        border-radius: 16px;
        outline: 0;
        width: 340px;
        padding: 20px;

        .close-icon {
            width: 14px;
            height: 14px;
            right: 30px;
            cursor: pointer;
            align-self: flex-end;
            margin-bottom: 20px;

            path {
                fill: #C4C4C4;
            }
        }

        label {
            align-self: center;
            font-weight: 400;
            font-size: 21px;
            color: #4D5059;
            padding: 0 40px;
            text-align: center;
        }

        .buttoned-popup-actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 20px 40px;
            margin-top: 20px;

            button {
                width: 120px;
                border-radius: 21.75px;
                color: white;
                padding: 10px 0;
                border: none;
                outline: 0;
                cursor: pointer;
                background-color: #FFCC33;
                font-weight: 500;
                font-size: 14px;
            }

            :nth-child(2) {
                background-color: #EA5459;
                margin-left: 20px;
            }
        }
    }
}