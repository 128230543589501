.price-plans {
    height: 100vh;
    background: url('../images/signup-background.png') no-repeat;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;

    .plan-loading {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #FFFFFF;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
        border-radius: 6px;
        width: 300px;
        height: 300px;
    }

    .price-plans-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #FFFFFF;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
        border-radius: 6px;
        padding: 50px;

        .price-plan-details {
            display: flex;
            flex-direction: column;
            align-items: center;

            .ppd-images {
                display: flex;
                flex-direction: column;
                align-items: center;

                .ppd-image {
                    display: flex;
                    flex-direction: row;
                    position: relative;
                    left: -100px;

                    .device-icon {
                        width: 273px;
                        height: 182px;
                        position: relative;
                        left: 135px;
                        top: 50px;
                    }
    
                    .products-icon {
                        z-index: 2;
                    }
                }
            }

            label {
                font-family: Roboto;
            }

            .ppd-title {
                font-size: 38px;
                font-weight: 400;

                span {
                    font-weight: 700;
                }
            }

            .ppd-text {
                margin-top: 20px;
                font-size: 20px;
                font-weight: 700;
                margin-bottom: 10px;

                span {
                    color: #EA5454;
                }
            }

            .pp-products {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                margin: 10px 0;

                .pp-product {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    background: #FFFFFF;
                    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.3);
                    padding: 20px;
                    margin: 15px;

                    img {
                        height: 40px;
                        object-fit: cover;
                    }

                    label {
                        font-family: Roboto;
                    }

                    .pp-product-name {
                        font-size: 20px;
                        font-weight: 700;
                        margin: 15px 0;
                    }

                    .cs-label {
                        font-size: 16px;
                        font-weight: 400;
                        color: #5B5B5B;
                    }

                    .plan-features {
                        .plan-feature {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            margin: 10px 0;

                            img {
                                width: 10px;
                                height: 10px;
                            }
    
                            label {
                                font-size: 10px;
                                margin-left: 5px;
                            }
                        }
                    }
                }
            }

            .continue-btn {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                margin-top: 10px;
                background: #EA5454;
                border-radius: 45px;
                width: 95px;
                align-self: center;
                color: white;
                font-weight: 300;
                font-size: 16px;
                font-family: Roboto;
                cursor: pointer;
                padding: 10px 20px;
                
                label {
                    cursor: pointer;
                }

                img {
                    cursor: pointer;
                }
            }
        }

        .add-payment-method {
            display: flex;
            flex-direction: column;
            align-items: center;

            .apm-top {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                border-bottom: 1px solid #C4C4C4;
                padding-bottom: 10px;

                .apm-title {
                    font-size: 16px;
                    font-weight: 400;
                    font-family: Roboto;
                    padding-bottom: 15px;
    
                    span {
                        font-weight: 700;
                        color: #EA5454;
                    }
                }

                .apm-subtitle {
                    color: #EA5454;
                    font-size: 14px;
                    font-weight: 700;
                    font-family: Roboto;
                }
            }

            .checkout-form {
                width: 100%;
            }
        }
    }
}