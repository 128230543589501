.custom-image-redirect {
  padding: 24px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  &__button {
    height: 50px;
    width: 80%;
    background-color: #a41c4a;
    border-radius: 30px;
    border: 0;
    color: white;
    font-size: 16.37px;
    margin-bottom: 70px;
    font-weight: bold;
  }
}
