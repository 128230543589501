@import './colors';

.chart-container {
    display: flex;
    border-radius: 10px;
    flex-direction: column;
    background-color: white;
    flex: 1;
    margin-bottom: 30px;
    // margin-left: 20px;
    padding: 10px 15px 10px 15px;
    user-select: none;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15);
  

    .chart-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .logo-title {
            display: flex;
            flex-direction: row;

            .logo {
                position: relative;
                display: flex;
                background-color: #ffcc33;
                height: 60px;
                width: 60px;
                border-radius: 0 0 0 30px;
                justify-content: center;
                align-items: center; 
                margin-top: -25px;

                img {
                    height: 25px;
                    width: 25px;
                    align-self: center;
                }
            }

            .visits-title {
                font-size: 20px;
                margin-left: 20px;
            }
        }

        .unit-selector {

            .chart-button {
                min-width: 60px;
                margin-left: 10px;
                border-radius: 25px;
                color: #717171;
                align-self: center;
                background-color: #E9E9E9;
                border:none;
                padding-top: 5px;
                padding-bottom: 5px;
                outline: 0;
                cursor: pointer;
            }

            .chart-button-selected {
                color: white;
                background-color: $accent-color;
                border:none;
            }
        }
    }

    .stats-chart {
        position: relative;
        margin: auto;
        width: 66vw;
        flex: 1;
        margin-top: 10px;
    }

    .stats-chart-row {
        width: 90vw;
    }

    .stats-chart-locked {
        width: 80vw;
    }
}  

.chart-container-row {
    flex: 2.5;
}

@media only screen and (max-width: 1400px) {
    .chart-container {
        .stats-chart {
            width: 64vw;
        }

        .stats-chart-row {
            width: 88vw;
        }

        .stats-chart-locked {
            width: 78vw;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .chart-container {
        .stats-chart-row {
            width: 86vw;
        }

        .stats-chart-locked {
            width: 75vw;
        }
    }
}

@media only screen and (max-width: 1100px) {
    .chart-container {
        .stats-chart {
            width: 60vw;
        }
        .stats-chart-row {
            width: 86vw;
        }

        .stats-chart-locked {
            width: 72vw;
        }
    }
}

@media only screen and (max-width: 1000px) {
    .chart-container {
        .stats-chart-row {
            width: 84vw;
        }

        .stats-chart-locked {
            width: 74vw;
        }
    }
}

@media only screen and (max-width: 900px) {
    .chart-container {
        .stats-chart-row {
            width: 82vw;
        }
    }
}

@media only screen and (max-width: 800px) {
    .chart-container {
        .stats-chart {
            width: 55vw;
        }
        .stats-chart-row {
            width: 80vw;
        }
    }
}

@media only screen and (max-width: 700px) {
    .chart-container {
        .stats-chart-row {
            width: 75vw;
        }
    }
}

@media only screen and (max-width: 600px) {
    .chart-container {
        .stats-chart-row {
            width: 72vw;
        }
    }
}