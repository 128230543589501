.payment-done-popup {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
    user-select: none;
    outline: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .payment-done-popup-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.25);
        overflow: hidden;
        border-radius: 16px;
        outline: 0;
        width: 330px;
        height: fit-content;
        margin-left: 90px;
        padding: 30px;

        .pd-title {
            font-family: Roboto Light;
            font-weight: 300;
            font-size: 30px;
            margin-top: 20px;
        }

        .pd-subtitle {
            font-family: Roboto;
            font-weight: 700;
            font-size: 14px;
            text-align: center;
            margin-top: 15px;

            span {
                color: #EA5454;
            }
        }

        .pds-error {
            color: #EA5454;
        }

        button {
            margin-top: 30px;     
            width: 90px;
            height: 40px;
            background: #EA5454;
            box-shadow: 0px 0px 14.5467px rgba(234, 84, 84, 0.6);
            border-radius: 45px;
            color: white;
            outline: 0;
            border: none;
            cursor: pointer;
        }

        .pd-actions {
            display: flex;
            font-display: row;
            align-items: center;

            .retry-btn {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 110px;
                background: #FFCC33;
                box-shadow: 0px 0px 14.5467px rgba(255, 204, 51, 0.6);
                border-radius: 45px;          
                cursor: pointer;
                margin-top: 30px;     
                height: 40px;
                margin-left: 20px;

                label {
                    color: white;
                    margin-right: 10px;
                    cursor: pointer;
                }

                img {
                    cursor: pointer;
                }
            }
        }
       
    }
}