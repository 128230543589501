@import './colors';

.network-item {
    border-radius: 10px;
    background-color: white;
    display: flex;
    flex-direction: column;
    margin: 10px;
    padding: 5px;

    .network-item-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 20px;

        .title {
            color: grey;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 200px;
            overflow: hidden;
        }

        .network-item-actions {
            display: flex;
            flex-direction: row;
            align-items: center;

            .network-item-action {
                width: 15px;
                height: 15px;
                margin-left: 10px;
                cursor: pointer;
            }

            .info {
                circle {
                    stroke: #DDDDDD;
                }

                path {
                    fill: #DDDDDD;
                }
            }

            .info:hover {
                circle {
                    stroke: #FF9A22;
                }

                path {
                    fill: #FF9A22;
                }
            }

            .edit {
                path {
                    stroke: #DDDDDD;
                }
            }

            .edit:hover {
                path {
                    stroke: #FECC33;
                }
            }

            .dashboard {
                rect {
                    stroke: #DDDDDD;
                }
            }

            .dashboard:hover {
                rect {
                    stroke: #ea5454;
                }

                path {
                    fill:  #ea5454;
                }
            }

            .users:hover {
                path {
                    stroke: #ea5454
                }

                rect {
                    stroke: #ea5454
                }
            }
        }

        // .actions {
        //     display: flex;
        //     flex-direction: row;

            .dashboard-btn {
                min-width: 70px;
                border-radius: 25px;
                color: white;
                // align-self: center;
                background-color: $accent-color;
                border:none;
                padding: 3px 10px 3px 10px;
                outline: 0;
                cursor: pointer;
            }

            // .buttons {
            //     display: flex;
            //     flex-direction: row;
            //     justify-content: space-between;
            //     margin-top: 10px;

            //     .action-btn {
            //         outline: 0;
            //         border: none;
            //         padding: 3px;
            //         font-weight: bold;
            //     }
            // }
        // }
    }

    hr {
        border: 0;
        clear:both;
        display:block;
        width: 100%;               
        background-color: #eeeeee;
        height: 1px;
      }

    .network-item-bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .location-container {
            display: flex;
            flex-direction: row;
            align-items: center;

            img {
                width: 15px;
                height: 15px;
            }

            label {
              color: grey;
              margin-left: 5px;
              white-space: nowrap;
              text-overflow: ellipsis;
              width: 180px;
              overflow: hidden;
            }
        }

        .venue {
            color: #bbbbbb;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 70px;
            overflow: hidden;
        }
    }
}