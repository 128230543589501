.email-done {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    padding: 20px;
    height: 100%;

    .ed-title {
        font-family: Roboto;
        font-weight: 400;
        font-size: 38px;
        margin: 50px 0;
    }

    .ed-info {
        font-family: Roboto;
        font-weight: 400;
        font-size: 16px;
        text-align: center;

        span {
            font-weight: 700;
            color: #EA5454;
        }
    }

    .scheduled-campaign {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 650px;

        :first-child {
            margin-bottom: 20px;
        }
    }

    .done-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: center;
        margin-top: 70px;
        
        .da-btn * {
            cursor: pointer;
        }

        .da-btn {
            display: flex;
            flex-direction: row;
            align-items: center;
            background: #EA5454;
            border-radius: 45px;
            padding: 0 20px;
            height: 40px;

            label {
                font-family: Roboto;
                font-weight: 300;
                font-size: 16px;
                color: #FFFFFF;
                margin-right: 10px;
            }
        }

        .save-template {
            margin-right: 20px;
            background: #FFCC33;
        }
    }
}