.plans-component {
    display: flex;
    flex-direction: column;
    align-items: center;

    .plans-title {
        font-size: 30px;
        font-weight: 300;
        font-family: Roboto Light;
        margin-top: 10px;
    }

    .plans-subtitle {
        font-size: 20px;
        font-weight: 700;
        font-family: Roboto;
        margin-top: 20px;
        margin-left: 30px;
        margin-right: 30px;

        span {
            color: #EA5454;
        }
    }

    .payment-loader {
        margin-top: 50px;
        margin-bottom: 10px;
    }

    .plans {
        display: flex;
        flex-direction: row;
        margin-top: 50px;
        margin-bottom: 10px;

        .plan {
            display: flex;
            flex-direction: column;
            align-items: center;
            background: #FFFFFF;
            box-shadow: 0px 1px 22px rgba(0, 0, 0, 0.2);
            border-radius: 12px;
            margin: 7px;

            .plan-top {
                display: flex;
                padding: 30px;
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
                
                label {
                    font-size: 20px;
                    font-weight: 700;
                    color: white;
                }
            }

            .plan-content {
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 100%;

                .plan-price {
                    font-size: 20px;
                    font-weight: 700;
                    margin: 20px 0;
                }

                .plan-items {
                    height: 100%;

                    .plan-item {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        margin: 7px 0;

                        label {
                            font-size: 10px;
                            margin-left: 5px;
                        }
                    }
                }

        
                button {
                    margin: 30px;
                    background: #EA5454;
                    box-shadow: 0px 0px 14.5467px rgba(234, 84, 84, 0.6);
                    border-radius: 45px;
                    border: none;
                    color: white;
                    outline: 0;
                    font-size: 16px;
                    padding: 10px;
                    width: 110px;
                    cursor: pointer;
                }
            }
        }
    }
}