@import './colors';

.network-page {
    display: flex;
    flex-direction: column;
    flex: 1;

    .network-title {
        font-size: 28px;
        color: grey;
        margin: 20px;
    }

    .actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 10px;
        margin-left: 20px;
        justify-content: space-between;

        .search {
            border-radius: 25px;
            margin-right: 20px;
            width: 200px;
            padding: 10px;
            background: transparent;
            border: 0;
            outline: 0;
            background-color: white;
        }

        .navigation {
            justify-self: center;
        }

        .right {
            display: flex;
            flex-direction: row;
            margin-right: 10px;
            
            .add-btn {
                min-width: 150px;
                border-radius: 25px;
                color: white;
                align-self: center;
                background-color: $accent-color;
                border:none;
                padding: 10px;
                outline: 0;
                cursor: pointer;
            }

            .export-btn {
                min-width: 140px;
                border-radius: 25px;
                color: white;
                align-self: center;
                background-color: #FFCC33;
                border:none;
                padding: 10px;
                outline: 0;
                cursor: pointer;
                margin-right: 20px;
            }

            .add-btn:after {
                content: "Add a new customer";
            }
        }

       
    }

    .network-grid {
        display: grid;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 20px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media only screen and (max-width: 1500px) {
    .network-page {
        .network-grid {
           grid-template-columns: 1fr 1fr 1fr;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .network-page {
        .network-grid {
           grid-template-columns: 1fr 1fr;
        }
    }
}

@media only screen and (max-width: 800px) {
    .network-page {
        .network-grid {
           grid-template-columns: 1fr;
        }
    }
}

@media only screen and (max-width: 1000px) {
    .network-page {
        .actions {
            .right {
                .add-btn {
                    min-width: 40px;
                    height: 40px;
                    border: none;
                    font-weight: bold;
                    padding: 8px;
                    border-radius: 50%;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                }

                .add-btn::after { 
                    content: "+";
                }
            }
        }
    }
}