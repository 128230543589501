.create-campaign {
     display: flex;
     flex-direction: column;
     flex: 1;

    .back-btn {
        margin-top: 20px;
    }

    .top {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .steps {
            display: flex;
            flex-direction: row;
            align-items: center;

        
            .step {
                display: flex;
                flex-direction: column;
                align-items: center;
                user-select: none;

                .number {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 30px;
                    height: 30px;
                    background-color: #FFCC33;
                    border-radius: 25px;
                    cursor: pointer;

                    label {
                        color: white;
                        font-size: 15px;
                        cursor: pointer;
                    }
                }
            }

            .line {
                width: 150px;
                height: 2px;
                background-color: #D7D7D7;
                margin: 0 10px;
            }
        }

        .titles {
            display: flex;
            flex-direction: row;
            align-items: center;
            
            label {
                margin: 10px 42px;
                font-size: 14px;
            }
        }
    }
  
}