@import './colors';

.login-page {
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center top;
  height: 100vh;
  width: 100%;
  align-content: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .login-form {
    width: 320px;
    z-index: 90;
    background-color: white;
    border-radius: 15px;
    padding: 80px;
    display: flex;
    flex-direction: column;

    form {
      display: flex;
      flex-direction: column;
    }

    .error-message-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      visibility: visible;
      top: -20px;
      background-color: red;
      padding: 15px;
      margin-left: -80px;
      margin-right: -80px;
      margin-bottom: 10px;

      label {
        color: black;
      }
    }

    .error-message-container-hidden {
      visibility: hidden;
    }
  }

  .logo {
    width: 280px;
    align-self: center;
    margin-bottom: 10px;
  }

  input {
    border: 0;
    outline: 0;
    background: transparent;
    height: 10px;
    padding: 10px;
    font-size: 15px;
  }

  .password-input {
    margin-top: 20px;
  }

  a {
    color: $accent-color;
    font-size: 14px;
    margin-top: 10px;
    text-decoration: none;
  }

  input[type=submit] {
    margin-top: 30px;
    margin-bottom: 20px;
    min-width: 120px;
    height: 35px;
    border-radius: 25px;
    color: white;
    align-self: center;
    background-color: $accent-color;
    border: none;
    outline: 0;
    font-size: 12px;
    cursor: pointer;
  }

  .sign-up {
    color: #5B5B5B;
    font-size: 16px;
    font-weight: 400;
    font-family: Roboto;
    margin-top: 20px;

    span {
      color: #EA5454;
      font-weight: 700;
      cursor: pointer;
    }
  }

  .remember-me {
    display: flex;
    flex-direction: row;
    align-items: center;

    .remember-me-logo {
      width: 18px;
      height: 18px;
    }

    label {
      font-size: 14px;
      margin-left: 5px;
    }
  }
}


///////////// Login Banner Styling
.App {
  height: 100vh;
  display: flex;
}

.appAside {
  width: 50%;
  overflow-y: hidden;
}

.appForm {
  width: 50%;
  padding: 25px 40px;
  overflow-y: hidden;
}

.pageSwitcher {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10%;
}

.pageSwitcherItem {
  background-color: #40434e;
  color: #9da6b1;
  padding: 10px 25px;
  cursor: pointer;
  font-size: 0.9em;
  border: none;
  outline: none;
  display: inline-block;
  text-decoration: none;
}

.pageSwitcherItem-active {
  background-color: #6b5b95;
  color: white;
}

.pageSwitcherItem:first-child {
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
}

.pageSwitcherItem:last-child {
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
}

.formCenter {
  margin-bottom: 100px;
}

.formTitle {
  color: #707c8b;
  font-weight: 300;
  margin-bottom: 50px;
}

.formTitleLink {
  color: #707c8b;
  text-decoration: none;
  display: inline-block;
  font-size: 1.7em;
  margin: 0 10px;
  padding-bottom: 5px;
}

.formTitleLink:first-child {
  margin-left: 0;
}

.formTitleLink-active {
  color: white;
  border-bottom: 1px solid #6b5b95;
}

.formField {
  margin-bottom: 40px;
}

.formFieldLabel {
  display: block;
  text-transform: uppercase;
  font-size: 0.9em;
  color: white;
}

.formFieldInput {
  width: 85%;
  background-color: transparent;
  border: none;
  color: white;
  outline: none;
  border-bottom: 1px solid #445366;
  font-size: 1em;
  font-weight: 300;
  padding-bottom: 10px;
  margin-top: 10px;
}

.formFieldInput::placeholder {
  color: #616e7f;
}

.formFieldButton {
  background-color: #6b5b95;
  color: white;
  border: none;
  outline: none;
  border-radius: 25px;
  padding: 15px 70px;
  font-size: 0.8em;
  font-weight: 500;
}

.formFieldLink {
  color: #66707d;
  text-decoration: none;
  display: inline-block;
  border-bottom: 1.5px solid #6b5b95;
  padding-bottom: 5px;
}

.formFieldCheckboxLabel {
  color: #646f7d;
  font-size: 0.9em;
}

.formFieldCheckbox {
  position: relative;
  top: 1.5px;
}

.formFieldTermsLink {
  color: white;
  border-bottom: 1px solid #6b5b95;
  text-decoration: none;
  display: inline-block;
  padding-bottom: 2px;
  margin-left: 5px;
}

.socialMediaButtons {
  padding: 10px 100px 10px 0px;
}

.carousel-next {

}