@import '../colors';

.device-item {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
    border-radius: 6px;    
    margin: 30px;
    align-items: center;
    user-select: none;
    padding: 20px;

    img {
        height: 150px;
    }

    .devices-delete-icon {
        width: 15px;
        height: 15px;
        align-self: flex-end;
        cursor: pointer;

        path {
            fill: #aaaaaa;
        }
    }

    .devices-delete-icon:hover {

        path {
            fill: $accent-color;
        }
    }

    .devices-select-icon {
        width: 25px;
        height: 25px;
        align-self: flex-end;
    }

    hr {
        border: 0;
        clear:both;
        display:block;
        width: 100%;               
        background-color: #eeeeee;
        height: 1px;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .device-item-title {
        color: #3C4858;
        font-size: 17px;
    }

    .device-item-title-edit {
        color: #3C4858;
        border: none;
        border:none;
        outline: 0;
        font-size: 17px;
        padding: 0px;
        margin-top: 20px;
    }

    .device-item-model {
        color: #878787;
        font-size: 12px;
        margin-top: 5;
    }

    .device-item-mac {
        color: #3C4858;
        font-size: 20px;
    }

    .input-name {
        font-size: 18px;
        color: #3C4858;
    }

    .input-ssid {
        font-size: 15px;
        color: #878787;
    }

    .error-input::placeholder {
        color: $accent-color;
    }

    input {
        border: none;
        justify-self: center;
        align-self: center;
        border:none;
        box-sizing: border-box;
        padding: 10px 10px 10px 10px;
        margin-left: 10px;
        outline: 0;
        font-size: 15px;
        text-align: center;
    }
}