.subscriptions-page {
    display: flex;
    flex-direction: column;
    padding: 30px;
    user-select: none;

    .subscriptions-header {
        display: flex;
        flex-direction: row;
        border-bottom: 0.5px solid #C4C4C4;
        
        label {
            flex: 1;
            font-weight: normal;
            font-size: 19.5px;
            line-height: 23px;
            color: #000000;
            padding-bottom: 20px;
        }
    }

    .subscriptions {
        overflow-y: scroll;
        height: calc(100vh - 350px);

        .subscription {
            display: flex;
            flex-direction: row;
            padding: 20px 0;
    
            label {
                font-weight: 300;
                font-size: 16.5px;
                line-height: 19px;
                color: #3F3F3F;
            }
    
            .subscription-field {
                flex: 1;
                display: flex;
                flex-direction: row;
                align-items: center;
                
                label {
                    margin-left: 10px;
                }
    
                .active {
                    path {
                        fill: #78E467;
                    }
                }
    
                .expired {
                    path {
                        fill: #EA5454;
                    }
                }
            }
    
            img {
                width: 40px;
            }
    
            .plan {
                display: flex;
                flex-direction: row;
                align-items: center;
            }
    
            .add-license {
                display: flex;
                flex-direction: row;
                align-items: center;
                cursor: pointer;
    
                label {
                    width: fit-content;
                    padding: 7px 20px;
                    color: white;
                    border-radius: 25px;
                    background-color: #FFCC33;
                    margin-left: -20px;
                    width: 90px;
                    text-align: center;
                    cursor: pointer;
                }
    
                .owned {
                    background-color: #FF9933;
                }

                .owened-user {
                    background-color: #FF9933;
                }
    
                .owned::after {
                    content: 'Owned';
                }

                .owened-user::after {
                    content: 'Owned';
                }
        
                .owned:hover {
                    background-color: #EA5454;
                }
    
                .owned:hover::after {
                    content: 'Remove';
                }
            }
        }
    }
}