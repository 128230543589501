.main-dashboard {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 10px;
    user-select: none;

    .layout {
        min-height: 200px;
    }

    .row {  
        display: flex;
        flex-direction: row;
        flex: 1;
        width: 100%;
    }
}