@import '../colors';

.show-integrations {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
    border-radius: 5px;

    .show-integrations-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: white;
        padding: 10px;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        align-items: center;

        label {
            margin-left: 30px;
            color: #3C4858;
        }

        button {
            min-width: 100px;
            border-radius: 25px;
            color: white;
            align-self: center;
            background-color: $accent-color;
            border: none;
            padding: 10px;
            outline: 0;
            margin-right: 20px;
            cursor: pointer;
        }
    }
    
    .show-integrations-items {
        display: flex;
        flex-direction: column;
        background-color: #E2E2E2;
        padding: 30px;

        .no-integrations {
            align-self: center;
        }
    }
}