.invoices-page {
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
    flex: 1;

    .header {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
        margin-top: 30px;
        border-bottom: 0.5px solid #C4C4C4;
        padding: 10px 0px;
        margin-left: 30px;
        margin-right: 30px;
        padding-right: 20px;

        label {
            font-weight: 400;
            font-size: 16px;
        }
    }

    .invoices-loader {
        flex: 1;
        display: flex;
        align-content: center;
        justify-content: center;
        margin-top: 50px;
    }

    .invoices {
        overflow-y: scroll;
        height: calc(100vh - 370px);
        padding: 10px 0px;
        margin-left: 30px;
        margin-right: 10px;
        padding-right: 40px;

        .invoice {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            padding: 15px 0;

            .invoice-type {
                display: flex;
                flex-direction: row;
                align-items: baseline;

                img {
                    height: 20px;
                    margin-right: 10px;
                }
            }

            label {
                font-size: 16px;
                font-weight: 300;
                font-family: Roboto;
                color: #3F3F3F;
            }
        }
    }
}