.update-payment-method-popup {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
    user-select: none;
    outline: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .update-payment-method-popup-content {
        display: flex;
        flex-direction: column;
        background-color: white;
        box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.25);
        overflow: hidden;
        border-radius: 16px;
        outline: 0;
        width: 400px;
        padding: 30px;

        .update-payment-method-top {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #CCCED6;
            width: 100%;
            margin-bottom: 10px;

            label {
                font-size: 16px;
                color: #4D5059;
                margin-top: 20px;
                font-weight: 700;
                font-family: Roboto;
            }

            .close-icon {
                width: 15px;
                height: 15px;
                cursor: pointer;
        
                path {
                    fill: #aaaaaa;
                }

                :hover {
                    path {
                        fill: #EA5454;
                    }
                }
            }
        }

        label {
            color: #5B5B5B;
            font-size: 14px;
            font-family: Roboto;
            margin-top: 15px;
            margin-bottom: 10px;
        }
    
        input {
            border: 1px solid #C4C4C4;
            border-radius: 21.75px;
            outline: none;
            padding: 7px 20px;
        }
    
        .input-error {
            border-color: #EA5454;
        }
    
        .card-element {
            border: 1px solid #C4C4C4;
            border-radius: 21.75px;
            padding: 7px 20px;
        }
    
        .cf-row-input {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
    
            input {
                width: 150px;
            }
        }
    
        .payment-loader {
            align-self: center;
            margin-top: 30px;
        }
    
        .payment-actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            align-self: center;
            margin-top: 30px;
    
            :first-child {
                background: #EA5454;
                box-shadow: 0px 0px 12px rgba(234, 84, 84, 0.6);
                margin-right: 20px;
            }
    
            button {
                width: 90px;
                height: 40px;
                background: #FFCC33;
                box-shadow: 0px 0px 12px rgba(255, 204, 51, 0.6);
                border-radius: 45px;
                color: white;
                outline: 0;
                border: none;
                cursor: pointer;
            }
        }
    }
}