@import "./colors";

.design-page {
  display: flex;
  flex-direction: column;
  flex: 1;
  // margin-top: 20px;

  .design-page-title {
    font-size: 30px;
    margin-left: 15px;
    margin-bottom: 15px;
    margin-top: 15px;
  }

  .design-title {
    font-size: 25px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
    margin-left: 20px;
    margin-bottom: 20px;
  }

  .design-description {
    font-size: 14px;
    margin: 20px;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
      Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  }

  .design-page-editor {
    display: flex;
    flex-direction: column;
    margin: 20px;
    border-radius: 5px;
    background-color: white;

    .design-page-editor-top {
      display: flex;
      background-color: white;
      flex-direction: row;
      align-items: center;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 5px 5px 0px 0px;

      .design-page-editor-logo {
        position: relative;
        top: -20px;
        display: flex;
        background-color: $accent-color;
        height: 60px;
        width: 60px;
        border-radius: 0 0 0 30px;
        justify-content: center;
        align-items: center;

        .design-page-editor-logo-svg {
          width: 30px;
          height: 30px;

          path {
            fill: white;
          }
        }
      }

      .design-page-editor-title {
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
          Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
        font-weight: 300;
        margin-left: 20px;
      }
    }

    .design-page-editor-types {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      padding: 23px;

      @media only screen and (max-width: 1140px) {
        flex-direction: column;
        align-items: unset;
      }

      .design-page-editor-type {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        margin: 15px;
        border-radius: 30px;
        flex-basis: 30%;
        box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.25);

        @media only screen and (max-width: 1140px) {
          margin-right: 50px;
        }

        .design-page-editor-type-top {
          display: flex;
          flex-direction: column;
          align-items: center;
          border-top-left-radius: 30px;
          border-top-right-radius: 30px;
          width: 100%;
          background-color: #fcca36;

          img {
            height: 250px;
          }
        }
        .tt {
          background-color: #ea5454;
        }

        .wifi {
          img {
            width: 100%;
            object-fit: fill;
            border-top-left-radius: 30px;
            border-top-right-radius: 30px;
          }
        }

        .design-page-editor-type-bottom {
          display: flex;
          flex-direction: column;
          align-items: center;
          padding: 20px;
          border-bottom-right-radius: 30px;
          border-bottom-left-radius: 30px;

          .design-page-editor-type-buttons {
            display: flex;
            flex-direction: row;

            .design-page-editor-type-button {
              display: flex;
              flex-direction: row;
              margin: 10px;
              background-color: $unaro-red;
              border-radius: 20px;
              padding: 8px 15px;
              color: white;
              align-items: center;
              cursor: pointer;

              label {
                margin-left: 5px;
                margin-right: 5px;
                font-weight: 300;
                font-size: 0.7rem;
                cursor: pointer;
              }
            }

            .existing {
              background-color: #f39638;

              label {
                margin-left: 0px;
              }
            }
          }

          .design-page-editor-type-title {
            font-size: 31px;
            margin: 10px 0px;
          }
        }
      }
    }
  }
}
