.save-template-popup {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
    user-select: none;
    outline: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .save-template-popup-content {
        display: flex;
        flex-direction: column;
        background-color: white;
        box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.25);
        overflow: hidden;
        border-radius: 16px;
        outline: 0;
        width: 400px;    
        padding: 20px;    
        
        .save-template-top {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            border-top-right-radius: 16px;
            border-top-left-radius: 16px;
            padding-bottom: 15px;
            position: relative;
            border-bottom: 1px solid #CCCED6;

            label {
                font-weight: 400;
                font-size: 23px;
                color: #4D5059;
                margin-left: 10px;
            }

            .close-icon {
                width: 14px;
                height: 14px;
                position: absolute;
                right: 10px;
                top: 15px;
                cursor: pointer;

                path {
                    fill: #C4C4C4;
                }
            }
        }

        .save-template-content {
            display: flex;
            flex-direction: column;
            padding: 20px 5px;

            input {
                border: 0.75px solid #DBDBDB;
                box-sizing: border-box;
                border-radius: 21.75px;
                padding: 10px 20px 10px 20px;
                outline: 0;
                font-size: 15px;
                margin: 10px 0;
                background-color: #F5F5F5;
            }

            .input-error {
                border: 0.75px solid #EA5454;
            }
        }

        .error-message {
            color: #EA5454;
            width: 40vw;
            margin-top: 10px;
            margin-left: 20px;
            margin-bottom: 10px;
            margin-top: -10px;
        }

        .save-tempalte-actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;

            button {
                width: 150px;
                background-color: #EA5454;
                outline: 0;
                border: none;
                color: white;
                padding: 10px;
                border-radius: 25px;
                cursor: pointer;
            }

            :nth-child(2) {
                background-color: #FFCC33;
                margin-left: 20px;
            }
        }
    }
}