.add-payment-method-popup {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
    user-select: none;
    outline: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .add-payment-method-popup-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.25);
        overflow: hidden;
        border-radius: 16px;
        outline: 0;
        width: 440px;
        padding: 20px;

        .add-payment-method-top {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #CCCED6;
            padding: 0px 10px 10px 10px;
            width: 90%;

            label {
                font-size: 16px;
                color: #4D5059;
                margin-top: 20px;
                font-weight: 700;
                font-family: Roboto;
            }

            .close-icon {
                width: 15px;
                height: 15px;
                cursor: pointer;
        
                path {
                    fill: #aaaaaa;
                }

                :hover {
                    path {
                        fill: #EA5454;
                    }
                }
            }
        }

        .add-payment-method-content {
            display: flex;
            flex-direction: column;
        }
    }
}