.manage-campaigns {
    display: flex;
    flex-direction: column;
    padding: 20px;
    flex: 1;
    
    .mc-top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;

        label {
            font-weight: 300;
            font-size: 23px;
            color: #3C4858;
        }

        .mc-actions {
            display: flex;
            flex-direction: row;
            align-items: center;

            input {
                outline: 0;
                border: none;
                border-radius: 18px;
                background-color: white;
                padding: 10px 20px;
                width: 250px;
            }

            button {
                margin-left: 20px;
                color: white;
                background-color: #EA5459;
                border: none;
                outline: none;
                border-radius: 20px;
                padding: 10px 20px;
                cursor: pointer;
            }
        }
    }

    .mc-content {
        display: flex;
        flex-direction: column;
        background-color: white;
        margin-top: 50px;
        border-radius: 5px;

        .mc-content-top {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 15px 10px;

            .left {
                display: flex;
                flex-direction: row;
                align-items: center;
                position: relative;

                .mc-icon-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    background-color: #EA5454;
                    border-radius: 0px 0px 0px 28.5px;
                    width: 60px;
                    height: 60px;
                    top: -35px;

                    .mc-icon {
                        circle {
                            stroke: white;
                            fill: white;
                        }
                
                        line {
                            stroke: white;
                        }
                    }
                }

                label {
                    font-weight: 300;
                    font-size: 18px;
                    margin-left: 80px;
                }
            }

            .right {
                display: flex;
                flex-direction: row;
                align-items: center;

                .filter-dd {
                    .dropdown {
                        background: #F5F5F5;
                        border: 0.75px solid #DBDBDB;
                        box-sizing: border-box;
                        border-radius: 21.75px;
                        padding: 10px 20px;
                        min-width: 200px;
                        align-items: center;
                        z-index: 3;
                        
                        label {
                            font-weight: 300;
                            font-size: 12px;
                            color: #3C4858;
                        }
                    }

                    .dropdown-open {
                        .logo {
                            margin-top: 0px;
                         }
                    }

                    .options {
                        top: 20px;
                        z-index: 2;
                        min-width: 200px;
                        height: 100px;
                        padding-top: 20px;
                        border-radius: 0px 0px 5px 5px;

                        label {
                            font-weight: 300;
                            font-size: 12px;
                            color: #3C4858;
                        }

                        label:hover {
                            color: white;
                        }
                    }
                }
            }
        }

        .campaigns {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            background-color: #E2E2E2;
            padding: 30px 10px;

            @media only screen and (max-width: 1400px) {
                grid-template-columns: 1fr 1fr 1fr;
            }

            @media only screen and (max-width: 1100px) {
                grid-template-columns: 1fr 1fr;
            }

            @media only screen and (max-width: 800px) {
                grid-template-columns: 1fr;
            }

            .mc-campaign {
                display: flex;
                flex-direction: column;
                background: #FFFFFF;
                box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
                border-radius: 5px;
                margin: 20px;
                padding: 15px;

                label {
                    font-weight: 300;
                    font-size: 23px;
                    color: #3C4858;
                    padding-bottom: 20px;
                }

                .actions {
                    border-top: 1px solid #EEEEEE;

                    button {
                        margin-top: 15px;
                        width: 100px;
                        border: none;
                        outline: none;
                        cursor: pointer;
                        margin-right: 15px;
                        border-radius: 20px;
                        color: white;
                        background-color: #FECB45;
                        padding: 3px 0;
                        font-weight: bold;
                    }

                    :nth-child(2) {
                        background-color: #E8555D;
                    }
                }
            }
        }
    }
}