@import "../colors.scss";

.fade-in {
  animation: fade-in 1s;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.facebook-grow {
  display: flex;
  flex-direction: column;
  padding: 24px;

  &__title {
    font-weight: bold;
    font-size: 16px;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 30px;
  }

  &__switch {
    display: flex;
    align-items: center;
    padding: 24px;
  }

  &__switch-label {
    margin-right: 24px;
  }

  &__content-divider {
    border: 0;
    clear: both;
    display: block;
    width: 90%;
    background-color: $grey-2;
    height: 1px;
  }

  &__enter-url-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    align-content: center;
    margin-bottom: 24px;
    margin-top: 24px;
  }

  &__bottom-content {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__input {
    font-family: Roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 11px;
    border-radius: 25px;
    margin-right: 55px;
    width: 265px;
    padding: 12px 20px;
    background: transparent;
    border-color: $grey-2;
    outline: 0;
    background-color: white;
    border-style: solid;
    border-width: 1px;
  }
}
