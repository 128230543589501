@import './colors';

.number-navigation {
    display: flex;
    flex-direction: row;
    border-radius: 25px;
    background-color: #dddddd;
    padding: 2px;

    .number-navigator {
        align-content: center;
        justify-content: center;
        display: flex;
        border-radius: 25px;
        width: 8px;
        height: 8px;
        color: grey;
        padding: 8px;
        outline: 0;
        text-align: center;
        margin: 2px;
        cursor: pointer;

        label {
            align-self: center;
            text-align: center;
            cursor: pointer;
        }
    }
    
    .number-navigator-selected {
        background-color: $accent-color;
        color: white
    }
}