@import '../colors';

.show-customers-page {
    display: flex;
    flex-direction: column;
    flex:1;

    .actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 20px;
        margin-right: 10px;
        margin-left: 20px;
        justify-content: space-between;

        .actions-top-left {
            display: flex;
            flex-direction: row;

            .back-btn {
                width: 60px;
                margin: 0px 20px 0px 0px;
            }

            .search {
                border-radius: 25px;
                width: 200px;
                padding: 10px;
                background: transparent;
                border: 0;
                outline: 0;
                background-color: white;
            }
        }

        .navigation {
            justify-self: center;
        }

        .action-top-right {
            display: flex;
            flex-direction: row;
        }

        .filters {
            display: flex;
            flex-direction: row;
        }
    }

    .action-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: $accent-color;
        border-radius: 15px;
        padding: 5px 10px;
        margin: 0px 10px;
        height: fit-content;
        user-select: none;
        outline: 0;

        .action-btn-icon {
            height: 20px;
            width: 20px;
        }

        label {
            color: white;
            margin-left: 5px;
            font-size: 15px;
            @media only screen and (max-width: 1250px) {
                display: none;
            }
        }
    }

    .alert-btn {
        width: fit-content;
        position: fixed;
        bottom: 30px;
        right: 30px;
    }
    .calendar {
        position: absolute;
        top: 140px;
        right: 20px;
        z-index: 3;
    }
    
    .customer-grid {
        display: grid;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 30px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media only screen and (max-width: 1400px) {
    .customers-page {
        .customer-grid {
           grid-template-columns: 1fr 1fr 1fr;
        }
    }
}

@media only screen and (max-width: 1100px) {
    .customers-page {
        .customer-grid {
           grid-template-columns: 1fr 1fr;
        }
    }
}

@media only screen and (max-width: 800px) {
    .customers-page {
        .customer-grid {
           grid-template-columns: 1fr;
        }
    }
}