@import '../colors.scss';

.venue-details {
    display: flex;
    flex-direction: row;
    margin-left: 30px;
    margin-right: 30px;
    user-select: none;

    .venue-details-title {
        font-size: 22px;
        font-weight: 300;
        color: #3C4858;
        margin-left: 5px;
    }

    .venue-item {
        margin-top: 30px;
        margin-right: 30px;
        margin-left: 0px;
    }

    .venue-details-venue-network {
        display: flex;
        flex-direction: column;
        flex: 1;

        .venue-details-venue {
            display: flex;
            flex-direction: column;
        }

        .venue-details-network {
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            margin-right: 30px;

            @media only screen and (max-width: 1170px) {
               margin-right: 0px;
            }

            .venue-details-title-network {
                margin-bottom: 20px;
            }

            .create-network-btn {
                width: fit-content;
                border-radius: 25px;
                color: white;
                background-color: $accent-color;
                border: none;
                padding: 10px 20px 10px 20px;
                outline: 0;
                margin: 10px 10px 0px 0;
                cursor: pointer;
            }
        }
    }


    .venue-details-devices {
        display: flex;
        flex-direction: column;
        flex: 2;

        .show-devices {
            .devices-grid {
                 grid-template-columns: 1fr 1fr 1fr;
            }
        }
    }
}

@media only screen and (max-width: 1300px) {
    .venue-details   {
        .venue-details-devices {
            .show-devices {
                .devices-grid {
                     grid-template-columns: 1fr 1fr;
                }
            }
        }
    }
}

@media only screen and (max-width: 1170px) {
    .venue-details   {
        flex-direction: column;

        .venue-item {
            margin-right: 0px;
        }

        .venue-details-devices {
            margin-top: 20px;

            .show-devices {
                .devices-grid {
                     grid-template-columns: 1fr 1fr 1fr;
                }
            }
        }
    }
}

@media only screen and (max-width: 900px) {
    .venue-details   {
        .venue-details-devices {
            .show-devices {
                .devices-page-top {
                    .devices-page-top-right {
                        .add-device-btn {
                            font-weight: bold;
                            min-width: 40px;
                            height: 40px;
                            border: none;
                        } 

                        .add-device-btn::after {
                            content: "+";
                        }
                    }
                }

                .devices-grid {
                     grid-template-columns: 1fr 1fr;
                }
            }
        }
    }
}