@import '../colors';

.info-popup {
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    z-index: 1;
    justify-content: space-between;
    background-color: white;

    padding: 10px 10px 10px 20px;
    border-radius: 5px;
    background-color: $accent-color;

    label {
        color: white;
        margin-right: 10px;
    }


    .delete-icon {
        width: 10px;
        height: 10px;

        path {
            fill: white;
        }
    }
}