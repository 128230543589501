@import './colors';

.sidebar {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: white;
    z-index: 99999988;
    box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.14);
    width: 210px;
    height: 100vh;
    transition: width 1s;

    @media only screen and (max-width: 1000px) {
        width: 80px;
    }
    
    label {
        font-size: 14px;
    }

    .menu {
        display: flex;
        flex-direction: column;

        .menu-icons {
            display: flex;
            flex-direction: row;
            margin-top: 30px;
            margin-bottom: 30px;
            margin-left: 5px;
            align-items: center;
            
            .hamburger-icon {
                width: 20px;
                height: 20px;
                margin-right: 10px;
                visibility: visible;
                transition: visibility 1s linear;
                transition-delay: 1s;
            }

            .menu-logo {
                display: flex;
                flex-direction: row;
                width: 150px;
                height: 30px;
                margin-right: 10px;
                margin-left: 20px;
                transition: width 1s linear;

                .logo-icon {
                    width: 30px;
                }

                .logo {
                    visibility:visible;
                    opacity: 1;
                    transition: visibility 1s, opacity 1s linear;
                    transition-delay: 0.3s;
                }
            }
        }

        .menu-btn {
            display: flex;
            flex-direction: row;
            align-self: center;
            background-color: #EEEEEE;
            border-radius: 5px;
            padding: 5px;
            margin-bottom: 30px;
            cursor: pointer;

            .line {
                background-color: white;
                width: 2px;
                height: 15px;
            }

            .icon {
                margin-left: 1px;
                height: 15px;
                width: 15px;
            }
        }

        .menu-btn-small-sidebar {
            flex-direction: row-reverse;

            .icon {
                transform: rotate(180deg);
            }

            .line {
                width: 7px;
            }
        }
    }

    .settings {
        display: flex;
        flex-direction: column;
        
        .vertical {
            border-left: 1px solid #C0C0C0; 
        }

        hr {
            width: 100%;
            color: #EEEEEE;
        }

        .info {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            margin-left: 20px;
            margin-right: 20px;
            margin-top: 10px;
            margin-bottom: 10px;

            a {
                text-decoration: none;
                color: #C0C0C0;
            }

            label {
                color: #C0C0C0
            }
        }

        .copyright {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 20px;
            margin-right: 20px;
            margin-bottom: 20px;
            overflow: hidden;

            :first-child {
                margin-right: 5px;
            }

            :nth-child(2) {
                margin-right: 5px;
            }

            label {
                color: #C0C0C0;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }
}

@mixin show-small-sidebar {
    .settings {
        .info {
             display: none;
        }

        .copyright {
            justify-content: center;
            align-items: center;
            margin-left: 18px;
            margin: 38px 0;

            :nth-child(2) {
                display: none;
            }
        }
    }

    .menu {
        .menu-icons {
            .menu-logo {
                width: 30px;

                .logo {
                    opacity: 0;
                    visibility: hidden;
                    transition-delay: 0s;
                    transition: visibility 0.3s, opacity 0.3s linear;
                }
            }

            .hamburger-icon {
                visibility: hidden;
                transition: visibility 0s linear;
                transition-delay: 0s;
            }
        }
        .profile-name {
            text-align: center;
        }
    }
}

.small-sidebar {
    width: 80px;
}

.small-sidebar {
    @include show-small-sidebar;
}

@media only screen and (max-width: 1000px) {
    .sidebar {
        @include show-small-sidebar;
    }
}