.messages-widget {
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    height: 100%;

    .messages-widget-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 10px;
        height: 100%;

        .remove-widget-icon {
            position: absolute;
            left: -10px;
            top: -10px;
            cursor: pointer;

            display: none;
            animation: display-none-transition 1s both; 
        }

        .show-remove {
            display: block;
            animation: display-none-transition 1s both;
        }

        .messages-widget-top {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
    
            .dashboard-item-icon {
                height: 20px;
            }
        }
    
    
        .sms-stats {
            display: flex;
            flex-direction: column;
            cursor: pointer;
    
            :first-child {
                font-weight: 500;
                font-size: 38px;
                color: #EA5454;
                cursor: pointer;
            }
    
            :nth-child(2) {
                font-weight: 500;
                font-size: 14px;
                color: #373737;
                cursor: pointer;
            }
        }
    
        .sms-content {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
    
            :nth-child(2) {
                :first-child {
                    color: #FFCC33;
                }
            }
        }
    }
}

.messages-widget-edit * {
    cursor: grab;
}

.messages-widget-edit:active * {
    cursor: grabbing;
}

@keyframes display-none-transition {
    0% {
      opacity: 0;
    }
}