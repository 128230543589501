@import '../colors';

.action-popup {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;

    .action-popup-content {
        display: flex;
        flex-direction: column;
        background-color: white;
        position: absolute;
        top: 40%;
        left: 40%;
        width: 30%;
        padding: 20px;
        border-radius: 5px;

        .action-popup-actions {
            display: flex;
            flex-direction: row;
            align-self: flex-end;
            margin-top: 20px;

            button {
                outline: 0;
                border: none;
                min-width: 80px;
                padding: 3px;
                font-weight: bold;
                border-radius: 25px;
                padding: 5px 10px 5px 10px;
                outline: 0;
                margin-left: 20px;
                color: white;
                background-color: #FECB45;
            }

            .action-popup-no {
                background-color: $accent-color;
            }

            button:hover {
                background-color: grey;
            }
        }
    }
}