@import '../colors';

.user-dropdown-container {
    display: flex;
    flex-direction: row;
    justify-items: center;
    outline: 0;
    cursor: pointer;

    .dropdown {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border-radius: 25px;
        align-self: center;
        background: #F7F7F7;
        min-width: 220px;
        border-radius: 35px;
        padding: 4px;
        outline: 0;
        margin-left: 20px;
        user-select: none;
        border: 1px solid #F7F7F7;
        cursor: pointer;

        .ud-name {
            display: flex;
            flex-direction: row;
            align-items: center;

            .profile-picture {
                width: 32px;
                height: 32px;
                border-radius: 50%;
                align-self: center;
                cursor: pointer;
            }
    
            .profile-picture-initials {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 32px;
                height: 32px;
                border-radius: 50%;
                align-self: center;
                background-color:$accent-color;
                color: white;
                cursor: pointer;
            }
    
            .profile-name {
                color: #3C4858;
                font-weight: 700;
                font-size: 14px;
                font-family: Roboto;
                margin-left: 10px;
                color: #5B5B5B;
                cursor: pointer;
            }
        }

        .logo {
            margin-right: 10px;
            cursor: pointer;
            transition-duration: 0.5s;
            transition-property: transform;
        }
    }

    .dropdown-open {

        .logo {
            transform: rotate(180deg);
         }
    }

    .options {
        position: absolute;
        display: flex;
        flex-direction: column;
        top: 65px;
        background: #FFFFFF;
        width: inherit;
        box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.14);
        border-radius: 10px;
        z-index: 2;
        min-width: 230px;
        cursor: pointer;
        margin-left: 20px;

        label {
            padding-top: 10px;
            padding-bottom: 10px;
            padding-left: 20px;
            padding-right: 10px;
            font-size: 15px;
            color: #3C4858;
            cursor: pointer;
        }

        label:hover {
            &:nth-child(1) {
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
            }
            &:nth-child(4) {
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
            }
            background-color: $accent-color;
            color: white;
        }
    }
}