@import '../colors';

.edit-account-page {
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;

    .edit-account {
        display: flex;
        flex-direction: row;
        margin: 50px 50px 20px 50px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
        background-color: white;

        .my-profile-label {
            color: #999999;
            font-size: 10px;
        }

        .name-label {
            color: #3C4858;
            font-size: 21px;
        }

        .edit-account-label-input {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 10px;

            .input-error {
                border: 0.75px solid $accent-color;
            }

            label {
                color: #3C4858;
                font-weight: 500;
                font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                width: 120px;
            }

            input {
                border: 0.75px solid #DBDBDB;
                box-sizing: border-box;
                border-radius: 21.75px;
                background-color: #F5F5F5;
                padding: 10px 20px 10px 20px;
                margin-left: 10px;
                outline: 0;
                font-size: 15px;
                flex: 1;
            }

            .google-places-autocomplete  {
                flex: 1;
                position: static;
                width: auto;
                margin-right: -10px;
            }

            textarea {
                height: 100px;
                border: 0.75px solid #DBDBDB;
                box-sizing: border-box;
                border-radius: 12px;
                background-color: #F5F5F5;
                padding: 10px 20px 10px 20px;
                margin-left: 10px;
                outline: 0;
                font-size: 15px;
                flex: 1;
            }
        }

        .account-details {
            display: flex;
            flex-direction: column;
            flex: 1.2;

            .account-details-contact {
                display: flex;
                flex-direction: row;
                // border-bottom: 1px solid rgba(0, 0, 0, 0.14);

                .avatar-selector {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 207px;
                    width: 120px;
                    height: 120px;
                    margin-left: 15px;
                    background-color: rgba(232, 85, 88, 0.5);
                    z-index: 1;

                    label {
                        color: white;
                        font-weight: bold;
                        font-size: 12px;
                    }
                }

                .file-select-input {
                    display: none;
                }

                img {
                    background-color: #bbbbbb;
                    position: relative;
                    top: -15px;
                    width: 120px;
                    height: 120px;
                    margin-left: 15px;
                }

                .my-account-initials {
                    background-color: $accent-color;
                    position: relative;
                    top: -15px;
                    width: 120px;
                    height: 120px;
                    margin-left: 15px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    color: white;
                    font-size: 30px;
                }

                .account-details-name {
                    display: flex;
                    flex-direction: column;
                    padding: 30px;
                    flex: 1;
                }
            }
        }

        .account-details-infos {
            display: flex;
            flex-direction: column;
            padding: 10px 30px 50px 40px;    
        }

        .business-details {
            display: flex;
            flex-direction: column;
            flex: 2;
            border-left: 1px solid rgba(0, 0, 0, 0.14);
            padding: 30px;

            .busines-details-info {
                display: flex;
                flex-direction: row;
                margin-top: 20px;
            }

            .description-label {
                margin-top: 20px;
                color: #3C4858;
                font-size: 12px;
            }
        }
    }

    .save-btn {
        min-width: 140px;
        border-radius: 25px;
        color: white;
        align-self: flex-end;
        margin-right: 50px;
        background-color: $accent-color;
        border:none;
        padding: 10px;
        outline: 0;
        cursor: pointer;
    }
}

@media only screen and (max-width: 1200px) {
    .edit-account-page {
        .edit-account {
            flex-direction: column;

            .business-details {
                border-top: 1px solid rgba(0, 0, 0, 0.14);
            }
        }
    }
}

@media only screen and (max-width: 1600px) {
    .edit-account-page {
        .edit-account {
            .business-details {
                .busines-details-info {
                    flex-direction: column;
                    .busines-details-info-item {
                        margin-top: 10px;
                    }
                }
            }
        }   
    }
}