@import './colors';

.invite-user-page {
    display: flex;
    flex-direction: column;
    user-select: none;

    .back-btn {
        margin-top: 20px;
        margin-left: 30px;
        cursor: pointer;
    }

    .invite-user {
        display: flex;
        flex-direction: column;

        background-color: white;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
        border-radius: 5px;
        margin-left: 50px;
        margin-top: 50px;

        .invite-user-top {
            display: flex;
            flex-direction: row;
            align-items: center;

            .invite-user-logo {
                position: relative;
                top: -20px;
                display: flex;
                background-color: $accent-color;
                height: 60px;
                width: 60px;
                border-radius: 0 0 0 30px;
                justify-content: center;
                align-items: center; 
                margin-left: 10px;
                margin-bottom: -10px;

                .invite-user-logo-svg {
                    width: 30px;
                    height: 30px;

                    path {
                    fill: white;
                    }
                }
            }

            label {
                margin-left: 20px;
            }
        }

        hr {
            border: 0;
            clear:both;
            display:block;
            width: 98%;               
            background-color: #eeeeee;
            height: 1px;
            margin-top: 0px;
        }

        .invite-user-inputs {
            display: flex;
            flex-direction: column;
            padding-left: 30px;
            padding-right: 14vw;
            margin-top: 20px;
            margin-bottom: 40px;
            
            label {
                color: #3C4858;
                font-size: 15px;
                width: 7vw;
            }
            
            .invite-user-input {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 10px;

                input {
                    border: 0.75px solid #DBDBDB;
                    box-sizing: border-box;
                    border-radius: 21.75px;
                    background-color: #F5F5F5;
                    padding: 10px 20px 10px 20px;
                    width: 30vw;
                    outline: 0;
                    font-size: 15px;
                }

                .input-error {
                    border: 0.75px solid $accent-color;
                }
            }

            .error-message {
                color: $accent-color;
                width: 40vw;
                margin-top: 10px;
            }

            .invite-user-select {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 10px;
                
                .dropdown-container {
                    .dropdown {
                        min-width: 30vw;
                        padding: 10px 20px 10px 20px;
                        border: 0.75px solid #DBDBDB;
                        box-sizing: border-box;
                        border-radius: 21.75px;
                        background-color: #F5F5F5;

                        label {
                            width: inherit;
                        }
                    }

                    .options {
                        top: 460px;

                        label {
                            width: inherit;
                        }
                    }

                    .dropdown-open {

                        .logo {
                            margin-top: 2px;
                         }
                    }
                }
            }

            .roles {
                .dropdown-container {
                    .options {
                        top: 510px;
                    }
                }
            }
        }

        .invite-user-actions {
            display: flex;
            flex-direction: row;
            padding-top: 20px;
            padding-bottom: 50px;
            margin-left: 12vw;

            button {
                min-width: 120px;
                border-radius: 25px;
                color: white;
                align-self: center;
                background-color: $accent-color;
                border:none;
                padding: 10px;
                outline: 0;
                margin-right: 20px;
                font-size: 14px;
                cursor: pointer;
            }

            .invite-user-cancel-btn {
                background-color: #D8D8D8; 
                border:none;
            }
        }
    }
}