.success-popup {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
    user-select: none;
    outline: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .success-popup-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.25);
        overflow: hidden;
        border-radius: 16px;
        outline: 0;
        width: 300px;
        padding: 30px;

        label {
            font-size: 21px;
            font-weight: 400;
            color: #4D5059;
            margin-top: 10px;
        }
        
        button {
            background: #FFCC33;
            border-radius: 57px;
            border: none;
            outline: 0;
            height: 40px;
            color: white;
            font-weight: 500;
            font-size: 15px;
            width: 150px;
            cursor: pointer;
            margin-top: 50px;
            margin-bottom: 10px;
        }
    
    }
}