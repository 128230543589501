@import '../colors';

.back-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 20px;
    width: 100px;
    user-select: none;
    cursor: pointer;

    .back-icon {
        width: 15px;
        height: 15px;
    }

    label {
        margin-left: 5px;
        cursor: pointer;
    }
}