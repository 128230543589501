$unaro-red: #ea5454;
$unaro-yellow: #ffcc33;
$unaro-orange: #ff9933;

$grey-1: #5B5B5B;
$grey-2: #C4C4C4;
$grey-3: #F2F2F2;

$accent-color: #E85558;
$login-background-color: #FFCC33;
