.create-tag-popup {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
    user-select: none;
    outline: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .create-tag-popup-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.25);
        overflow: hidden;
        border-radius: 16px;
        outline: 0;
        width: 500px;

        .create-tag-top {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
            background-color: #FFCC33;
            border-top-right-radius: 16px;
            border-top-left-radius: 16px;
            padding: 15px;
            position: relative;

            label {
                color: white;
                font-weight: 500;
                font-size: 23px;
            }

            img {
                width: 14px;
                height: 14px;
                position: absolute;
                right: 30px;
                cursor: pointer;
            }
        }
        .create-tag-content {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 80%;

            .tag-name {
                margin-top: 20px;
                margin-bottom: 15px;
            }
    
            input {
                width: 100%;
                border-color:  #C4C4C4;
            }

            .error-label {
                border-color:  #EA5459;
            }

            .tag-colors {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 20px;

                label {
                    font-weight: 300;
                    font-size: 14px;
                    color: #000000;
                }

                .tag-color-container {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    margin-left: 11px;
                    padding: 2px;
                    border: 1px solid white;
                    border-radius: 25px;
                    cursor: pointer;

                    .tag-color {
                        width: 18px;
                        height: 18px;
                        border-radius: 25px;
                        border: 1px solid #C4C4C4;
                    }

                }

                .tag-color-container-selected {
                    border-color: #C4C4C4;
                }
            }

            .create-tag-actions {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 100%;
                margin-top: 30px;
                margin-bottom: 40px;

                button {
                    width: 100px;
                }

                :nth-child(2) {
                    background-color: #FFCC33;
                    margin-left: 20px;
                }
            }
        }
        
    }
}