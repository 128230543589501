@import './colors';

.forgot-password-page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-image: url('../images/background.svg');
  background-color: #eeeeee;
  align-items: center;
  user-select: none;

  .forgot-password {
    display: flex;
    flex-direction: column;

    background-color: white;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
    border-radius: 5px;
    margin-top: 100px;

    .forgot-password-top {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-top: 20px;

      label {
        margin-left: 20px;
        margin-bottom: 20px;
      }
    }

    hr {
      border: 0;
      clear: both;
      display: block;
      width: 98%;
      background-color: #eeeeee;
      height: 1px;
      margin-top: 0px;
    }

    .forgot-password-input {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-top: 10px;

      padding-left: 30px;
      padding-right: 30px;
      //margin-top: 20px;
      margin-bottom: 30px;

      label {
        color: #3C4858;
        font-size: 15px;
      }

      input {
        padding: 10px 10px 10px 10px;
        border: 0.75px solid #DBDBDB;
        background-color: #F5F5F5;
        border-radius: 21.75px;
        box-sizing: border-box;
        align-items: center;
        margin-left: 20px;
        width: 20vw;
        outline: 0;
      }

      .input-error {
        border: 0.75px solid $accent-color;
      }

      .error-message {
        color: $accent-color;
        width: 40vw;
        margin-top: 10px;
      }
    }

    .forgot-password-actions {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-top: 20px;
      padding-bottom: 50px;

      button {
        min-width: 120px;
        border-radius: 25px;
        color: white;
        align-self: center;
        background-color: $accent-color;
        border: none;
        padding: 10px;
        outline: 0;
      }

      .back-to-login-btn {
        background-color: #afafaf;
        margin-left: 20px;
      }

      .disabled {
        opacity: 0.5 !important;
        cursor: not-allowed;
      }
    }

    .forgot-password-response {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-bottom: 20px;
    }
  }
}