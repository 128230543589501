@import "../colors.scss";

.page-selector {
    display: flex;
    justify-content: space-between;
    width: 250px;

    &__page-toggle {
        background-color: white;
        border-radius: 30px;
        display: flex;
        padding: 8px;
        height: 20px;
        border: 0.5px solid $grey-2;
        margin: auto 10px auto 10px;
    }

    &__page-number {
        margin: 0;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        margin-left: 5px;
        margin-right: 5px;
        text-align: center;
        &:hover {
            background-color: $unaro-red;
            color: white;
        }
    }
}