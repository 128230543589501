@import "../colors";

.color-picker-container {
  display: flex;
  flex-direction: column;
  outline: 0;
  user-select: none;


  .color-picker {
    display: flex;
    flex-direction: row;
    align-items: center;

    .color-picker-label {
      color: #3c4858;
      font-weight: bold;
      width: 180px;
    }

    .switch-color {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px;
      height: 15px;
      border: 0.75px solid #dbdbdb;
      border-radius: 25px;
      background-color: #f5f5f5;
      margin-left: 10px;
      cursor: pointer;
      justify-content: space-between;

      .color-label {
        width: 60px;
        cursor: pointer;
      }
    }
  }

  .sketch-picker {
    position: absolute;
    // margin-top: 40px;
    // margin-left: 40px;
    top: -25px;
    left: 50%;
    z-index:9999999;

    &--position-top {
      bottom: 40px;
      top: auto;
      left: auto;
    }
  }
}

.picker-container {
  position: relative;
}
