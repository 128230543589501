.add-contact-popup {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
    user-select: none;
    outline: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .add-contact-popup-content {
        display: flex;
        flex-direction: column;
        background-color: white;
        box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.25);
        overflow: hidden;
        border-radius: 16px;
        outline: 0;
        width: 600px;
        padding: 20px;
        max-height: 750px;
        overflow-y: scroll;
        
        .add-contact-top {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            position: relative;

            .page-selector {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin-left: 10px;
                background-color: white;
                border: 1px solid #EA5454;
                border-radius: 25px;
                padding: 8px;
                width: 140px;
                margin-top: 20px;
                cursor: pointer;

                label {
                    cursor: pointer;
                }
            }

            .page-selector-selected {
                color: white;
                background-color: #EA5454;
            }

            .close-icon {
                width: 15px;
                height: 15px;
                position: absolute;
                right: 10px;
                cursor: pointer;
        
                path {
                    fill: #aaaaaa;
                }

                :hover {
                    path {
                        fill: #EA5454;
                    }
                }
            }
        }

        .contact-dropdown-container {
            flex: 1;

            .dropdown {
                height: 30px;
                border: 0.75px solid #C4C4C4;
                box-sizing: border-box;
                border-radius: 21.75px;
                background-color: white;
                padding: 5px 20px;

                label {
                    width: inherit;
                }
            }

            .options {

                label {
                    width: inherit;
                }
            }
        }

        input {
            flex: 1;
            margin: unset;
            border-color: #C4C4C4;
            margin-left: 10px;
            padding: 5px 20px;
        }

        .title {
            font-size: 23px;
            color: #000000;
            margin-top: 20px;
            margin-bottom: 30px;
            align-self: center;
        }

        .add-single-contact {
            display: flex;
            flex-direction: column;
    
            .input-label {
                font-size: 15px;
                color: #000000;
                margin-left: 30px;
            }
    
            .inputs {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 10px 30px;
    
                input {
                    flex: 2;
                }
            }
    
            .names {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 10px 30px;
    
                :first-child {
                    margin-right: 10px;
                }
    
                .name-input {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
    
                    label {
                        margin: unset;
                        margin-bottom: 10px;
                    }
    
                    input {
                        margin: unset;
                    }
                }
            }
    
            .tags-dd {
                width: 60%;  
            }
    
            .customs {
                display: grid;
                grid-template-columns: 1fr 1fr;
                margin: 10px 30px 10px 20px;
                row-gap: 20px;
    
                input {
                    padding: 10px 10px;
                }
            }

            .error-message {
                color: red;
                margin-left: 30px;
                margin-top: 10px;
                font-size: 15px;
            }

            .input-error {
                border-color: red;

                .dropdown {
                    border-color: red;
                }
            }
    
            button {
                width: 200px;
                background-color: #FFCC33;
                padding: 10px;
                margin-top: 40px;
            }
        
        }
    }
        
}