.poster-page {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    margin-bottom: 20px;

    .pp-edit {
        display: flex;
        flex-direction: column;
        flex: 1;
        background-color: white;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
        margin-left: 30px;
        border-radius: 20px;
        padding: 0px 20px 20px 20px;
        height: fit-content;

        .pp-edit-top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .pp-edit-title {
                display: flex;
                flex-direction: row;
                align-items: center;

                .pp-title-text {
                    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
                    font-weight: 300;
                    margin-left: 20px;
                }

                .pp-edit-logo {
                    position: relative;
                    top: -20px;
                    display: flex;
                    background-color: #EA5454;
                    height: 60px;
                    width: 60px;
                    border-radius: 0 0 0 30px;
                    justify-content: center;
                    align-items: center; 
                    
                    .pp-edit-logo-svg {
                        width: 30px;
                        height: 30px;

                        path {
                          fill: white;
                        }
                    }
                }
            }
        }

        .pp-top-line {
            border: 0;
            clear:both;
            display:block;
            width: 100%;               
            background-color: #eeeeee;
            height: 1px;
            margin-top: 0px;
        }
    }
}