
.devices-page {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: 15px;
    padding: 10px;

    .devices-page-test {
        color: #3C4858;
        font-size: 20px;
    }

    .back-btn {
        margin-left: 0px;
    }
}