@import './colors';

.page-navigator {
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;
    
    button {
        outline: 0;
        border: none;
        padding: 10px;
        font-weight: bold;
        background-color: transparent;
        color: grey;
        margin-left: 10px;
        margin-right: 10px;
        cursor: pointer;
    }
}