
.billing-page {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: fit-content;

    .back-btn {
        margin-top: 20px;
    }

    .billing-top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .title {
            font-weight: 300;
            font-size: 19.5px;
            line-height: 23px;
            color: #3C4858;
            margin-left: 40px;
        }

        .billing-actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 40px;

            button {
                margin-left: 30px;
                background: #FF9933;
                border-radius: 21.75px;
                color: white;
                outline: 0;
                border: none;
                padding: 10px 15px;
                cursor: pointer;
            }
        }
    }

   

    .billing-content {
        display: flex;
        flex-direction: row;
        flex: 1;
        margin: 20px;
        height: calc(100vh - 220px);

        @media only screen and (max-width: 1130px) {
            flex-direction: column;
        }

        .left {
            display: flex;
            flex-direction: column;
            background-color: white;
            flex: 1;
            margin: 20px;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);

            label {
                font-size: 16.5px;
                line-height: 19px;
                color: #000000;
                padding: 20px;
                cursor: pointer;
            }

            label:first-child {
                margin-top: 10px;
            }

            .menu-item-selected {
                background-color: #F2F2F2;
            }
        }
    
        .right {
            background-color: white;
            flex: 4;
            margin: 20px;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
        }
    }
}