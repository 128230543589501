@import "../colors";

.landing-page-header {
  display: flex;
  justify-content: space-between;
  align-content: center;
  margin-left:5%;
  margin-right:5%;
  margin-top:20px;

  .landing-page-search-inputs {
    display: flex;
    justify-content: space-between;
    height: 40px;
  }
  .landing-page-filter-dropdown {
    margin-left: 20px;
    margin-right: 20px;
  }
}

.landing-pages {
  display: flex;
  flex-direction: column;
  margin: 20px;
  border-radius: 5px;

  .add-card-content {
    display: flex;
    cursor: pointer;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    padding: 20px;

    label {
      font-weight: bold;
    }
  }

  .landing-page-item-status {
    display: flex;
    justify-content: flex-start;
    width: 90%;
  }

  .landing-page-item-status-items {
    display: flex;
    width: 70%;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
  }

  .landing-page-add-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    border-radius: 10px;
    background-color: white;
    // margin-left: 15px;
    // margin-right: 15px;
    margin-bottom: 30px;
    min-height: 255px;
    // flex-basis: 31%;
    width:30%;
    // min-width: 510px;
    margin-right:20px;
  }

  .landing-pages-top {
    display: flex;
    background-color: white;
    flex-direction: row;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    border-radius: 5px 5px 0px 0px;

    .landing-pages-logo {
      position: relative;
      top: -20px;
      display: flex;
      background-color: $accent-color;
      height: 60px;
      width: 60px;
      border-radius: 0 0 0 30px;
      justify-content: center;
      align-items: center;

      .landing-page-edit-logo-svg {
        width: 30px;
        height: 30px;

        path {
          fill: white;
        }
      }
    }

    .landing-pages-title {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
        Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
      font-weight: 300;
      margin-left: 20px;
    }
  }

  .landing-pages-flex-container {
    margin-top: 40px;
    margin-left:2%;
    margin-right:2%;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    justify-content:flex-start;
  }

  .landing-pages-grid {
    display: grid;
    margin-right: 20px;
    margin-left: -30px;
    margin-top: 40px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .add-landing-page-btn {
    min-width: 120px;
    border-radius: 25px;
    color: white;
    align-self: center;
    background-color: $accent-color;
    border: none;
    padding: 10px 20px 10px 20px;
    outline: 0;
    margin: 20px;
    font-size: 12px;
    cursor: pointer;
  }
}

@media only screen and (max-width: 1400px) {
  .landing-pages {
    .landing-pages-grid {
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media only screen and (max-width: 1100px) {
  .landing-pages {
    .landing-pages-grid {
      grid-template-columns: 1fr;
    }
  }
}
