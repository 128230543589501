@import '../../colors';


.show-qr-popup {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
    user-select: none;
    outline: 0;

    .show-qr-popup-content {
        display: flex;
        flex-direction: row;
        background-color: white;
        position: absolute;
        top: 15%;
        left: 20%;
        width: 60%;
        min-width: 400px;
        padding: 80px;
        overflow: hidden;
        border-radius: 5px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        outline: 0;
        box-shadow: 0px 1px 22px rgba(0, 0, 0, 0.2);
        height: 40%;
        
        .left {
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            
            label {
                font-size: 20.5px;
                line-height: 18px;
                text-align: center;                
                color: #000000;
            }

            button {
                min-width: 90px;
                border-radius: 25px;
                color: white;
                align-self: center;
                background-color: $accent-color;
                border:none;
                padding: 10px 40px;
                outline: 0;
                margin: 20px;
            }
        }

        .right {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}