.export-popup {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
    user-select: none;
    outline: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .export-popup-content {
        display: flex;
        flex-direction: column;
        background-color: white;
        box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.25);
        overflow: hidden;
        border-radius: 16px;
        outline: 0;
        width: 330px;
        padding: 30px;

        .export-top {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border-bottom: #CCCED6 solid 1px;
            padding-bottom: 20px;

            label {
                color: #4D5059;
                font-weight: 400;
                font-size: 21px;
            }

            .close-icon {
                width: 20px;
                height: 20px;
                cursor: pointer;
                align-self: flex-end;
    
                path {
                    fill: #C4C4C4;
                }
            }
    
            .close-icon:hover {
                path {
                    fill: #EA5459;
                }
            }
        }

        .export-option {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 20px;
            cursor: pointer;

            .selector {
                display: flex;
                align-items: center;
                justify-content: center;
                min-width: 16px;
                min-height: 16px;
                width: 15px;
                height: 15px;
                border: 1px solid #CACACA;
                border-radius: 50%;
                cursor: pointer;

                .selected {
                    display: flex;
                    min-width: 10px;
                    min-height: 10px;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    cursor: pointer;
                    background-color: #FFCC33;
                }
            }

            label {
                font-size: 16px;
                font-weight: 300;
                color: #4D5059;
                margin-left: 20px;
                cursor: pointer;
            }
        }

        .industries-dd {
            margin-top: 20px;
        }
        

        .export-actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            margin-top: 40px;
            margin-bottom: 10px;

            :first-child {
                background: #EA5454;
            }

            button {
                background: #FFCC33;
                border-radius: 57px;
                border: none;
                outline: 0;
                height: 40px;
                color: white;
                font-weight: 500;
                font-size: 15px;
                width: 150px;
                cursor: pointer;
            }
        }     
    }
}