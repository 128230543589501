.compose-message {
    display: flex;
    flex-direction: row;
    background-color: white;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    margin: 20px 30px;
    padding: 40px;
    user-select: none;

    .error-message {
        color: #EA5454;
        margin-top: 10px;
    }

    .left {
        flex: 1;
        display: flex;
        flex-direction: column;

        .title {
            font-weight: 300;
            font-size: 25px;
            line-height: 23px;
            color: #3C4858;
            margin-bottom: 10px;
            margin-top: 10px;
        }

        .phone-number {
            display: flex;
            flex-direction: row;
            margin-top: 30px;

            .pn-name {
                width: 120px;
                font-weight: 500;
                font-size: 15px;
                color: #3C4858;
                margin-top: 10px;
            }

            .phone-numbers {
                display: flex;
                flex-direction: column;

                input {
                    border: 0.75px solid #DBDBDB;
                    box-sizing: border-box;
                    border-radius: 21.75px;
                    background-color: #F5F5F5;
                    padding: 10px 20px 10px 20px;
                    margin-bottom: 10px;
                    width: 200px;
                    outline: 0;
                    font-size: 15px;
                }
            }

            .dropdown-container {
                cursor: pointer;

                .dropdown {
                    min-width: 150px;
                    padding: 10px 20px;
                    border: 0.75px solid #DBDBDB;
                    box-sizing: border-box;
                    border-radius: 21.75px;
                    background-color: #F5F5F5;
                    margin-right: 20px;
                    cursor: pointer;
                    align-items: center;
    
                    label {
                        width: inherit;
                        cursor: pointer;
                    }
                }
    
                .options {
                    max-height: 150px;
                    overflow-y: scroll;
                    cursor: pointer;
    
                    label {
                        width: inherit;
                        cursor: pointer;
                    }
                }
    
                .dropdown-open {
    
                    .logo {
                        margin-top: 2px;
                     }
                }
            }

            .time {
                .options {
                }
            }

            .add-phone-number {
                width: 25px;
                height: 25px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background-color: #EA5454;
                border-radius: 25px;
                margin: 5px 30px;
                cursor: pointer;
            }

            button {
                min-width: 120px;
                border-radius: 25px;
                color: white;
                background-color: #FFCC33;
                border:none;
                padding: 10px;
                outline: 0;
                font-size: 14px;
                height: 40px;
                cursor: pointer;
                margin-left: 20px;
            }
        }

        .time-selector {
            margin-top: 0px;
        }

        .date-selector {
            margin-bottom: 20px;
        }

        hr {
            width: 100%;
            border: 1px solid #EAEAEA;
            margin-top: 30px;
            margin-bottom: 20px;
        }

        .schedule-types {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 10px 0;
            cursor: pointer;

            .schedule-type {
                width: 85px;
                margin-right: 10px;
                background: #DBDBDB;
                border-radius: 21.75px;
                padding: 5px 10px;
                font-weight: 300;
                font-size: 16px;
                text-align: center;
                color: #FFFFFF;
                cursor: pointer;
            }

            .schedule-type-selected {
                background: #FFCC33;
            }
        }

        .radio-selector {
            width: 18px;
            height: 18px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            border: 0.75px solid #DBDBDB;
            border-radius: 50%;
            margin-right: 5px;
            cursor: pointer;

            .selected {
                display: flex;
                width: 10px;
                height: 10px;
                background: #FFCC33;
                border-radius: 50%;
                cursor: pointer;
            }
        }

        .schedule-selector {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 20px 0;

            label {
                font-weight: 300;
                font-size: 14px;
                color: #3C4858;
                cursor: pointer;
            }

            input {
                width: 20px;
                border: none;
                border-bottom: 1px solid #EA5454;
                color: #EA5454;
                outline: 0;
                margin: 0 2px;
            }
        }

        .weekly-schedule {
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            .weekly-schedule-title {
                font-weight: 500;
                font-size: 15px;
                color: #3C4858;
            }

            .week-days {
                display: grid;
                margin: 30px 0;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
                row-gap: 20px;
                column-gap: 10px;

                .week-day {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    label {
                        font-weight: 300;
                        font-size: 14px;
                        color: #3C4858;
                        cursor: pointer;
                    }
                }
            }
        }

        .actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 20px;
    
            button {
                min-width: 120px;
                border-radius: 25px;
                color: white;
                align-self: center;
                background-color: #EA5459;
                border:none;
                padding: 10px;
                outline: 0;
                margin-right: 40px;
                font-size: 14px;
                cursor: pointer;
            }
    
            :nth-child(2) {
                background-color: #FFCC33;
            }
        }
    }

    .right {
        flex: 1;
        display: flex;
        flex-direction: column;

        .message-preview {
            flex: 1;
            width: 100%;
            height: 100%;
            align-self: baseline;
            background-image: url('../../../../../assets/images/message-preview.svg');
            background-repeat: no-repeat;
            display: flex;
            flex-direction: column;

            .message-container {
                display: flex;
                flex-direction: column;
                margin-left: 76px;
                margin-top: 100px;
                width: 120px;
                background-color: #E6E5EB;
                padding: 10px;
                border-radius: 18px;
                position: relative;

               ::before {
                    content: "";
                    position: absolute;
                    z-index: 0;
                    bottom: 0;
                    left: -7px;
                    height: 20px;
                    width: 15px;
                    background: #E6E5EB;
                    border-bottom-right-radius: 15px;
                }

                ::after {
                    content: "";
                    position: absolute;
                    z-index: 1;
                    bottom: 0;
                    left: -10px;
                    width: 10px;
                    height: 20px;
                    background: white;
                    border-bottom-right-radius: 10px;
                }

                label {
                    font-size: 8px;
                    line-height: 10px;
                    color: rgba(60, 60, 67, 0.6);
                }
            }
        }
    }
}