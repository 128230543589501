.suspend-page {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    margin: 40px;

    :first-child {
        font-weight: 500;
        font-size: 23px;
    }

    :nth-child(2) {
        font-weight: 300;
        font-size: 15px;
        margin-top: 40px;
    }

    :nth-child(3) {
        font-weight: 500;
        font-size: 15px;
        margin-top: 10px;
        color: #EA5459;
    }
}