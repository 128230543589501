@import '../colors';

.text-switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    user-select: none;

    .text-switch-label {
        color: #3C4858;
        font-weight: 500;
        width: 180px;
        user-select: none;
    }

    .text-switch-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 10px;
        height: 15px;
        border: 0.75px solid #DBDBDB;
        border-radius: 25px;
        background-color: #F5F5F5;
        margin-left: 10px;
        cursor: pointer;

        .text-switch-value {
            width: 55px;
            cursor: pointer;
        }

        .text-switch-buttons {
            display: flex;
            flex-direction: column;
            cursor: pointer;
            
            margin-left: 30px;
            margin-right: 10px;

            .text-switch-button {
                width: 20px;
                height: 20px;
                margin: -8px;
                cursor: pointer;
            }
            
        }
    }

}