.remove-popup {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
    user-select: none;
    outline: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .remove-popup-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.25);
        overflow: hidden;
        border-radius: 16px;
        outline: 0;
        width: 500px;

        .remove-top {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
            background-color: #FFCC33;
            border-top-right-radius: 16px;
            border-top-left-radius: 16px;
            padding: 15px;
            position: relative;

            label {
                color: white;
                font-weight: 500;
                font-size: 23px;
            }

            img {
                width: 14px;
                height: 14px;
                position: absolute;
                right: 30px;
                cursor: pointer;
            }
        }
        .remove-content {
            display: flex;
            flex-direction: column;
            width: 80%;

            .remove-text {
                align-self: center;
                margin: 40px 0;

                label {
                    align-self: center;
                    font-weight: bold;
                    font-size: 16px;
                }

                span {
                    color: #EA5459;
                }
            }

           

            .remove-actions {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 100%;
                margin-bottom: 40px;

                button {
                    width: 100px;
                    border-radius: 21.75px;
                    background-color: #EA5459;
                    color: white;
                    padding: 10px 0;
                    border: none;
                    outline: 0;
                    cursor: pointer;
                }

                :nth-child(2) {
                    background-color: #FFCC33;
                    margin-left: 20px;
                }
            }
        }
        
    }
}