@import "../colors";

.edit-login {
  hr {
    margin-top: 30px;
    margin-bottom: 20px;
    border: 0;
    clear: both;
    display: block;
    width: 90%;
    background-color: $grey-2;
    height: 1px;
  }
  display: flex;
  flex-direction: column;
  &__title {
    font-weight: bold;
    font-size: 16px;
    margin-left: 30px;
    margin-top: 50px;
    margin-bottom: 30px;
  }

  &__media-switch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    height: 80px;
  }

  &__social-switches {
    display: flex;
    width: 500px;
    justify-content: space-evenly;
  }

  &__input {
    margin-left: 20px;
    margin-right: 10px;
    height: 15px;
    padding: 10px;
    border: 0.75px solid #dbdbdb;
    border-radius: 25px;
    background-color: #f5f5f5;
    color: #3c4858;
    outline: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 70%;
  }

  &__dropdown {
    margin-left: 24px;
  }

  &__dropdown-label {
    font-weight: bold;
    font-size: 16px;
  }

  &__dropdown-title {
    margin-top: 50px;
    margin-bottom: 30px;
    display: flex;
    align-content: center;
  }

  &__input-container {
    display: flex;
    flex-basis: 45%;
    label {
      margin: auto;
    }
  }

  &__text-fields {
    display: flex;
    // justify-content: space-evenly;
    padding-left:30px;
  }
}
