.edit-message {
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    margin: 20px 30px;
    padding: 40px;
    user-select: none;

    button {
        min-width: 120px;
        border-radius: 25px;
        color: white;
        align-self: center;
        background-color: #EA5459;
        border:none;
        padding: 10px;
        outline: 0;
        font-size: 14px;
        cursor: pointer;
    }

    .edit-message-top {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        max-width: 1000px;

        .title {
            font-weight: 300;
            font-size: 25px;
            line-height: 23px;
            color: #3C4858;
            margin-bottom: 30px;
            margin-top: 10px;
        }

        button {
            width: 150px;
            background-color: #FFCC33;
        }
    }

    .subtitle {
        margin-bottom: 10px;
    }

    textarea {
        border: 0.75px solid #DBDBDB;
        height: 100px;
        resize: none;
        outline: none;
        padding: 10px;
        font-size: 15px;
        max-width: 1000px;
    }

    .characters {
        font-size: 12px;
        margin-top: 10px;
        margin-bottom: 40px;
    }

    .personalisations {
        display: flex;
        flex-direction: row;
        align-items: center;

        .dropdown-container {
            cursor: pointer;

            .dropdown {
                width: 300px;
                padding: 12px 20px 12px 20px;
                border: 0.75px solid #DBDBDB;
                box-sizing: border-box;
                border-radius: 21.75px;
                background-color: #F5F5F5;
                cursor: pointer;

                @media only screen and (max-width: 780px) {
                    width: 250px;
                }

                label {
                    width: inherit;
                    cursor: pointer;
                }
            }

            .options {

                label {
                    width: inherit;
                    cursor: pointer;
                }
            }

            .dropdown-open {

                .logo {
                    margin-top: 2px;
                 }
            }
        }

        button {
            margin-left: 30px;
            background-color: #FFCC33;
        }

        .info-container {
            display: flex;
            flex-direction: row;
    
            .info {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 20px;
                height: 20px;
                margin-left: 30px;
                border: 2px solid #979797;
                border-radius: 25px;
                outline: 0;
                z-index: 3;
                
                label {
                    color: #979797;
                }
            }
        }
    }

    .error-label {
        color: #EA5454;
        margin-top: 10px;
    }

    .edit-message-actions {
        margin-top: 30px;

        :nth-child(2) {
            margin-left: 30px;
            background-color: #FFCC33;
        }
    }
}