@import '../colors';

.add-integration-popup {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
    user-select: none;

    .add-integration-popup-content {
        display: flex;
        flex-direction: column;
        background-color: white;
        position: absolute;
        top: 25%;
        padding: 20px 0 20px 0;
        box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.4);
        border-radius: 6px;

        .add-integration-popup-content-top {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            padding-left: 20px;
            padding-right: 20px;

            .add-integration-popup-title {
                color: #3C4858;
                font-size: 20px;
                width: auto;
            }

            .close-icon {
                width: 15px;
                height: 15px;
                align-self: flex-end;
        
                path {
                    fill: #aaaaaa;
                }
            }
    
            .close-icon:hover {
                path {
                    fill: $accent-color;
                }
            }
        }

        .add-integration-popup-content-slider {
            display: flex;
            flex-direction: row;

            .slider-button {
                position: absolute;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                top: 63px;
                left: 0;
                width: 2vw;
                height: 361px;
                border: none;
                outline: none;
                background-color: #000000;
                opacity: 0;
                cursor: pointer;
            }

            .slider-button-right {
                left: 68vw;
            }

            .slider-button:hover {
                opacity: 0.2;
            }

            .add-integration-popup-venues {
                display: flex;
                flex-direction: row;
                width: 70vw;
                overflow-x: scroll;
                margin-top: 10px;
            }
    
            .add-integration-popup-venues-small {
                width: 25vw;
                justify-content: center;
            }
    
            .add-integration-popup-venues-medium {
                width: 50vw;
                justify-content: center;
            }
        }
    }
}

