.payment-component {
    display: flex;
    flex-direction: column;
    align-items: center;

    .payment-title {
        font-size: 16px;
        font-family: Roboto;
        margin-top: 10px;
        border-bottom: 1px solid #C4C4C4;
        padding: 10px;
        font-weight: 700;

        span {
            color: #EA5454;
        }
    }
}