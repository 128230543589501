.import-contacts {
    display: flex;
    flex-direction: column;

    .uploading-loader {
        min-height: 300px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .drag-and-drop {
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 2px dashed #EA5454;
        border-radius: 11px;
        padding-top: 40px;
        padding-bottom: 20px;
        margin: 20px;
        background-color: rgba(196, 196, 196, 0.1);
        height: 250px;

        .download-img {
            width: 80px;
            margin-bottom: 40px;
        }

        .dd-title {
            font-size: 25px;
            margin-bottom: 10px;
        }

        .dd-text {
            font-size: 15px;
        }

        button {
            width: fit-content;
            margin: 30px 0px;
            background-color: #FFCC33;
            padding: 10px 40px;
        }

        .dd-file-types {
            font-size: 12px;
        }

        .selected-file {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            label {
                margin-top: 20px;
                font-weight: 300;
            }
        }
    }

    .import-selector-name {
        font-size: 18px;
        color: #000000;
        margin-bottom: 10px;
    }

    .radio-selector {
        width: 18px;
        height: 18px;
        border: 1px solid #C4C4C4;
        border-radius: 50%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .radio-selector-selected {
            width: 10px;
            height: 10px;
            border-radius: 50%;
            background-color: #FFCC33;
        }
    }

    .bottom {
        display: flex;
        flex-direction: row;
        padding: 30px 50px;

        .import-tag-selector {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 5px 0px;
            cursor: pointer;

            input {
                margin-left: 15px;
            }

            .selector-name {
                font-weight: 300;
                font-size: 15px;
                color: #000000;
                margin-left: 15px;
                cursor: pointer;
            }

            .tags-dd {
                margin-left: 15px;

                .options {
                    height: 80px;
                }
            }
        }

        .import-tags {
            display: flex;
            flex-direction: column;
            flex: 1.5;
            padding-right: 30px;
        }

        .choose-actions {
            display: flex;
            flex-direction: column;
            flex: 1;
            padding-left: 30px;
            border-left: 1px solid #C4C4C4;
        }
    }

    .columns-container {
        display: flex;
        flex-direction: column;
        padding: 10px 50px;
        margin-bottom: 10px;
        

        .import-column {
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;
            margin-top: 10px;

            .column-name {
                font-weight: 300;
                font-size: 15px;
                color: #000000;
                margin-left: 15px;
                cursor: pointer;
            }

            input {
                margin-left: 60px;
                max-width: 200px;
                font-weight: 300;
            }

            .remove-icon {
                padding: 10px;
            }

            .columns-dd {
                margin-left: 60px;
                max-width: 200px;
                font-weight: 300;

                .options {
                    height: 100px;
                }
            }
        }

        .add-column-btn {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 2px;
            cursor: pointer;
            margin-top: 20px;
            margin-bottom: 10px;

            label {
                font-weight: 300;
                font-size: 15px;
                color: #EA5454;
                margin-left: 20px;
                cursor: pointer;
            }
        }
}

    .import-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: flex-end;

        button {
            width: 120px;
        }

        :nth-child(2) {
            background-color: #FFCC33;
            margin-left: 20px;
        }
    }

    .success-label {
        font-weight: 300;
        font-size: 28px;
        text-align: center;
        margin: 20px 0;
    }


    .done-btn {
        margin: 20px 0; 
        background-color: #FFCC33;
    }

    .failed-btn {
        margin: 20px 0; 
    }

    .error-message {
        color: #EA5454;
        margin-left: 40px;
        margin-bottom: 10px;
        margin-top: -10px;
    }

    .failed-message {
        align-self: center;
        color: #EA5454;
    }
}