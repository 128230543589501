.assign-tag-popup {
    position: fixed;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
    user-select: none;
    outline: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .assign-tag-popup-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;
        box-shadow: 0px 4px 22px rgba(0, 0, 0, 0.25);
        overflow: hidden;
        border-radius: 16px;
        outline: 0;
        width: 500px;

        .assign-tag-top {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            width: 100%;
            background-color: #FFCC33;
            border-top-right-radius: 16px;
            border-top-left-radius: 16px;
            padding: 15px;
            position: relative;

            label {
                color: white;
                font-weight: 500;
                font-size: 23px;
            }

            img {
                width: 14px;
                height: 14px;
                position: absolute;
                right: 30px;
                cursor: pointer;
            }
        }
        .assign-tag-content {
            display: flex;
            flex-direction: column;
            width: 80%;
            
            .tag-colors {
                display: flex;
                flex-direction: column;
                margin: 40px 0;

                label {
                    font-weight: 300;
                    font-size: 14px;
                    color: #000000;
                    margin-right: 7px;
                }

                .tags-line {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    flex: 1;
                    margin-left: 20px;

                    .tag-color-container {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        margin: 5px;
                        padding: 2px;
                        border: 1px solid white;
                        border-radius: 25px;
                        cursor: pointer;
                        position: relative;

                        .tag-color {
                            width: 18px;
                            height: 18px;
                            border-radius: 25px;
                            border: 1px solid #C4C4C4;
                        }

                        .tag-name {
                            position: absolute;
                            background-color: white;
                            left: 13px;
                            top: -15px;
                            font-weight: 300;
                            font-size: 12px;
                            color: #000000;
                            border: 1px solid #CECECE;
                            box-sizing: border-box;
                            border-radius: 4px;
                            padding: 5px;
                            z-index: 2;
                            white-space:nowrap;
                        }
                    }

                    .tag-color-container-selected {
                        border-color: #C4C4C4;
                    }
                }
            }

            .assign-tag-actions {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                width: 100%;
                margin-bottom: 40px;

                button {
                    width: 100px;
                }

                :nth-child(2) {
                    background-color: #FFCC33;
                    margin-left: 20px;
                }
            }
        }
        
    }
}