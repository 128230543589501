.custom-url-redirect {
    margin-top: 20px;
    padding: 24px; 
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    &__content {
        display: flex; 
        flex-direction: column;
        align-items: center;
    }

    label {
        font-weight: bold;
        font-size: 16px;
        margin-top: 30px;
    }
}