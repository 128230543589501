@import './colors.scss';

.alert-page {
    display: flex;
    flex-direction: row;
    flex: 1;
    padding: 30px;
    user-select: none;
    height: 85vh;

    .alert-sent {
        display: flex;
        flex-direction: column;
        width: 50%;
        background-color: white;
        align-items: center;
        border-radius: 25px;

        .alert-sent-top {
            display: flex;
            flex-direction: column;
            height: 35%;
            width: 100%;
            align-items: center;
            justify-content: center;
            background-image: url('../images/green-background.png');
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            border-top-right-radius: 25px;
            border-top-left-radius: 25px;
        }
    }

    .alert-container {
        display: flex;
        flex-direction: row;
        flex: 1;
        padding: 30px;
        user-select: none;
        height: 85vh;
        
        .alert-left {
            display: flex;
            flex-direction: column;
            flex: 3;
            margin-right: 30px;

            .alert-from {
                display: flex;
                flex-direction: row;
                align-items: center;
                background-color: white;
                margin-bottom: 40px;

                label {
                    width: 100px;
                    padding: 15px;
                    background-color: #FFCC33;
                    color: white;
                    font-size: 23px;
                }

                input {
                    width: 100%;
                    border: none;
                    outline: none;
                    padding: 12px;
                    color: #666666;
                    font-size: 23px;
                }
            }

            .input-error {
                border: 1px solid $accent-color;
            }

            .alert-message {
                background-color: white;
                flex: 1;
                margin-bottom: 80px;
                padding: 15px;

                textarea {
                    width: 100%;
                    height: 100%;
                    border: none;
                    resize: none;
                    outline: none;
                    font-size: 20px;
                    color: #666666;
                    white-space: pre-line;
                    font-size: 23px;
                }

            
            }
        }

        .alert-right {
            display: flex;
            flex-direction: column;
            flex: 1.2;

            .alert-send-btn {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 12px 20px;
                border-radius: 30px;
                align-self: center;
                background-color: #FECC33;
                width: 80%;
                margin-bottom: 20px;
                
                label {
                    font-style: normal;
                    font-weight: normal;
                    font-size: 23px;
                    color: #FFFFFF;
                    margin-right: 10px;
                }
            }

            *{
                -ms-overflow-style: none;
            }
            ::-webkit-scrollbar {
                display: none;
            }

            .alert-contacts {
                display: flex;
                flex-direction: column;
                overflow-y: scroll;
                height: 60vh;
            }

            .box {
                position: absolute;
                top: 72vh;
                display: flex;
                flex-direction: row;

                .box-icon {
                    width: 100%;
                }
            }

            .arrow-down {
                display: flex;
                height: 25vh;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            }
        }
    }
}