@import '../colors';

.update-integration-popup {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    top: 0;
    left: 0;
    user-select: none;

    .update-integration-popup-content {
        display: flex;
        flex-direction: column;
        background-color: white;
        position: absolute;
        top: 25%;
        padding: 20px 20px 20px 20px;
        box-shadow: 0px 1px 50px rgba(0, 0, 0, 0.4);
        border-radius: 6px;
        outline: none;
    }
    
    .update-integration-popup-content-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-left: 20px;
        padding-right: 20px;

        .update-integration-popup-title {
            color: #3C4858;
            font-size: 20px;
            width: auto;
        }

        .close-icon {
            width: 15px;
            height: 15px;
            align-self: flex-end;
    
            path {
                fill: #aaaaaa;
            }
        }

        .close-icon:hover {
            path {
                fill: $accent-color;
            }
        }
    }

    .update-integration-form {
        display: flex;
        flex-direction: column;
        padding: 20px;
        margin: 20px;
        border: 0.75px solid #DBDBDB;

        .update-integration-popup-venue {
            display: flex;
            flex-direction: row;
            background-color: $accent-color;
            align-items: center;
            padding: 10px;
            min-width: 20vw;
            border-radius: 10px;
            margin-bottom: 10px;
    
    
            .update-integration-popup-venue-logo {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                background-color: white;
                border-radius: 25px;
                padding: 10px;
    
                img {
                    width: 20px;
                    height: 20px;
                }
            }
    
    
            label {
                margin-left: 20px;
                color: white;
            }
        }

        .update-integration-popup-item-input {
            border: 0.75px solid #DBDBDB;
            box-sizing: border-box;
            border-radius: 21.75px;
            background-color: #F5F5F5;
            padding: 10px 20px 10px 20px;
            min-width: 350px;
            outline: 0;
            font-size: 15px;
            margin-top: 10px;
        }

        .update-integration-popup-item-dropdown {
            margin-top: 5px;
    
            .dropdown-container {
                .dropdown {
                    min-width: 350px;
                    padding: 10px 20px 10px 20px;
                    border: 0.75px solid #DBDBDB;
                    box-sizing: border-box;
                    border-radius: 21.75px;
                    background-color: #F5F5F5;
    
                    label {
                        width: inherit;
                    }
                }
    
                .options {
                    top: 310px;
    
                    label {
                        width: inherit;
                    }
                }
    
                .dropdown-open {
    
                    .logo {
                        margin-top: 2px;
                    }
                }
            }
        }

        .update-integration-popup-input-label {
            color: #3C4858;
            font-size: 15px;
            width: 100px;
            margin-top: 10px;
        }
    
        .input-error {
            border: 1px solid $accent-color;
            border-radius: 21.75px;
        }
    
        .error-label {
            margin-top: 10px;
            color: $accent-color;
        }
    
        button {
            min-width: 100px;
            border-radius: 25px;
            color: white;
            align-self: center;
            background-color: $accent-color;
            border: none;
            padding: 10px 20px 10px 20px;
            outline: 0;
            margin: 10px 10px 0px 10px;
            cursor: pointer;
        }
    }

    .update-input {
        min-width: 350px;
        padding: 10px 20px 10px 20px;
        border: 0.75px solid #DBDBDB;
        box-sizing: border-box;
        border-radius: 21.75px;
        background-color: #F5F5F5;
        margin: 10px 0;
        outline: 0;
    }
}