@import '../colors';

.show-account {
    display: flex;
    flex-direction: row;
    margin: 50px;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.14);
    background-color: white;

    .my-profile-label {
        color: #999999;
        font-size: 10px;
    }

    .name-label {
        color: #3C4858;
        font-size: 21px;
    }

    .account-details {
        display: flex;
        flex-direction: column;
        flex: 1;

        .account-details-contact {
            display: flex;
            flex-direction: row;
            border-bottom: 1px solid rgba(0, 0, 0, 0.14);

            img {
                background-color: #bbbbbb;
                position: relative;
                top: -15px;
                width: 120px;
                height: 120px;
                margin-left: 15px;
            }

            .my-account-initials {
                background-color: $accent-color;
                position: relative;
                top: -15px;
                width: 120px;
                height: 120px;
                margin-left: 15px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: white;
                font-size: 30px;
            }

            .account-details-name {
                display: flex;
                flex-direction: column;
                padding: 30px;
            }
        }
    }

    .account-details-infos {
        display: flex;
        flex-direction: row;
        padding: 10px 40px 50px 40px;

        .account-details-info {
            display: flex;
            flex-direction: column;
            margin-right: 5vw;

            .titled-label {
                margin-top: 20px;
            }
        }
    
    }

    .business-details {
        display: flex;
        flex-direction: column;
        flex: 2;
        border-left: 1px solid rgba(0, 0, 0, 0.14);
        padding: 30px;

        .busines-details-info {
            display: flex;
            flex-direction: row;
            margin-top: 20px;

            .busines-details-info-item {
                display: flex;
                flex-direction: row;

                .titled-label {
                    margin-right: 5vw;
                }
            }
        }

        .description-label {
            margin-top: 20px;
            color: #3C4858;
            font-size: 12px;
        }
    }
}

@media only screen and (max-width: 1200px) {
    .show-account {
        flex-direction: column;

        .business-details {
            border-top: 1px solid rgba(0, 0, 0, 0.14);
        }
    }
}

@media only screen and (max-width: 1600px) {
    .show-account {
        .business-details {
            .busines-details-info {
                flex-direction: column;
                .busines-details-info-item {
                    margin-top: 10px;
                }
            }
        }
    }
}