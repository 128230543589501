.checkout-form {
    display: flex;
    flex-direction: column;
    width: 400px;
    margin-top: 20px;
    padding: 0 20px;

    label {
        color: #5B5B5B;
        font-size: 14px;
        font-family: Roboto;
        margin-top: 15px;
        margin-bottom: 10px;
    }

    input {
        border: 1px solid #C4C4C4;
        border-radius: 21.75px;
        outline: none;
        padding: 7px 20px;
    }

    .input-error {
        border-color: #EA5454;
    }

    .card-element {
        border: 1px solid #C4C4C4;
        border-radius: 21.75px;
        padding: 7px 20px;
    }

    .cf-row-input {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        input {
            width: 150px;
        }
    }

    .payment-loader {
        align-self: center;
        margin-top: 30px;
    }

    .payment-actions {
        display: flex;
        flex-direction: row;
        align-items: center;
        align-self: center;
        margin-top: 30px;

        :first-child {
            background: #EA5454;
            box-shadow: 0px 0px 12px rgba(234, 84, 84, 0.6);
            margin-right: 20px;
        }

        button {
            width: 90px;
            height: 40px;
            background: #FFCC33;
            box-shadow: 0px 0px 12px rgba(255, 204, 51, 0.6);
            border-radius: 45px;
            color: white;
            outline: 0;
            border: none;
            cursor: pointer;
        }
    }
}