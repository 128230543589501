@import './colors';

.menu-item {
    display: flex;
    flex-direction: column;
    user-select: none;
    outline: 0;
    cursor: pointer;

    .item {
        display: flex;
        padding: 15px 20px 15px 25px;
        align-items: center;
        justify-content: space-between;

        .left {
            display: flex;
            flex-direction: row;
            align-items: center;

            .item-logo {
                width: 30px;
            }
        
            label {
                animation: display-none-transition 1s both;
                margin-left: 10px;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                color: #848484;
                font-size: 15px;
                cursor: pointer;
            }
        }
    }
    
}

.menu-item-selected {
    display: flex;
    flex-direction: column;
    user-select: none;
    outline: 0;
    cursor: pointer;

    .item {
        display: flex;
        padding: 15px 20px 15px 25px;
        background: linear-gradient(270deg, #EEEEEE 13.08%, rgba(238, 238, 238, 0) 100%);
        align-items: center;
        justify-content: space-between;

        .left {
            display: flex;
            flex-direction: row;
            align-items: center;

            .item-logo {
                width: 30px;
            }
        
            label {
                margin-left: 10px;
                animation: display-none-transition 1s both;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
                color: #848484;
                font-size: 15px;
                cursor: pointer;
            }
        }
    }
}

.subItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 20px 15px 28px;
    cursor: pointer;
    color: rgba(132, 132, 132, 0.6);

    .subItem-logo {
        width: 25px;
        height: 25px;
        background-repeat: no-repeat;
        background-size: contain;
    }

    .subItem-logo-selected {
        path {
            fill: white;
            stroke: white;
        }

        rect {
            stroke: white;
        }

        circle {
            stroke: white;
            fill: white;
        }

        line {
            stroke: white;
        }

        path:nth-of-type(2) {
            fill: #EA5454;
        }
    }

    .subItem-logo-person {
        path {
            fill:  $accent-color;
        }

        rect {
            fill: $accent-color;
        }
    }

    .subItem-logo-reports {

        path {
            fill: $accent-color;
        }
    }

    .subItem-logo-inactive {
        path {
            fill: #D3D3D3;
            stroke: #D3D3D3;
        }

        rect {
            stroke: #D3D3D3;
        }

        circle {
            stroke: #D3D3D3;
            fill: #D3D3D3;
        }

        line {
            stroke: #D3D3D3;
        }
    }

    label {
        animation: display-none-transition 1s both;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-weight: bold;
        margin-left: 15px;
        cursor: pointer;
    }
}

.subItem-selected {
    background: linear-gradient(269.81deg, #E8555D 56.19%, #C13337 99.64%);
    color: white;
    font-weight: bold;
}

.arrow-icon-down {
    transform: rotate(180deg);
    margin-top: 5px;
}

.arrow-icon {
    transition-duration: 0.5s;
    transition-property: transform;
}

.inactive-item {
    .item-logo {

        path {
            fill: grey;
        }

        rect {
            stroke: grey;
        }

        circle {
            stroke: grey;
            fill: grey;
        }

        line {
            stroke: grey;
        }
    }
}

.small-menu-item {

    label {
        display: none;
        animation: display-none-transition 1s both;

    }

    .arrow-icon {
        display: none;
    }
}

.small-menu-item-selected {

    label {
        display: none;
        animation: display-none-transition 1s both;
    }


    .arrow-icon {
        display: none;
    }
}

@media only screen and (max-width: 1000px) {
    .menu-item {

        label {
            display: none;
            animation: display-none-transition 1s both;

        }
    }

    .menu-item-selected {

        label {
            display: none;
            animation: display-none-transition 1s both;
        }
    }
}



@keyframes display-none-transition {
    0% {
      opacity: 0;
    }
}