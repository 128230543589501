.social-preview {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border-radius: 50px;
    padding: 15px;
    width: 20px;
    height: 20px;
    margin: 5px;

    .social-preview-icon {
        width: 30px;
        height: 30px;
    }
}