@import '../colors.scss';

.venue-item {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    margin: 10px;
    padding: 20px;

    .venue-item-top {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-top: 10px;
        padding-bottom: 10px;

        label {
            font-size: 20px;
            font-weight: 300;
            color: #3C4858;
        }

        .venue-item-actions {
            display: flex;
            flex-direction: row;
            align-items: center;

            .venue-item-action {
                margin-left: 10px;
                cursor: pointer;
            }

            .venue-item-action-edit:hover {
                path {
                    fill: #ea5454
                }
            }

            .venue-item-action-users:hover {
                path {
                    stroke: #ea5454
                }

                rect {
                    stroke: #ea5454
                }
            }
        }
    }

    hr {
        border: 0;
        clear:both;
        display:block;
        width: 100%;       
        background-color: #eeeeee;
        height: 1px;
        margin-top: 10px;
    }

    .venue-item-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 10px;

        label {
            color: #3C4858;
            font-size: 12px;
            font-weight: 300;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-left: 10px;
        }
        .venue-item-icon {
            width: 15px;
            height: 15px;

            path {
                fill: #D8D8D8;
            }
        }
    }

    .venue-item-bottom {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        button {
            min-width: 80px;
            border-radius: 25px;
            color: white;
            align-self: center;
            background-color: $accent-color;
            border: none;
            padding: 5px 10px 5px 10px;
            outline: 0;
            cursor: pointer;
        }
    }
}